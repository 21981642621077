/*###############################################

@Title: Page Contact
@Description:

###############################################*/

.content {
    form {
        .grecaptcha-badge {
            z-index: 100;
        }
    }
}