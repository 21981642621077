/*###############################################

@Title: Forms
@Description:
This file contains the form styles

###############################################*/

// namespace vars
$_opc-form-field-font-family: sans-serif !default;
$_opc-form-field-font-size: inherit !default;

$_opc-form-field-margin: 2rem !default;
$_opc-form-field-border: 1px solid #ccc !default;
$_opc-form-field-border-hover: 1px solid #bbb !default;
$_opc-form-field-border-focus: 1px solid #aaa !default;
$_opc-form-field-border-invalid: 1px solid $error-color !default;
$_opc-form-field-border-valid: 1px solid $success-color !default;
$_opc-form-field-border-disabled: 1px solid #eee !default;

$_opc-form-field-material-init-line: 1px solid #ccc;
$_opc-form-field-material-line-height: 3px !default;
$_opc-form-field-material-line-bg: #999 !default;
$_opc-form-field-material-line-bg-invalid: $error-color !default;
$_opc-form-field-material-line-bg-valid: $success-color !default;

$_opc-form-field-color: #000 !default;
$_opc-form-field-color-invalid: $error-color !default;
$_opc-form-field-color-message-invalid: $error-color !default;
$_opc-form-field-color-valid: $success-color !default;
$_opc-form-field-color-message-valid: $success-color !default;
$_opc-form-field-placeholder-color: #ccc !default;
$_opc-form-field-bg: #fff !default;
$_opc-form-field-bg-hover: #fff !default;
$_opc-form-field-bg-focus: #fff !default;
$_opc-form-field-bg-disabled: #eee !default;
$_opc-form-field-opacity-disabled: 0.65;

$_opc-form-field-label-color: #000 !default;
$_opc-form-field-label-font-weight: 600 !default;
$_opc-form-field-label-font-family: inherit !default;
$_opc-form-field-msg-font-size: 1.2rem !default;
$_opc-form-field-label-width: 10rem !default;

$_opc-form-field-font-size-s: $_opc-font-size-s !default;
$_opc-form-field-font-size-m: $_opc-font-size-m !default;
$_opc-form-field-font-size-l: $_opc-font-size-l !default;
$_opc-form-field-font-size-xl: $_opc-font-size-xl !default;

$_opc-radio-width: 2rem !default;
$_opc-radio-height: 2rem !default;
$_opc-radio-border: 1px solid #ccc !default;
$_opc-radio-border-hover: 1px solid #bbb !default;
$_opc-radio-border-focus: 1px solid #aaa !default;
$_opc-radio-border-checked: 1px solid #ccc !default;
$_opc-radio-border-radius: 2rem !default;
$_opc-radio-color: #000 !default;
$_opc-radio-bg: #fff !default;
$_opc-radio-bg-hover: #fff !default;
$_opc-radio-bg-focus: #fff !default;
$_opc-radio-bg-checked: #fff !default;
$_opc-radio-icon: inline-svg($op-icon-check-svg, "#333") !default;
$_opc-radio-icon-checked: inline-svg($op-icon-check-svg, "#333") !default;
$_opc-radio-icon-size: 1.6rem !default;
$_opc-radio-label-font-size: 1.4rem !default;

$_opc-checkbox-width: 2rem !default;
$_opc-checkbox-height: 2rem !default;
$_opc-checkbox-border: 1px solid #ccc !default;
$_opc-checkbox-border-hover: 1px solid #bbb !default;
$_opc-checkbox-border-focus: 1px solid #aaa !default;
$_opc-checkbox-border-checked: 1px solid #ccc !default;
$_opc-checkbox-border-radius: 0.5rem !default;
$_opc-checkbox-color: #000 !default;
$_opc-checkbox-bg: #fff !default;
$_opc-checkbox-bg-hover: #fff !default;
$_opc-checkbox-bg-focus: #fff !default;
$_opc-checkbox-bg-checked: #fff !default;
$_opc-checkbox-icon: inline-svg($op-icon-check-svg, "#333") !default;
$_opc-checkbox-icon-checked: inline-svg($op-icon-check-svg, "#333") !default;
$_opc-checkbox-icon-size: 1.6rem !default;
$_opc-checkbox-label-font-size: 1.4rem !default;

$_opc-select-icon: inline-svg($op-icon-angle-down-svg, "#999") !default;

$_opc-boolean-switch-label-width: 4.75rem;
$_opc-boolean-dimension-multiplikator: 0.9;
$_opc-boolean-switch-label-height: $_opc-boolean-switch-label-width / 2;
$_opc-boolean-switch-label-border-radius: $_opc-boolean-switch-label-height / 2;
$_opc-boolean-switch-slider-dimension: $_opc-boolean-switch-label-height * $_opc-boolean-dimension-multiplikator;
$_opc-boolean-switch-slider-translate-height: $_opc-boolean-switch-slider-dimension;
$_opc-boolean-active-color: #fc8200 !default;
$_opc-boolean-field-bg: #fff !default;
$_opc-boolean-field-border-color: #ccc !default;

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

.opc-radio {
	position: relative;
	margin-right: $_opc-radio-width;
	display: inline-block;

	label {
		cursor: pointer;
		position: relative;
		font-size: $_opc-checkbox-label-font-size;
		vertical-align: top;
		line-height: $_opc-radio-height + 0.2;
		padding-left: $_opc-radio-width + 1;
		margin-bottom: 0;

		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			border: $_opc-radio-border;
			border-radius: $_opc-radio-border-radius;
			background: $_opc-radio-bg;
			height: $_opc-radio-height;
			width: $_opc-radio-height;
		}
		&::after {
			position: absolute;
			content: "";
			border: 1px solid transparent;
			background-image: $_opc-radio-icon;
			background-size: $_opc-radio-icon-size;
			background-position: center;
			background-repeat: no-repeat;
			left: 0;
			top: 0;
			height: $_opc-radio-height;
			width: $_opc-radio-height;
			opacity: 0;
			transform: translateY(0) rotate(-360deg);
			transition: all 0.5s ease-in-out;
		}

		&.error {
			&::before,
			&::after {
				display: none;
			}
		}

		&:hover {
			&::before {
				border: $_opc-radio-border-hover;
				background: $_opc-radio-bg-hover;
			}
		}
		&:focus {
			&::before {
				border: $_opc-radio-border-focus;
				background: $_opc-radio-bg-focus;
			}
		}
	}

	input[type="radio"] {
		opacity: 0;
		position: absolute;
		height: 0;
		width: 0;

		&:checked {
		}
		&:checked + label {
			&::before {
				background: $_opc-radio-bg-checked;
				border: $_opc-radio-border-checked;
			}
			&::after {
				opacity: 1;
				transform: translateY(0) rotate(0);
			}
		}

		&:focus + label {
			&::before {
				background: $_opc-radio-bg-focus;
				border: $_opc-radio-border-focus;
			}
		}

		&:disabled + label {
			cursor: not-allowed;
			opacity: $_opc-form-field-opacity-disabled;
			&::after {
				// cursor: not-allowed;
				// opacity: $_opc-form-field-opacity-disabled;
				background-color: $_opc-form-field-bg-disabled !important;
				background-image: none;
				border-radius: 50%;
				opacity: 1;
			}
		}

		&:disabled:checked + label {
			&::after {
				opacity: 1;
				background-image: $_opc-radio-icon;
			}
		}
	}
}

.opc-boolean {
	label {
		position: relative;
		top: 0rem;
		left: 0rem;
		visibility: hidden;
		z-index: -1;

		.buttonbackground {
			background-color:$_opc-boolean-field-bg;
			border: 1px solid $_opc-boolean-field-border-color;
			width: $_opc-boolean-switch-label-width;
			height: $_opc-boolean-switch-label-height;
			border-radius: $_opc-boolean-switch-label-border-radius;
			position: relative;
			transition:background-color 0.2s ease;
			display:inline-block;

			.buttonslider {
			background:#ddd;
			width: $_opc-boolean-switch-slider-dimension;
			height: $_opc-boolean-switch-slider-dimension;
			border-radius:50%;
			position: absolute;
			left: 0;
			top: $_opc-boolean-switch-slider-dimension;
			transform: translateY(-$_opc-boolean-switch-slider-translate-height);
			transition:all 0.2s ease;
			display:block;
			}
		}
	}

	input[id$="TRUE"],
	input[id$="FALSE"] {
		~ label {
			&:hover {
				cursor:pointer;
			}
		}
		&:focus {
			~ label {
				.buttonbackground {
					border: $_opc-form-field-border-focus !important;
					background: $_opc-form-field-bg-focus;
					outline: none;
				}
			}
		}
	}

	input[id$="TRUE"][type=radio] {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
		&:disabled {
			~ label {
				opacity: $_opc-form-field-opacity-disabled;
				.buttonbackground {
					filter: grayscale(0.75);
				}
				&:hover {
					cursor:not-allowed;
				}
			}
		}
		&:checked {
			~ label[for$="FALSE"] {
				visibility: visible;
				z-index: auto;
				.buttonbackground {
					background-color: $_opc-boolean-active-color;
					border: 1px solid $_opc-boolean-active-color;
					.buttonslider {
						left: 100%;
						transform: translateX(-$_opc-boolean-switch-slider-dimension) translateY(-$_opc-boolean-switch-slider-translate-height);
					}
				}
			}

			~ label[for$="TRUE"] {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				.buttonslider {
					left: 100%;
					transform: translateX(-$_opc-boolean-switch-slider-dimension) translateY(-$_opc-boolean-switch-slider-translate-height);
				}
			}
		}
	}




	input[id$="FALSE"][type=radio] {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
		&:disabled {
			~ label {
				.buttonbackground {
					//background-color: $_opc-form-field-bg-disabled !important;
				}
				&:hover {
					cursor:not-allowed;
				}
			}
		}
		&:checked {
			~ label[for$="TRUE"] {
				visibility: visible;
				z-index: auto;
				.buttonbackground {
					background-color:$_opc-boolean-field-bg;
					border: 1px solid $_opc-boolean-field-border-color;
					.buttonslider {
						left: 0;
					}
				}
			}

			~ label[for$="FALSE"] {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				.buttonslider {
					left: 0;
				}
			}
		}
	}

	// Default Box if nothing checked
	input[id$="FALSE"][type=radio]:not(:checked)+input[id$="TRUE"][type=radio]:not(:checked) {
		~ label[for$="TRUE"] {
			visibility: visible;
			z-index: auto;
			position:absolute;
			.buttonbackground {
				background-color:$_opc-boolean-field-bg;
				border: 1px solid $_opc-boolean-field-border-color;
				.buttonslider {
					left: 0;
				}
			}
		}
	}
}

.opc-checkbox {
	position: relative;
	margin-right: $_opc-checkbox-width;
	display: inline-block;

	label {
		cursor: pointer;
		position: relative;
		font-size: $_opc-checkbox-label-font-size;
		vertical-align: top;
		line-height: $_opc-checkbox-height + 0.2;
		padding-left: $_opc-checkbox-width + 1;
		margin-bottom: 0;

		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			border: $_opc-checkbox-border;
			border-radius: $_opc-checkbox-border-radius;
			background: $_opc-checkbox-bg;
			height: $_opc-checkbox-height;
			width: $_opc-checkbox-height;
		}
		&::after {
			position: absolute;
			content: "";
			border: 1px solid transparent;
			background-image: $_opc-checkbox-icon;
			background-size: $_opc-checkbox-icon-size;
			background-position: center;
			background-repeat: no-repeat;
			left: 0;
			top: 0;
			height: $_opc-checkbox-height;
			width: $_opc-checkbox-height;
			opacity: 0;
			transform: translateY(0) rotate(-360deg);
			transition: all 0.5s ease-in-out;
		}

		&.error {
			&::before,
			&::after {
				display: none;
			}
		}

		&:hover {
			&::before {
				border: $_opc-checkbox-border-hover;
				background: $_opc-checkbox-bg-hover;
			}
		}
		&:focus {
			&::before {
				border: $_opc-checkbox-border-focus;
				background: $_opc-checkbox-bg-focus;
			}
		}
	}

	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		visibility: hidden;

		&:checked {
		}
		&:checked + label {
			&::before {
				background: $_opc-checkbox-bg-checked;
				border: $_opc-checkbox-border-checked;
			}
			&::after {
				opacity: 1;
				transform: translateY(0) rotate(0);
			}
		}

		&:disabled + label {
		cursor: not-allowed;
		opacity: $_opc-form-field-opacity-disabled;
		&::after {
			cursor: not-allowed;
			opacity: $_opc-form-field-opacity-disabled;
			background-color: $_opc-form-field-bg-disabled !important;
			background-image: none;
			border-radius: 20%;
		}
		}

		&:disabled:checked + label {
			&::after {
				background-image: $_opc-radio-icon;
			}
		}
	}
}

.opc-time, .opc-date {
	position: relative;

	.daterangepicker-input, .flatpickr-input {
		&:read-only {
			cursor: pointer;
			background: initial;
			opacity: initial
		}

		&:disabled {
			cursor: not-allowed;
			opacity: $_opc-form-field-opacity-disabled;
			background: $_opc-form-field-bg-disabled !important;
		}

		+ .daterangepicker-calendar, + .flatpickr-calendar{
			&.open {
				position: absolute;
				left: 0;
				top: map-get($heights, "medium");
				min-width: 250px;
				max-width: 100%;
			}
		}

		&[data-op-size="tiny"] {
			+ .daterangepicker-calendar, + .flatpickr-calendar {
				&.open {
					position: absolute;
					left: 0;
					top: map-get($heights, "tiny");
				}
			}
		}
		&[data-op-size="small"] {
			+ .daterangepicker-calendar, + .flatpickr-calendar {
				&.open {
					position: absolute;
					left: 0;
					top: map-get($heights, "small");
				}
			}
		}
		&[data-op-size="medium"] {
			+ .daterangepicker-calendar, + .flatpickr-calendar {
				&.open {
					position: absolute;
					left: 0;
					top: map-get($heights, "medium");
				}
			}
		}
		&[data-op-size="large"] {
			+ .daterangepicker-calendar, + .flatpickr-calendar {
				&.open {
					position: absolute;
					left: 0;
					top: map-get($heights, "large");
				}
			}
		}
		&[data-op-size="huge"] {
			+ .daterangepicker-calendar, + .flatpickr-calendar {
				&.open {
					position: absolute;
					left: 0;
					top: map-get($heights, "huge");
				}
			}
		}
	}
}

.opc-input,
.opc-textarea {
	font-family: $_opc-form-field-font-family;
	font-size: $_opc-form-field-font-size;
	border: $_opc-form-field-border;
	background: $_opc-form-field-bg;
	font-size: $_opc-form-field-font-size-m;
	height: map-get($heights, "medium");
	line-height: map-get($heights, "medium");
	padding: 0 map-get($padding, "medium");

	// disable ms arrow
	&::-ms-expand {
		display: none;
	}
	// disable ms clear
	&::-ms-clear {
		display: none;
		height: 0;
		width: 0;
	}

	&:disabled, &:read-only {
		cursor: not-allowed;
		opacity: $_opc-form-field-opacity-disabled;
		background: $_opc-form-field-bg-disabled !important;
	}

	&:hover {
		border: $_opc-form-field-border-hover;
		background: $_opc-form-field-bg-hover;
	}

	&:focus {
		border: $_opc-form-field-border-focus;
		background: $_opc-form-field-bg-focus;
		outline: none;
	}

	.is-invalid &,
	&.is-invalid {
		border: $_opc-form-field-border-invalid;
		color: $_opc-form-field-color-invalid;
	}

	.is-valid &,
	&.is-valid {
		border: $_opc-form-field-border-valid;
		color: $_opc-form-field-color-valid;
	}
}

.opc-textarea {
	height: auto;
	line-height: 1.4;
	padding: map-get($padding, "medium");
}

.opc-select {
	padding: (map-get($padding, small)-0.1) (map-get($padding, small)-0.1);
	font-family: $_opc-form-field-font-family;
	border: $_opc-form-field-border;
	font-size: $_opc-form-field-font-size;
	background-color: $_opc-form-field-bg;

	font-size: $_opc-form-field-font-size-m;
	height: map-get($heights, "medium");
	line-height: map-get($heights, "medium");
	padding: 0 map-get($padding, "medium");

	padding-right: 3.5rem !important;
	appearance: none;
	background-image: $_opc-select-icon;
	background-size: 1.8rem;
	background-position: calc(100% - 1rem) center;
	background-repeat: no-repeat;

	// disable ms arrow
	&::-ms-expand {
		display: none;
	}
	// disable ms clear
	&::-ms-clear {
		display: none;
		height: 0;
		width: 0;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: $_opc-form-field-opacity-disabled;
		background: $_opc-form-field-bg-disabled !important;
	}

	&:hover {
		border: $_opc-form-field-border-hover;
		background-color: $_opc-form-field-bg-hover;
	}

	&:focus {
		border: $_opc-form-field-border-focus;
		background-color: $_opc-form-field-bg-focus;
	}

	.is-invalid &,
	&.is-invalid {
		border: $_opc-form-field-border-invalid;
		color: $_opc-form-field-color-invalid;
	}

	.is-valid &,
	&.is-valid {
		border: $_opc-form-field-border-valid;
		color: $_opc-form-field-color-valid;
	}
}

.opc-dropzone {
	&:hover {
		border: $_opc-form-field-border-hover;
		background: $_opc-form-field-bg-hover;
	}

	&:focus {
		border: $_opc-form-field-border-focus;
		background: $_opc-form-field-bg-focus;
		outline: none;
	}

	.is-invalid &,
	&.is-invalid {
		border: $_opc-form-field-border-invalid;
		color: $_opc-form-field-color-invalid;
	}

	.is-valid &,
	&.is-valid {
		border: $_opc-form-field-border-valid !important;
		color: $_opc-form-field-color-valid;
	}
}

.opc-wysiwyg {
	.is-invalid &,
	&.is-invalid {
		border: $_opc-form-field-border-invalid;
		color: $_opc-form-field-color-invalid;
	}
}

.opc-input,
.opc-select {
	&[data-op-size="tiny"] {
		font-size: $_opc-form-field-font-size-s;
		height: map-get($heights, "tiny");
		line-height: map-get($heights, "tiny");
		padding: 0 map-get($padding, "tiny");
	}
	&[data-op-size="small"] {
		font-size: $_opc-form-field-font-size-m;
		height: map-get($heights, "small");
		line-height: map-get($heights, "small");
		padding: 0 map-get($padding, "small");
	}
	&[data-op-size="medium"] {
		font-size: $_opc-form-field-font-size-m;
		height: map-get($heights, "medium");
		line-height: map-get($heights, "medium");
		padding: 0 map-get($padding, "medium");
	}
	&[data-op-size="large"] {
		font-size: $_opc-form-field-font-size-l;
		height: map-get($heights, "large");
		line-height: map-get($heights, "large");
		padding: 0 map-get($padding, "large");
	}
	&[data-op-size="huge"] {
		font-size: $_opc-form-field-font-size-xl;
		height: map-get($heights, "huge");
		line-height: map-get($heights, "huge");
		padding: 0 map-get($padding, "huge");
	}
}

.opc-form-field {
	margin-bottom: $_opc-form-field-margin;

	.form-field-label {
		display: block;
		color: $_opc-form-field-label-color;
		font-family: $_opc-form-field-label-font-family;
		font-weight: $_opc-form-field-label-font-weight;
	}

	.form-field-element {
		width: 100%;
		position: relative;

		input[type="text"],
		input[type="password"],
		input[type="email"],
		input[type="number"],
		input[type="tel"],
		input[type="search"],
		input[type="url"],
		input[type="date"],
		textarea {
			width: 100%;

			// disable ms arrow
			&::-ms-expand {
				display: none;
			}

			// disable ms clear
			&::-ms-clear {
				display: none;
				height: 0;
				width: 0;
			}
		}

		select, option {
			width: 100%;

			// disable ms arrow
			&::-ms-expand {
				display: none;
			}

			// disable ms clear
			&::-ms-clear {
				display: none;
				height: 0;
				width: 0;
			}
		}

		input[type="date"]::-webkit-inner-spin-button {
			display: none;
		}
	}

	.form-field-note {
		font-size: $_opc-form-field-msg-font-size;
		color: rgba($_opc-form-field-label-color, 0.4);
	}

	/* --------------------------------------------------------------- */
	// label styles and variations

	&[data-op-label="hidden"] {
		> label {
			display: none;
		}
	}

	&[data-op-label="top"] {
		> label {
			margin-bottom: 0.5rem;
		}
	}

	&[data-op-label="left"] {
		display: flex;
		flex-wrap: wrap;
		> label {
			width: $_opc-form-field-label-width;
			flex-shrink: 0;
			align-items: center;
			display: flex;
			margin-bottom: 0;
		}
		> .form-field-element {
			flex-shrink: 0;
			width: calc(100% - #{$_opc-form-field-label-width});
		}
		> .form-field-note {
			margin-left: $_opc-form-field-label-width;
		}

		> .form-field-error {
			margin-left: $_opc-form-field-label-width;
		}
	}

	&[data-op-label="material"] {
        position: relative;

        > label {
            margin-bottom: 0.5rem;
            position: absolute;
            top:50%;
            top: 0;
            left: 0;
            transform: translateY(1.3rem);
            z-index: 2;
            transition: all 0.2s ease-in-out;
            margin-bottom: 0;
            padding: 0.2rem 1.25rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            width: 100%;
			cursor: text;
			pointer-events: none;
        }

        > .form-field-element {
            flex-shrink: 0;
            background: #fff;
            border:  1px solid #ccc;
            border: none;
            border-bottom: $_opc-form-field-material-init-line;

            &::before {
                content: '';
                display: block;
                height: $_opc-form-field-material-line-height;
                background: $_opc-form-field-material-line-bg;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                transform: scaleX(0);
                transition: transform 0.2s ease-in-out;
            }

            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="number"],
            input[type="tel"],
            input[type="search"],
            input[type="url"],
            input[type="date"],
            select {
                opacity: 0;
                background: transparent;
                border: none;
                height: 5rem;
                padding-top: 1.25rem !important;
                transition: opacity 0.2s ease-in-out;
                outline: none;
            }
            textarea {
                height: auto;
                min-height: 5rem;
                opacity: 0;
                background: transparent;
                border: none;
                margin-top: 2rem;
                outline: none;
                padding-top: 0;
            }

        }
        > .form-field-note {
            margin-top: 0.4rem;
            margin-left: 1.25rem;
        }

        > .form-field-error {
            margin-left: 1.25rem;
        }

        &.is-materialized {
            > label {
                transform: translateY(0);
                font-size: 0.75em;
                pointer-events: none;
                //height: 2rem;
            }
            > .form-field-element {
                input[type="text"],
                input[type="password"],
                input[type="email"],
                input[type="number"],
                input[type="tel"],
                input[type="search"],
                input[type="url"],
                input[type="date"],
                select,
                textarea {
                    opacity: 1;
                }

                &::before {
                    transform: scaleX(1);
                }
            }
        }

        &.is-invalid {
            > label {
                color: $_opc-form-field-color-invalid;
            }
            > .form-field-element {
                border-color: $_opc-form-field-material-line-bg-invalid;
                &::before {
                    background: $_opc-form-field-material-line-bg-invalid;
                }
            }
        }
    }

	/* --------------------------------------------------------------- */
	// error style for messages

	.ff-error, .form-field-error {
		font-size: $_opc-form-field-msg-font-size;
		padding: 0.5rem 0;
		color: $error-color;
		margin: 0;
	}

	/* --------------------------------------------------------------- */
	// sizes
	&[data-op-size="tiny"] {
		.form-field-element {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="number"],
			input[type="tel"],
			input[type="search"],
			input[type="url"],
			input[type="date"],
			select,
			textarea {
				width: 100%;
				font-size: $_opc-form-field-font-size-s;
				height: map-get($heights, "tiny");
				line-height: map-get($heights, "tiny");
				padding: 0 map-get($padding, "tiny");
			}
		}

		&[data-op-label="left"] {
			.form-field-label {
				font-size: $_opc-form-field-font-size-s;
				height: map-get($heights, "tiny");
				line-height: map-get($heights, "tiny");
				padding: 0 map-get($padding, "tiny");
			}
		}
	}

	&[data-op-size="small"] {
		.form-field-element {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="number"],
			input[type="tel"],
			input[type="search"],
			input[type="url"],
			input[type="date"],
			select,
			textarea {
				width: 100%;
				font-size: $_opc-form-field-font-size-m;
				height: map-get($heights, "small");
				line-height: map-get($heights, "small");
				padding: 0 map-get($padding, "small");
			}
		}
		&[data-op-label="left"] {
			.form-field-label {
				font-size: $_opc-form-field-font-size-m;
				height: map-get($heights, "small");
				line-height: map-get($heights, "small");
				padding: 0 map-get($padding, "small");
			}
		}
	}

	&[data-op-size="medium"] {
		.form-field-element {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="number"],
			input[type="tel"],
			input[type="search"],
			input[type="url"],
			input[type="date"],
			select,
			textarea {
				width: 100%;
				font-size: $_opc-form-field-font-size-m;
				height: map-get($heights, "medium");
				line-height: map-get($heights, "medium");
				padding: 0 map-get($padding, "medium");
			}
		}
		&[data-op-label="left"] {
			.form-field-label {
				font-size: $_opc-form-field-font-size-m;
				height: map-get($heights, "medium");
				line-height: map-get($heights, "medium");
				padding: 0 map-get($padding, "medium");
			}
		}
	}

	&[data-op-size="normal"] {
		.form-field-element {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="number"],
			input[type="tel"],
			input[type="search"],
			input[type="url"],
			input[type="date"],
			select,
			textarea {
				width: 100%;
				font-size: $_opc-form-field-font-size-m;
				height: map-get($heights, "normal");
				line-height: map-get($heights, "normal");
				padding: 0 map-get($padding, "normal");
			}
		}
		&[data-op-label="left"] {
			.form-field-label {
				font-size: $_opc-form-field-font-size-m;
				height: map-get($heights, "normal");
				line-height: map-get($heights, "normal");
				padding: 0 map-get($padding, "normal");
			}
		}
	}

	&[data-op-size="large"] {
		.form-field-element {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="number"],
			input[type="tel"],
			input[type="search"],
			input[type="url"],
			input[type="date"],
			select,
			textarea {
				width: 100%;
				font-size: $_opc-form-field-font-size-l;
				height: map-get($heights, "large");
				line-height: map-get($heights, "large");
				padding: 0 map-get($padding, "large");
			}
		}
		&[data-op-label="left"] {
			.form-field-label {
				font-size: $_opc-form-field-font-size-l;
				height: map-get($heights, "large");
				line-height: map-get($heights, "large");
				padding: 0 map-get($padding, "large");
			}
		}
	}

	&[data-op-size="huge"] {
		.form-field-element {
			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="number"],
			input[type="tel"],
			input[type="search"],
			input[type="url"],
			input[type="date"],
			select,
			textarea {
				width: 100%;
				font-size: $_opc-form-field-font-size-xl;
				height: map-get($heights, "huge");
				line-height: map-get($heights, "huge");
				padding: 0 map-get($padding, "huge");
			}
		}
		&[data-op-label="left"] {
			.form-field-label {
				font-size: $_opc-form-field-font-size-xl;
				height: map-get($heights, "huge");
				line-height: map-get($heights, "huge");
				padding: 0 map-get($padding, "huge");
			}
		}
	}

	&[data-op-addon] {
		.form-field-element {
			padding-right: 4rem;
			position: relative;
			.form-field-element-addon {
				position: absolute;
				right: 0;
				bottom: 0;
				top: 0;
				width: 4rem;
				background: #fff;
				border: $_opc-form-field-border;
				border-left: none;
				display: flex;
				justify-content: center;
				align-items: center;
				.opc-button {
					margin: 0;
				}
				svg {
					font-size: 1.4em;
				}
			}
		}
	}
}

.pwd-strength {
	margin-top: 1rem;
	padding-left: 2rem;
	margin-bottom: 2rem;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			font-size: 1.4rem;
			display: grid;
			grid-template-columns: 16px 1fr;
			column-gap: 8px;
			align-items: center;
			margin-bottom: 0.5rem;

			&::before {
				content: "";
				display: inline-block;
				background-image: inline-svg($op-icon-cross-svg, $error-color);
				background-size: 100%;
				background-position: center;
				background-repeat: no-repeat;
				color: $error-color;
				margin-right: 0.5rem;
				width: 1.6rem;
				height: 1.6rem;
				text-align: center;
			}

			&.is-valid {
				&::before {
					content: "";
					background-image: inline-svg(
						$op-icon-check-svg,
						$success-color
					);
					background-size: 100%;
					background-position: center;
					color: $success-color;
				}
			}
		}
	}
}

.opc-form-field-group {
	width: 100%;
	display: flex;

	.opc-form-field {
		margin-bottom: 0;
	}
}

.opc-form-field-btn {
	background: #fff;
	margin-left: -1px;
	cursor: pointer;
	&:hover {
		background: #eee;
	}
}

.form-field-list {
	margin-bottom: 2rem;
	width: 100%;
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	border: $_opc-form-field-border;
	max-height: 20rem;
	z-index: 1000;
	overflow-y: auto;

	&.is-visible {
		display: block;
	}

	.select-list {
		li {
			padding: 0.5rem 1rem;
			cursor: pointer;

			&:hover {
				background: #eee;
			}
			&.disabled {
				pointer-events: none;
			}
		}
	}
}

.opc-checkbox-slider {
	.checkbox-slider-wrapper {
		position: relative;
		display: inline-block;
		width: 5rem;
		height: 2.4rem;
	}

	.checkbox-slider-wrapper input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider-button {
		border-radius: 34px;
		border: solid 1px $_opc-boolean-field-border-color;
    	box-sizing: border-box;
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $_opc-boolean-field-bg;
		-webkit-transition: .2s;
		transition: .2s;
	}

	.slider-button:before {
		border-radius: 50%;
		position: absolute;
		content: "";
		width: 2rem;
		height: 2rem;
		bottom: 1px;
		left: 1px;
		background-color: #ddd;
		-webkit-transition: .2s;
		transition: .2s;
	}

	input:checked + .slider-button {
		background-color: $_opc-boolean-active-color;
	}

	input:focus + .slider-button {
		box-shadow: 0 0 1px $_opc-boolean-active-color;
	}

	input:checked + .slider-button:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}
}

// da keine styles ohne opc componenten vorgesehen waren im core, kommen die PAGESPEZIFISCHEN STYLES nun temporär hier hin...
#register-form-addr,
#register-form-contact {
	flex-wrap: wrap;
	display: flex;
	.opc-form-field {
		width: 100%;
	}
}

