/*###############################################

@Title: Message
@Description:
This file contains the message styles for system messasegs

###############################################*/

$_opc-message-bg : #f8f8f8 !default;
$_opc-message-padding : 2rem !default;
.opc-message {
    background: $_opc-message-bg;
    padding: $_opc-message-padding;
    margin-bottom: 3rem;
    p {
        &:last-of-type {
            margin: 0;
        }
    }
    svg {
        font-size: 1.2em;
        margin-right: 1rem;
    }
    &[data-op-type="info"] {
        background: $info-color; 
    }
    &[data-op-type="warning"] {
        background: $warning-color;
    }
    &[data-op-type="success"] {
        background: $success-color;
        color: #fff;
    }
    &[data-op-type="error"] {
        background: $error-color;
        color: #fff;
    }
}