/*###############################################

@Title: Breadcrumb
@Description:
This file contains the breadcrumb styles

###############################################*/

// namespace vars & mapping
$_opc-breadcrumb-margin: 0.5rem !default;
$_opc-breadcrumb-color: inherit !default;
$_opc-breadcrumb-color-hover: $brand-color-1 !default;
// styles 
.opc-breadcrumb {
    margin-bottom: 2rem;
    display: flex; // pretext of the breadcrumb
    .breadcrumb-pretext {
        display: inline-block;
        margin-right: $_opc-breadcrumb-margin;
        vertical-align: top;
        flex-shrink: 0;
    } // breadcrumb itself
    .breadcrumb-navigation-multi {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .breadcrumb-navigation {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;
        vertical-align: top;
        margin-bottom: 0.5rem;
        li {
            float: left;
            margin-right: $_opc-breadcrumb-margin; // icon after the text / link
            &::after {
                content: '';
                width: 1em;
                height: 1em;
                display: inline-block;
                background-image: inline-svg($op-icon-angle-right-svg, #000);
                background-size: 80%;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
            } // is-active state for breadcrumb element
            &.is-active {
                font-weight: bold; // disable the after element for the active item
                &::after {
                    display: none;
                }
            }
            a {
                text-decoration: none;
                color: $_opc-breadcrumb-color;
                &:hover {
                    color: $_opc-breadcrumb-color-hover;
                }
            }
        }
    }
}