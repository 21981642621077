.card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
        display: flex;
        align-items: center;
        box-shadow: 3px 3px 10px $progress-color;
        height: 190px;
        width: 100%;
        padding: 25px;
        margin-top: 25px;

        @include mq($min-width: map-get($breakpoints, xs)) {
            padding: 40px;
        }

        @include mq($min-width: map-get($breakpoints, m)) {
            width: 49%;
        }

        @include mq($min-width: map-get($breakpoints, l)) {
            filter: grayscale(1);

            &:hover {
                filter: grayscale(0);
                transition: 0.3s ease;
            }
        }

        .logo {

            img {
                max-width: 70px;

                @include mq($min-width: map-get($breakpoints, s)) {
                    max-width: 160px;
                    max-height: 120px;
                }
            }
        }

        .membership {
            margin-left: 25px;

            @include mq($min-width: map-get($breakpoints, s)) {
                margin-left: 40px;
            }

            @include mq($min-width: map-get($breakpoints, m)) {
                width: 300px;
            }

            .membership-name {
                font-weight: bold;
                font-size: 1.7rem;

                @include mq($min-width: map-get($breakpoints, m)) {
                    font-size: 2rem;
                }
            }

            .membership-link {
                font-size: 1.5rem;
            }
        }
    }
}