/*###############################################

@Title: Favorite Info Button
@Description:
This file contains the favorite info button styles

###############################################*/

$_opc-favorite-button-font-size: 3.6rem !default;

.opc-favorite-info {
    position: relative; 
    &[data-op-layout="1"] {
        >.opc-button {
            font-size: $_opc-favorite-button-font-size;
            border: none;
            background: none;
            margin-left: 0.5rem;
            cursor: pointer;
            position: relative;
            padding: 0 0.5rem;
            svg {
                font-size: 3.6rem;
            }
            &:hover {}
            .favorite-text {
                display: none;
            }
            .favorite-count {
                width: 1.8rem;
                height: 1.8rem;
                display: block;
                background: $brand-color-1;
                border-radius: 50%;
                position: absolute;
                top: 0.4rem;
                right: 0rem;
                font-size: 1.1rem;
                text-align: center;
                line-height: 1.8rem;
                color: #fff;
                &:empty {
                    display: none;
                }
            }
        }
    }
    &[data-op-layout="2"] {
        >.opc-button {
            padding: 0;
            height: auto;
            line-height: inherit;
            svg {
                &.op-icon-fav {
                    display: none;
                }
            }
            &:hover {}
            .favorite-text {}
            .favorite-count {
                font-weight: bold;
                &::before {
                    content: "(";
                }
                &::after {
                    content: ")";
                }
                &:empty {
                    display: none;
                }
            }
        }
    }
}



$_opc-compare-button-font-size: 3.6rem !default;

.opc-compare-info {
    position: relative; 
    &[data-op-layout="1"] {
        >.opc-button {
            font-size: $_opc-compare-button-font-size;
            border: none;
            background: none;
            margin-left: 0.5rem;
            cursor: pointer;
            position: relative;
            padding: 0 0.5rem;
            svg {
                font-size: 3.6rem;
            }
            &:hover {}
            .compare-text {
                display: none;
            }
            .compare-count {
                width: 1.8rem;
                height: 1.8rem;
                display: block;
                background: $brand-color-1;
                border-radius: 50%;
                position: absolute;
                top: 0.4rem;
                right: 0rem;
                font-size: 1.1rem;
                text-align: center;
                line-height: 1.8rem;
                color: #fff;
                &:empty {
                    display: none;
                }
            }
        }
    }
    &[data-op-layout="2"] {
        >.opc-button {
            padding: 0;
            height: auto;
            line-height: inherit;
            svg {
                &.op-icon-compare {
                    display: none;
                }
            }
            &:hover {}
            .compare-text {}
            .compare-count {
                font-weight: bold;
                margin-left: 0.4rem;
                &::before {
                    content: "(";
                }
                &::after {
                    content: ")";
                }
                &:empty {
                    display: none;
                }
            }
        }
    }
}