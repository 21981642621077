.opc-search {
    position: relative;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20000;
    visibility: hidden;
    opacity: 0;
    z-index: -100;
    max-width: 108rem;
    &.is-open {
        visibility: visible;
        opacity: 1;
        z-index: 20000;
    }
    .search-toggle {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        display: none;
    }
    .opc-search-bg {
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: block;
    }
    .opc-search-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        padding: 2rem;
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: 100%;
        position: relative;
        background: none;
        visibility: visible;
        opacity: 1;
        z-index: 20000;
        .opc-search-bg,
        .opc-search-toggle {
            display: none;
        }
        .opc-search-content {
            position: relative;
            padding: 0;
        }
    }

    .opc-search-content {
        .opc-button[data-op-action="close"] {
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
        .opc-search-query {
            border: none;
            border-radius: 0;
            background: transparent;
            border-bottom: 1px solid $dark-grey;
            display: flex;
            padding: 1rem 0;
            height: 6rem;
            align-items: center;
            margin-right: 5rem;
            @include mq($min-width: map-get($breakpoints, xl)) {
                margin-right: 0;
            }
            .search-query {
                border: none;
                height: 3rem;
                padding-left: 0;
                padding-right: 0;
                background-color: transparent;

                &:focus {
                    outline: none;
                }
                &::placeholder{
                    color: #999;
                }
            }

            .search-filter {
                border: none;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                padding: 0 1rem;
                height: 3rem;

                &:focus {
                    outline: none;
                }
            }

            .search-btn {
                margin-right: 0;
                padding-right: 0;
                background: none;
                border: none;
                height: 4rem;
                width: 4rem;
                flex-shrink: 0;
                color: $black;
                // background-image: linear-gradient(to bottom right, $dark-blue, $blue);
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    // background-image: linear-gradient(to bottom right, lighten($dark-blue, 2%), lighten($blue, 2%));
                }

                svg{
                    font-size: 4.2rem;
                }
            }

            input {
                background: #F3F3F3;

                &::placeholder {
                    color: #B5B5B5;
                }
            }
        }

        .opc-search-results {
            display: none!important;
            padding: 0;
            position: relative;
            top: 0;
            background-color: $light-grey;
            border: none;
            .search-is-open &{
                &.is-open{
                    display: block!important;
                }
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                position: absolute;
                top: calc(100% + 2px);
            }

            .opc-directsearch-message {
                height: 4rem;
                position: relative;
                z-index: 2;
                background: #eee;
                margin: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }


    }



    input {
        width: 100%;
    }
}



.column-section-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-bottom: 1px solid #ccc;
        a {
            display: block;
            padding: 1.5rem 0;
            &:hover {
                background: rgba(255,255,255,1);
            }
            >span {
                display: block;
            }
        }
        &.moreItems{
            a {
                &:hover {
                    color: $brand-color-1;
                    background: transparent;
                }
            }
        }
    }

    &[data-op-type="article-image"] {
        .list-item-image {
            width: 11rem;
            margin-right: 1rem;
        }

        .list-item-info {
            width: calc(100% - 9rem);
        }

        .list-item-description {
            max-width: 100%;
        }

        a {
            display: flex;
            width: 100%;
            padding: 1rem 0;
            align-items: center;
            .image {
                //background: #ccc;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .info {

                .name {
                    display: block;
                    font-weight: 600;
                    margin-bottom: 0.2em;
                }

                .additional-name {
                    display: block;
                    font-size: 1.2rem;
                    opacity: 0.8;
                    margin-bottom: 0.2em;
                }

                .description {
                    display: block;
                }

                .price {}



            }


        }




    }

    &[data-op-type="simple"] {
        a {
            position: relative;
        }

        .CatStruct-Name {}

        .CatStruct-Name~.Amount {
            display: none;
        }

        .CatStruct-Number {
            display: none;
        }
    }

    .moreItems {
        a {
            padding: 3rem;
            justify-content: center;
            span {
                margin: 0;
                font-size: 1.8rem;
                font-weight: 600;
            }
        }
        border-bottom: none;
    }
}

