/*###############################################

@Title: Content
@Description:


###############################################*/
html, body {
	margin: 0;   
	padding: 0;
	max-width: 100%;
}
html {
	font-size: 10px; 
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  &.offcanvas-menu-open,
  &.search-open {
    overflow: hidden;
  }
}
body { 
	font-size: 1.4rem;
  font-family: $_opc-base-font-family;
  font-weight: $_opc-base-font-weight;
  line-height: $_opc-base-line-height;
	background: #efefef;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $text-color;
  // &.is-fixed{
  //   position: fixed;
  //   overflow-y: hidden;
  //   right: 0;
  //   left: 0;
  //   top: 0;
  //   bottom: 0;
  // }
}  

.viewport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
	transition: all 0.3s ease-in-out;
  transform: translateY(-9.5rem);
  margin-bottom: -9.5rem;
  max-width: 100%;
  overflow: hidden;
  position: fixed;
  top: 94px;
  bottom: 0;
  overflow-y: scroll;
  @include mq($min-width: map-get($breakpoints, m)) {
    position: static;
    overflow-y: hidden;
  }
}
.search-is-open{
  .viewport{
    transform: translateY(0);
    margin-bottom: 0rem;
	}
}

.content {
	//@extend %mw-custom;
  
  @include clearfix();
	
  background: #fff;
  flex-grow: 1;

	.opc-content-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: #000;
    opacity: 0.2;
  }

  &.opc-cms{
    .static-content &{
      max-width: 108rem;
      margin: 0 auto;
      width: 100%;
    }
  }
  &.referenzen{
    .section-wrapper{
      .section-inner{
        max-width: 108rem;
        margin: 0 auto;
        width: 100%;
      }
    }
  }
} 




.result-view-menu,
.article-list-menu {
	
}

.inner-gradient {
  position: relative;
  background-color: $dark-blue;
  color: $white;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    right: 100%;
    background-image: linear-gradient(to left, $dark-blue 0%, $blue 10%);
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 100%;
    background-image: linear-gradient(to right, $dark-blue 0%, $blue 10%);
  }
}

.daterangepicker-calendar, .flatpicker-calendar {
  position: absolute !important;
  width: 275px;
}

.products.opc-carousel{
  min-height: 44.9rem;
  // padding: 0 4rem;
  // @include mq($min-width: map-get($breakpoints, m)) {
  //   padding: 0;
  // }
}




.content[data-cms-site="ZZZ-CS-0000071"]{
  .column.third{
    img{
      max-width: 200px;
      @include mq($min-width: map-get($breakpoints, m)) {
        max-width: 280px;
      }
      @include mq($min-width: map-get($breakpoints, l)) {
        max-width: 300px;
      }
      @include mq($min-width: map-get($breakpoints, xl)) {
        max-width: 100%;
      }
    }
  }
  .opc-cms-content-wrapper .column.third {
    width: 100%!important;
    @include mq($min-width: map-get($breakpoints, s)) {
      width: 50%!important;
    }
    @include mq($min-width: map-get($breakpoints, l)) {
      width: calc(100% / 3)!important;
    }
    h5{
      margin: 10px 0 ;
    }
  }
}

.content[data-cms-site="ZZZ-CS-0000053"]{
  margin-top: 20px!important;
  @include mq($min-width: map-get($breakpoints, m)) {
    margin-top: 0!important;
  }
  .opc-cms-content-wrapper .column.half {
    width: 100%!important;
    @include mq($min-width: map-get($breakpoints, m)) {
      width: 50%!important;
    }
  }
}