/*###############################################

@Title: Article Stock
@Description:
This file contains the article stock styles

###############################################*/

.opc-stock {
    vertical-align: middle;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    .stock-label {
        display: none;
        vertical-align: middle;
    }
    svg {
        font-size: 1.2em;
        margin-right: 0.5rem;
        flex-shrink: 0;
    }
    .stock-value {
        vertical-align: middle;
    }
    .stock-location {
        display: flex;
        align-items: center;
        .stock-name {
            margin-right: 0.4rem;
            &:empty {
                display: none;
            }
        }
        .stock-value {
            font-weight: bold;
            margin-right: 0.4rem;
        }
    }
    &[data-op-status="on-stock"] {
        svg {
            color: $stock-on-stock;
        }
        .stock-value {
            color: $stock-on-stock;
        }
    }
    &[data-op-status="out-stock"] {
        svg {
            color: $stock-out-stock;
        }
        .stock-value {
            color: $stock-out-stock;
        }
    }
    &[data-op-status="call-stock"] {
        svg {
            color: $stock-call-stock;
        }
        .stock-value {
            color: $stock-call-stock;
        }
    }
    &[data-op-status="less-stock"] {
        svg {
            color: $stock-less-stock;
        }
        .stock-value {
            color: $stock-less-stock;
        }
    }
    &[data-op-status="na-stock"] {
        svg {
            color: $stock-na-stock;
        }
        .stock-value {
            color: $stock-na-stock;
        }
    }
}