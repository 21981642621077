.opc-favorite-info {
    &[data-op-layout="1"] {
        >.opc-button {
            .favorite-count {
                background-image: linear-gradient(to bottom right, $dark-blue, $blue);
            }
        }
    }
}

.opc-compare-info {
    &[data-op-layout="2"] {
        >.opc-button {
            border: none;
            .compare-count {
                width: 1.8rem;
                height: 1.8rem;
                display: block;
                background: $white;
                border-radius: 50%;
                font-size: 1.1rem;
                text-align: center;
                line-height: 1.8rem;
                color: $dark-blue;
                margin-right: 3px;

                &::before, &::after {
                    display: none;
                }
            }
        }
    }
}