.opc-article-list {
    >li {
        border-right: 1px solid $grey;
        padding: 0 2.5rem;
        margin-bottom: 4rem;
        position: relative;

        &:nth-child(4n) {
            border-right: none;
        }

        .article-list-item {
            border: none;
            display: flex;
            flex-direction: column;
            height: 100%;
            cursor: pointer;

            &:hover {
                border: none;
                .details-button{
                    background-position: calc(100% + 3px) 8px!important;
                }
            }

            .article-list-item-flags{
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            .article-list-item-details {
                .opc-price {
                    min-height: 2.4rem;
                    margin-bottom: 0.5rem;
                    .price-info{
                        margin-bottom: 3px;
                    }
                }

                .article-list-item-title {
                    font-weight: 400;
                    font-size: 1.8rem;
                    line-height: calc(2.4 / 1.8);
                    margin-bottom: 0.5rem;
                    height: auto;
                    overflow: visible;
                    .brand-name{
                        font-weight: 700;
                        display: block;
                    }
                }

                .article-list-item-artno{
                    font-size: 1.6rem;
                    padding: .5rem 0;
                }

                .article-list-item-description {
                    //height: 3.4rem;
                    overflow: hidden;

                    p,
                    .is-product-msg {
                        height: 4.4rem;
                        font-size: 1.4rem;
                        overflow: hidden;
                        margin-bottom: 0;
                        padding: 0.5rem 0;
                        line-height: 1.3;

                        &:empty {
                            display: none;
                        }
                    }

                    .is-product-msg {
                        background: #eee;
                        display: block;
                        padding: 0.5rem 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-radius: 1rem;
                        font-size: 1.2rem;
                        max-width: 60rem;
                    }
                }
            }

            // styling action buttons
            .article-list-item-actions {
                overflow: visible;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                color: #000;
                margin-top: auto;

                .details-button {
                    margin-left: auto;
                    margin-bottom: 0.5rem;
                }

                .opc-favorite-button {
                    background-color: #fff;

                    &:hover,
                    &:focus {
                        box-shadow: 1px 3px 3px #ccc;
                    }
                }


                .opc-basket-button {
                    width: 4rem;
                    padding: 0;
                    margin-left: auto;

                    svg {
                        margin-right: 0;
                    }

                    span {
                        display: none;
                    }

                    @include mq($min-width: map-get($breakpoints, s)) {}

                    &:hover,
                    &:focus {
                        box-shadow: 1px 3px 3px #ccc;
                    }
                }

                .details-button {
                    margin-top: 2rem;
                    width: auto;
                    margin-left: 0;
                    @include mq($min-width: map-get($breakpoints, m)) {
                        width: auto;
                    }
                    background-image: inline-svg($op-icon-arrow-right-svg, $brand-color-1);
                    background-color: transparent;
                    // background-position: center right;
                    background-position: right 8px;
                    background-size: 24px;
                    background-repeat: no-repeat;
                    padding: 0 3.5rem 0 0;
                    color: $brand-color-1;
                    font-size: 1.8rem;
                    font-weight: 400;
                    position: relative;
                    border-radius: 0;
                    overflow: visible;
                    &::before{
                        content: "";
                        position: absolute;
                        top: -1.5rem;
                        left: 0;
                        width: 5.8rem;
                        height: .3rem;
                        background-color: $brand-color-1;
                    }
                }

            }

            [data-op-article-list-type="list"] & {

                .article-list-item-details {

                    .article-list-item-title {
                        font-size: 1.8rem;
                        font-weight: 600;
                        margin-bottom: 0.5rem;
                        height: auto;
                        overflow: hidden;
                    }
                }

                .article-list-item-actions {
                    overflow: visible;
                    display: flex;

                    color: #000;

                    .details-button {
                        margin-left: auto;
                        margin-bottom: 0.5rem;
                    }

                    .opc-favorite-button {
                        background-color: #fff;
                        margin-right: 0.5rem;

                        &:hover,
                        &:focus {
                            box-shadow: 1px 3px 3px #ccc;
                        }
                    }

                    .opc-more-button {
                        margin-left: 0;
                        margin-right: 0.5rem;
                    }

                    .opc-compare-button {
                        background-color: #fff;
                        margin-left: 0;
                        margin-right: 0.5rem;

                        &:hover,
                        &:focus {
                            box-shadow: 1px 3px 3px #ccc;
                        }
                    }

                    .opc-basket-button {
                        width: 4rem;
                        padding: 0;
                        justify-content: center;

                        svg {
                            margin-right: 0;
                        }

                        span {
                            display: none;
                        }

                        @include mq($min-width: map-get($breakpoints, s)) {}

                        &:hover,
                        &:focus {
                            box-shadow: 1px 3px 3px #ccc;
                        }
                    }

                    .details-button {
                        width: 100%;

                        @include mq($min-width: map-get($breakpoints, m)) {
                            width: auto;
                        }
                    }

                }


            }
        }

        .opc-compare-button {
            position: absolute;
            bottom: 0;
            right: 0;
            width: auto;
            margin-left: 0;
            width: auto;
            background-color: transparent;
            padding: 0 3.5rem 0 0;
            color: $text-color;
            font-size: 1.8rem;
            font-weight: 400;
            border-radius: 0;
            opacity: .6;
            overflow: visible;
            z-index: 2;
            border: 40px solid transparent;
            border-top-width: 20px;
            border-left-width: 20px;
            margin: 0;
            padding: 0;
            span{
                display: none;
            }
            
            &:hover,
            &:focus {
                opacity: 1;
                color: $brand-color-1;
            }
            &.is-active{
                opacity: 1;
                color: $brand-color-1;
            }
        }
    }
}


.opc-carousel.products{
    .opc-article-list {
        overflow: hidden;
        height: 100%;
        >li {
            &:nth-child(4n) {
                border-right: 1px solid $grey;
            }
            .article-list-item{
                padding: 15px 30px;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding: 15px;
                }
            }
        }
    }
    [data-controls="prev"],
    [data-controls="next"] {
        font-size: 4rem;
        background-color: transparent;
        &::before,
        &::after {
            background-size: 4rem;
        }
        
        &:hover {
            background-color: transparent;
        }
        transform: translateX(-9px);
    }
    [data-controls="next"] {
        transform: translateX(9px);
    }
}

// header search 
$_header-spacing: 4rem;
.struct-menu {
    flex: 1;
    // display: none;
    margin-right: $_header-spacing / 2;
    width: 100%;
    min-width: 100%;
    order: 5;
    // position: absolute;
    justify-content: flex-start;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    &::before {
        content: '';
        display: block;
    }

    .navigation-level-1,
    .navigation-level-2{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li{
            font-size: 1.6rem;
            flex-basis: 100%;
            &:hover{
                a{
                    background-color: transparent!important;
                }
            }
            a{
                color: $text-color;
                padding: 1rem 0!important;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 300px;
                white-space: nowrap;
                &::before{
                    height: .8em!important;
                }
            }
        }
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        margin-bottom: 7rem;
        display: flex;
        margin-right: 0;
        margin-top: 0;
        min-width: 0;
        order: 2;
        position: relative;
        .navigation-level-1,
        .navigation-level-2{
            li{
                flex-basis: auto;
                font-size: 2rem;
                a{
                    padding: 3.5rem 0!important;
                    margin-right: 4rem;
                    border-bottom: 5px solid transparent;
                    width: auto;
                    white-space: inherit;
                    &:hover{
                        border-bottom-color: $brand-color-1;
                    }
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
    
    @include mq($min-width: map-get($breakpoints, xl)) {
        margin-right: $_header-spacing;
        .navigation-level-1,
        .navigation-level-2{
            li{
                font-size: 2.4rem;
            }
        }
    }
}

.article-list-header{
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    flex-direction: column;
    .article-list-header-text{
        order: 2;
        .opc-breadcrumb{}
        h1{
            word-break: break-word;
            font-size: 3.4rem;
            @include mq($min-width: map-get($breakpoints, xl)) {
                font-size: 4rem;
            }
        }
        p{
            font-size: 2rem;
            font-weight: 300;
        }
        
        .opc-static-content {
            > div{
                font-size: 1.6rem;
                @include mq($min-width: map-get($breakpoints, xl)) {
                    font-size: 2rem;
                }
                font-weight: 300;
            }
            >button{
                display: none;
            }
            max-height: 130px;
            overflow: hidden;
            position: relative;
            &::after{
                content: "";
                display: block;
                height: 60px;
                background: rgb(255,255,255);
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
            }
            &::before {
                content: '...';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 4rem;
                display: block;
                // background-image: inline-svg($op-icon-angle-down-svg-thin, '#000');
                // background-position: center;
                // background-size: 2rem;
                // background-repeat: no-repeat;
                z-index: 3;
                font-size: 24px;
                text-align: center;
            }
            &.is-open{
                max-height: none;
                &::before,
                &::after{
                    display: none;
                }
            }
            &.edit{
                max-height: none;
                >button{
                    display: block;
                }
                &[data-op-type="editable"] div[id^="staticcontent-"]:empty {
                    display: block;
                }
                &::before,
                &::after{
                    display: none;
                }
            }
            &[data-op-type="editable"] div[id^="staticcontent-"]:empty {
                display: none;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                max-height: none;
                &::before,
                &::after{
                    display: none;
                }
            }
        }
    }
    .article-list-header-image{
        order: 1;
        width: 100%;
        img{
            width: 100%;
            max-width: 100%;
        }
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        flex-direction: row;
        margin-bottom: 4rem;
        .article-list-header-text{
            flex-basis: 56rem;
            padding-right: 2rem;
            order: 1;
        }
        .article-list-header-image{
            width: auto;
            flex-basis: 108rem;
            order: 2;
            margin-top: 1rem;
        }
        .column.full{
            padding: 0 0 0 2rem;
        }
    }
}