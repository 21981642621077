/*###############################################

@Title: Static Content Editor
@Description:
This file contains the static content editor styles

###############################################*/

.opc-static-content-editor {
    max-height: 80vh;
    .static-content-editor-head {
        padding: 1.5rem 2rem;
        position: relative;
        display: none;
        h3 {
            margin: 0;
            font-size: 1.8rem;
        }
        .opc-button {
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: inherit;
        }
        border-bottom: 1px solid #ccc;
    }
    .static-content-editor-body {
        padding: 0;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
        border: 1px solid #ccc;
        .mce-tinymce {
            border: none !important;
        }
    }
    .static-content-editor-footer {
        padding: 1rem 2rem;
        padding: 1rem 0;
        text-align: right;
    }
}