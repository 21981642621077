/*###############################################

@Title: Item List
@Description:
This file contains the item list styles

###############################################*/

.opc-item-list {
    margin-bottom: 2rem;
    .item-list-head,
    .list-item {
        width: 100%;
        display: flex;
    }
    .item-list-head {
        border-bottom: 2px solid #999;
        font-weight: bold;
        .list-item-column {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .item-list-body {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .list-item {
        align-items: center;
        border-bottom: 1px solid #ccc;
        transition: all 0.2s ease-in-out;
        &:hover {
            background: #f8f8f8;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        .list-item-label {
            font-weight: bold;
            margin-right: 0.5rem;
        }
        .list-item-value {
            display: inline-block;
            margin-right: 0.4rem;
            margin-bottom: 0.4rem;
        }
        .editmode {
            align-items: center;
            flex-wrap: wrap;
            display: flex;
            display: none;
        }
        form {
            align-items: flex-start;
            flex-wrap: wrap;
            display: flex;
            width: 100%;
        }
        a {
            color: #000;
            &:hover {
                color: lighten(#000, 40%);
            }
        }
        &.is-changing {
            .editmode {
                display: flex;
            }
            form {
                .list-item-value {
                    display: none;
                }
            }
            .list-item-column {
				display: block;
                align-self: flex-start;
                padding-right: 0.5rem;
                .opc-form-field-group {
                    .opc-form-field {
                        margin-right: 0.5rem;
                        flex-grow: 1;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
                .opc-form-field {
                    margin-bottom: 0.5rem;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .list-item-column {
		flex-grow: 0;
		width: 33%;
		min-width: 8rem;
		display: flex;
		align-items: center;

        .list-item-label {
            display: inline-block;
            display: none;
        }
    }
}