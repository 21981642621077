/*###############################################

@Title: Page Favorites
@Description:


###############################################*/

.page-favorites,
.page-multi-favorites {
    .favorites-head {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        .favorites-selection {
            flex-grow: 1;
            .opc-form-field {
                margin-bottom: 0;
            }
        }
        .favorites-actions {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            #rename-form {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                display: flex;
                background: #fff;
                .opc-form-field,
                input {
                    width: 100%;
                    border: none;
                }
				.opc-form-field {
					input {
						border-bottom: 2px solid #ccc !important;
						margin: 0 map-get($padding, 'large') map-get($padding, 'small');
						padding: 0;

					}
					.form-field-note {
						margin: 0 map-get($padding, 'large') map-get($padding, 'small');
						.ff-error {
							display: block;
						}
					}
					.form-field-error {
						margin: 0 map-get($padding, 'large') map-get($padding, 'small');
						display: block;
					}
				}
            }
        }
        .opc-form-field {
            margin-bottom: 0;
            .headline-select {
                width: 100%;
                border: none !important;
                ;
                background: transparent;
                border-bottom: 2px solid #ccc !important;
                padding: 1rem 0 !important;
                margin-bottom: 0;
                appearance: none;
                height: auto !important;
                line-height: 1.2 !important;
                background-image: inline-svg($op-icon-angle-down-svg, '#000');
                background-position: calc(100% - 1rem) center;
                background-size: 2rem;
                background-repeat: no-repeat;
                @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h1, $_opc-font-size-h1-max);
                &::-ms-expand {
                    display: none;
                }
            }
        }
        h4 {
            font-size: 1.4rem;
            margin-bottom: 1rem;
            padding: 0;
        }
    }
    .favorites-desc {
        margin-bottom: 4rem;
        background: #f8f8f8;
        padding: 2rem;
        h4 {
            margin-bottom: 0.5em;
        }
    }
}