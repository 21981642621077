/*###############################################

@Title: Manager
@Description:
This file contains the Manager Styles 

###############################################*/

.opc-manager-update {
    text-align: center;
}

.opc-manager-stage {
    margin-top: 20px;
    .opc-col>div {
        border: 1px solid grey;
        padding: 15px;
        text-align: center;
        min-height: 325px;
    }
    .stage-action {
        margin: 10px;
    }
    .stage-cache {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        span.opc-button {
            pointer-events: none;
        }
    }
}
.opc-manager-cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
.opc-cookie-message {
    background: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    flex-direction: column;
    text-align: center;
    @include mq($min-width: map-get($breakpoints, s)) {
        flex-direction: row;
    }
    p {
        font-size: 1.2rem;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-bottom: 0;
            margin-right: 1rem;
        }
        a {
            color: $brand-color-1;
        }
    }
    .opc-button {
        flex-shrink: 0;
    }
}