.opc-basket-info {
    >.opc-button {
        font-size: $_opc-basket-button-font-size;
        border: none;
        background: none;
        margin-left: 0.5rem;
        cursor: pointer;
        position: relative;
        padding: 0 0.5rem;
        svg {
            font-size: 4rem;
        }
        &:hover {}
        .basket-info-count {
            width: 1.8rem;
            height: 1.8rem;
            display: block;
            background: $brand-color-3;
            border-radius: 50%;
            position: absolute;
            top: auto;
            bottom: 0;
            right: 0;
            transform: none;
            font-size: 1.1rem;
            font-weight: 600;
            text-align: center;
            line-height: 1.8rem;
            color: $brand-color-1;
            &:empty {
                display: none;
            }
        }
    } 
    
    .mini-basket{
        position: fixed;
        top: 9.5rem;
        right: 0;
        bottom: -9.5rem;
        opacity: 0;
        transition: transform .2s linear, opacity .3s linear;
        display: block;
        background: rgba(240, 241, 241, 1);
        max-height: calc(100vh);
        font-weight: 300;
        z-index: 400;
        width: 100%;
        max-width: 100%;
        transform: translateX(100%);
		@include mq($min-width: map-get($breakpoints, m)) {
            box-shadow: 0 0 10px rgba(#000,.16);
            transition: transform .3s linear, opacity .4s linear;
            width: 50rem;
            transform: translateX(52rem);
		}
        
        &.is-open{
            transform: translateX(0);
            opacity: 1;
            .mini-basket-head{
                transform: translateX(0);
            }
            .mini-basket-body,
            .mini-basket-footer{
                opacity: 1;
            }
        }
        .mini-basket-head{
            font-weight: 300;
            font-size: 2.2rem;
            cursor: pointer;
            transform: translateX(10rem);
            transition: transform .2s ease-in-out;
            transition-delay: .3s;
            // &::after{
            //     content: "";
    		// 	background-image: inline-svg($op-icon-cross-svg, '$offcanvas-color');
            //     display: block;
            //     width: 3rem;
            //     height: 3rem;
            //     float: right;
            // }
        }
        .mini-basket-body{
            max-height: calc(100vh - 216px);
            opacity: 0;
            transition: opacity .4s ease-in;
            transition-delay: .5s;
            ul.basket-item-list{
                li.basket-item{
                    .basket-item-thumb{
                        display: none;
                    }
                    .basket-item-info{
                        padding-right: 2rem;
                        .basket-item-title{
                            padding-right: 0;
                            // display: inline;
                            margin-bottom: .5rem;
                            a{
                                font-weight: 300;
                                font-size: 1.6rem;
                            }
                        }
                        .basket-item-article-number{
                            color: $text-color;
                            font-size: 1.6rem;
                            // display: none;
                        }
                        .basket-item-quantity{
                            &::before{
                                display: block;
                                content: " ";
                            }
                        }
                        .basket-item-unit{}
                        .basket-item-price-wrapper{
                            margin-right: -2rem;
                            display: none;
                            .basket-item-currency,
                            .basket-item-price{
                                font-size: 1.4rem;
                                font-weight: 300;
                                color: $text-color;
                            }
                        }
                    }
                }
            }
            .basket-more-items{
                .opc-button[data-op-style="secondary"]{
                    color: $text-color;
                    margin-left: 1.3rem;
                }
            }
            .brand-name{
                font-weight: 700;
            }
        }
        .mini-basket-footer{
            margin-top: 1rem;
            padding: 2rem;
            opacity: 0;
            transition: opacity .4s ease-in;
            transition-delay: .6s;
            .mini-basket-footer-total{
                font-weight: 500;
                font-size: 1.6rem;
                display: none;
                b{
                    font-weight: 300;
                }
            }
        }
    }
}
