/*###############################################

@Title: Flags
@Description:
This file contains the article flag styles

###############################################*/

$_opc-flags-new-bg: #2eacb0 !default;
$_opc-flags-new-color: #fff !default;
$_opc-flags-sale-bg: #d85d40 !default;
$_opc-flags-sale-color: #fff !default;
$_opc-flags-bestseller-bg: #8fd840 !default;
$_opc-flags-bestseller-color: #fff !default;
$_opc-flags-size: 6rem !default;
$_opc-flags-font-size: 1.4rem !default;

.opc-flags {
    display: flex;
    .flag-news,
    .flag-free1,
    .flag-free2 {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $_opc-flags-size;
        height: $_opc-flags-size;
        line-height: $_opc-flags-size;
        border-radius: 50%;
        text-align: center;
        transform: rotate(-30deg);
        font-weight: bold;
        text-transform: uppercase;
        font-size: $_opc-flags-font-size;
        white-space: pre-line;
        margin-right: 1rem; 

        [data-op-article-list-type="list"] & {
            width: $_opc-flags-size / 1.5;
            height: $_opc-flags-size / 1.5;
            line-height: $_opc-flags-size / 1.5;
            font-size: $_opc-flags-font-size / 1.5;
            margin-right: 0.5rem; 
        }
        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        svg {
            font-size: 4rem;
            margin: 0;
            flex-shrink: 0;
        }
    }
    .flag-news {
        background: $_opc-flags-new-bg;
        color: $_opc-flags-new-color;
    }
    .flag-free1 {
        background: $_opc-flags-sale-bg;
        color: $_opc-flags-sale-color;
    }
    .flag-free2 {
        background: $_opc-flags-bestseller-bg;
        color: $_opc-flags-bestseller-color;
    }
    &[data-op-layout="icon"] {
        
        svg {
            font-size: $_opc-flags-size;
            [data-op-article-list-type="list"] & {
                font-size: $_opc-flags-size / 1.5;
            }
        }
        .flag-news {
			background: transparent !important;
            color: $_opc-flags-new-bg;
            
        }
        .flag-free1 {
			background: transparent !important;
            color: $_opc-flags-sale-bg;
           
        }
        .flag-free2 {
			background: transparent !important;
            color: $_opc-flags-bestseller-bg;
           
        }
    }
    &[data-op-layout="text"] {
        svg {
            display: none;
        }
    }
}