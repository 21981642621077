/*###############################################

@Title: Page Offline
@Description:


###############################################*/

.offline {
    padding: 10rem 4rem 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    .brand {
        margin-bottom: 2rem;
    }
    h1 {}
    svg {
        font-size: 18rem;
        opacity: 0.1;
        margin-bottom: 2rem;
    }
    .lead {
        margin-bottom: 4rem;
    }
}