/*###############################################

@Title: Sections
@Description:


###############################################*/


// namespace vars
$section-padding: 4rem;
// section layout styles




.section-wrapper {
    @extend %mw-custom;
    max-width: 100%;
    overflow: hidden;
    padding-top: $section-padding / 3;
    padding-bottom: $section-padding / 3;

    @include mq($min-width: map-get($breakpoints, s)) {
        padding-top: $section-padding / 2;
        padding-bottom: $section-padding / 2;
    }

    @include mq($min-width: map-get($breakpoints, m)) {
        padding-top: $section-padding / 1.5;
        padding-bottom: $section-padding / 1.5;
    }

    @include mq($min-width: map-get($breakpoints, l)) {
        padding-top: $section-padding;
        padding-bottom: $section-padding;
    }

    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.hero-section {
        .section-inner {
            max-width: $mw-max;
            overflow: hidden;
            .hero-wrapper{
            }
        }
    }

    .section-inner {
        @extend %mw-inner;
        @include clearfix();


        // used for aside with content block
        .section-inner-row {

            @include mq($min-width: map-get($breakpoints, m)) {
                @include break;
                @include susy-clearfix;
                margin-left: -$_opc-grid-gutter-width;
                margin-right: -$_opc-grid-gutter-width;
            }

            .section-inner-aside {
                width: 100%;
                margin-bottom: 2rem;

                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-bottom: 0;
                    @include span(3);
                }
            }

            .section-inner-content {
                width: 100%;

                @include mq($min-width: map-get($breakpoints, m)) {
                    @include span(9);
                }
            }
        }

        .section-inner-content {}
    }

    &.hero-menu-section {
        margin-bottom: 4rem;
        display: none;
        @include mq($min-width: map-get($breakpoints, l)) {
            display: block;
        }
        @include mq($min-width: map-get($breakpoints, xxl)) {
            margin-bottom: 8rem;
        }
        .section-inner {
            max-width: $mw-max;
            background-color: $light-grey;
        }
        .hero-menu-wrapper{
            padding-left: 2rem;
            padding-right: 2rem;
            @include mq($min-width: map-get($breakpoints, xxl)) {
                padding-left: 10rem;
                padding-right: 10rem;
            }
            .opc-navigation {
                margin-right: -4rem;
                .navigation-level-1 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    @include mq($min-width: map-get($breakpoints, xxl)) {
                        justify-content: space-between;
                    }
                    &::after {
                        display: none;
                    }
                    >li {
                        flex-basis: 100%;
                        font-size: 1.8rem;
                        a {
                            color: $text-color;
                            border-bottom: 5px solid transparent;
                            padding: 1.8rem 0 0.8rem;

                            &:hover {
                                // color: $brand-color-1;
                                border-bottom-color: $brand-color-1;
                            }
                            &::before {
                                display: none;
                            }
                        }
                        @include mq($min-width: map-get($breakpoints, s)) {
                            font-size: 2.0rem;
                            flex-basis: calc(100% / 2);
                            a{
                                padding: 2.8rem 0 2.3rem;
                            }
                        }
                        @include mq($min-width: map-get($breakpoints, m)) {
                            font-size: 2.4rem;
                            flex-basis: calc(100% / 2);
                        }
                        @include mq($min-width: map-get($breakpoints, xxl)) {
                            flex-basis: initial;
                            margin-bottom: 0;
                            a{
                                margin-right: 4rem;
                                padding: 4.8rem 0 4.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
    &.mobile-only{
        margin-top: 20px;
        @include mq($min-width: map-get($breakpoints, l)) {
            display: none;
        }
    }

    &.news-section{
        .section-inner{
            >h2{
                display: inline;
                margin-right: 2rem;
            }
            >a{
                font-size: 1.8rem;
                font-weight: 500;
            }
            .news-content-wrapper{
                margin-top: 3rem;
                display: flex;
                flex-wrap: wrap;
                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-right: -4rem;
                }
                .news-content{
                    flex-basis: 100%;
                    background-color: $light-grey;
                    margin-bottom: 4rem;
                    display: flex;
                    flex-direction: column;
                    @include mq($min-width: map-get($breakpoints, m)) {
                        flex-basis: calc(100% / 2 - 4rem);
                        margin-right: 4rem;
                        &:nth-child(2n){
                            margin-right: 0;
                        }
                    }
                    background-color: $light-grey;
                    @include mq($min-width: map-get($breakpoints, l)) {
                        flex-basis: calc(100% / 3 - 4rem);
                        margin-right: 4rem;
                        margin-bottom: 4rem;
                        &:nth-child(2n){
                            margin-right: 4rem;
                        }
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                    }
                    .news-image{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        position: relative;
                        padding-bottom: (calc(360 / 520 * 100%));
                        
                        flex-shrink: 0;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                    .news-text{
                        padding: 2rem;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        flex-grow: 1;
                        h2{
                            font-size: 2.4rem;
                            font-weight: 500;
                        }
                        p{
                            font-size: 1.8rem;
                            margin-bottom: 3rem;
                        }
                        .details-button{
                            margin-top: auto;
                            &:hover{
                                color: $brand-color-1;
                                background-position: calc(100% + 3px) 8px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.brands-section{
        background-color: $light-grey;
        margin-top: 4rem;
        margin-bottom: 6rem;
        ul{
            margin: 0;
            padding: 6rem 0;
            margin-left: -2rem;
            margin-right: -2rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            li{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                flex-basis: calc(100% / 2);
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: calc(100% / 4);
                }
                margin: 0;
                padding: 2rem;
                list-style-type: none;
                position: relative;
                img{
                    max-width: 100%;
                }
                &::after{
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: #F6F6F6;
                    mix-blend-mode: multiply;
                    pointer-events: none;
                }
            }
        }
    }

}

.Inaktiv{
    display: none !important;
    [data-portal-type="dev"] &{
        display: list-item!important;
    }
}