/*###############################################

@Title: Page Compare
@Description:


###############################################*/

.compare-wrapper {
    position: fixed;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
    background: #fff; //box-shadow: 0 0 100px #666;
    overflow: auto;
    background: $white;
}

.compare-topbar {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    padding: 1rem;
    height: 7rem;
    background: $white;
    align-items: center;
    // border-bottom: 1px solid $compare-grey;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    // &::before{
    //     content: "";
    //     display: block;
    //     max-width: 163px;
    //     flex-grow: 1;
    // }
    .compare-topbar-logo {
        img {
            height: 5.2rem;
            display: block;
        }
    }
    .compare-topbar-exit {}
}

.opc-compare {
    z-index: 100000;
    background: $white;
    .compare-list {
        width: 100%;
        padding-top: 7rem;
    }
    .opc-message {
        margin-top: 8rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .compare-list-head {
        position: sticky;
        top: 0;
        z-index: 5;
    }
    .compare-row {
        display: flex;
        // justify-content: center;
        &.headrow {
            position: sticky;
            top: 0;
            z-index: 3;
        }
        &[data-op-id="Image"] {
            .compare-item {
                .value {
                    display: block;
                    width: 100%;
                    text-align: center;
                    img {
                        height: 12rem;
                        max-width: 100%;
                    }
                }
            }
        }
        &[data-op-id="Remove"] {
            .compare-item {
                .value {
                    display: block;
                    text-align: right;
                }
            }
        }
        &[data-op-id="Price"]{
            .compare-label{
                padding: 20px 10px;
            }
            .compare-item {
                padding: 20px 10px;
                .price-basis{
                    top: calc(-100% + 10px);
                }
            }
        }
        .value.diff {
            
        }
        .compare-label {
            width: 30rem;
            flex-shrink: 0;
            position: sticky;
            left: 0;
            background: $white;
            padding: 1rem;
            text-align: right;
            border-left: 1px solid $compare-grey;
            border-bottom: 1px solid $compare-grey;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            z-index: 2;
            background-color: $light-grey;
            select {
                margin-top: 0.5rem;
                @extend .opc-select;
            }
        }
        .compare-item {
            width: 30rem;
            flex-grow: 1;
            flex-shrink: 0;
            padding: 1rem;
            background: #fff; //border-right: 1px solid $compare-grey;
            border-bottom: 1px solid $compare-grey;
            &.dif {
                .value {
                    background: #F8EAD3;
                    padding: 3px;
                }
            }
            .value{
                .opc-price{
                    margin-bottom: 0!important;
                    .price-info {
                        line-height: 21px;
                    }
                }
            }
            &:nth-of-type(2) {}
            &:nth-of-type(4) {}
            &:nth-of-type(3) {}
            &:nth-of-type(5) {}
        }
        [class*="compare-item col"]{
            background-color: $white;
            border-left: 1px solid $compare-grey;
            max-width: 40rem;
        }
        .compare-item{
            &:last-child{
                border-right: 1px solid $compare-grey;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
            }
        }
        &:first-child{
            .compare-label,
            .compare-item{
                border-top: 1px solid $compare-grey;
            }
        }
    }
    .compare-group {
        .compare-row {
            &:first-of-type {
                //.compare-label,
                //.compare-item {border-top: 1px solid #000; }
            }
        }
    }
    .compare-group-title {
        position: relative;
        position: sticky;
        display: none;
        left: 0;
        width: 30rem;
        padding: 0;
        height: 4rem;
        cursor: pointer;
        .group-title-inner {
            // border-bottom: 1px solid $compare-grey;
            position: absolute;
            left: 0;
            top: 0;
            height: 4rem;
            display: flex;
            align-items: center;
            padding: 0 0 0 1rem;
            width: calc(100vw - 20px);
            width: 100vw;
            background: #fff;
            @extend .h4;
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 100%;
                width: 2000px;
                top: 0;
                bottom: 0;
                background: #fff;
                display: none;
            } //background: $dark-grey;
        }
        & + .compare-group{
            .compare-row{
                &:first-child{
                    .compare-label,
                    .compare-item{
                        border-top: none;
                    }
                }
            }
        }
    }
}

.opc-compare-selection{
    select{
        font-size: 14px!important;
        border-color: $brand-color-1!important;
        border-width: 2px!important;
        background-color: rgba($brand-color-1,.1)!important;
        cursor: pointer!important;
    }
}