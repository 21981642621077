.not-supported {
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    margin: auto; 
    box-shadow: 0 0 40px #ccc;
    position: relative;
    z-index: 10;
    max-width: 100%;


    .brand {
        margin-bottom: 2rem;
    }
    h2 { text-align: center; }
    svg {
        font-size: 18rem;
        opacity: 1;
        margin-bottom: 2rem;
    }
    .lead {
        margin-bottom: 2rem;
        text-align: center;
		width: 100%;
    }

    .browser-list {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2rem;

      button {
        width: auto;
        width: 9rem;
        height: 11rem;
        margin: 0 0.5rem;
        padding:1rem;
        display: block;
        background: transparent;
        border: none;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background: #eee;
        }
        svg {
          width: 100%;
          margin:0;
          padding:0;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .opc-button {
        white-space: normal;
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}