$_opc-session-timeout-bg-color: rgba(#FFF, 0.95) !default;
$_opc-session-timeout-padding: 2rem !default;

.opc-manager-session-timeout {
    display: none;
}

.session-timeout-message-active{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.opc-manager-session-timeout {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: $_opc-session-timeout-bg-color;
        padding: $_opc-session-timeout-padding;

        .session-timeout-title {
            max-width: 100%;
        }
    }
}