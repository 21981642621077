.opc-notifications .notifications-dialog .notifications-header{
	padding: 1rem 2rem;
}
.notifications-content {
    .opc-basket-button {
		
		//max-width: 30rem;
		flex-direction: column;
		align-items: center;

		.basket-button-dim-1,
		.basket-button-dim-2,
		.basket-button-dim-3 {
			.basket-button-quantity {
				width: calc(100% - 8rem);
			}
		}
		> .opc-button {
			width: 100%;
			margin: 0;
			margin-top: 1rem;

			&:last-child {
				// margin-top: 0;
			}

			&[data-op-style="primary-text"] {
				color: #999;

				&:hover {
					color: #666;
				}
			}
		}
    }
		>h2{
			font-weight: 400;
			font-size: 2.8rem;
			padding-right: 2rem;
			.brand-name{
				display: block;
			}
			.article-name{}
		}
}



 

.basket-modal {
	width: 32rem;
	padding: 2rem 2rem 1rem;
	box-shadow: 4px 4px 10px #ccc;
	border: 1px solid #eee;
	background:#fff;

	.opc-price {
		@extend .text;
		margin-bottom: 0;

		.price-basis {
			font-size: inherit;
		}
	}
	h2 {
		@extend .h3;
		margin-bottom: 2rem;
	}


	.opc-basket-button {
		display: flex;
		flex-direction: column;

		.basket-button-dim-variants-wrapper,
		.basket-button-dim-1,
		.basket-button-dim-2,
		.basket-button-dim-3 {
            width: 100%;
            max-width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 0.5rem;
			margin-left: 0;

			button {
				height: map-get($heights, medium);
				margin: 0;
				background: #fff;
				border: 1px solid #ccc;
				width: map-get($heights, medium);
				flex-grow: 0;
				flex-shrink: 0;
				padding: 0;

				+ input {
					border-left: none;
					border-right: none;
				}
			}

			label {
                width: 40%;
                max-width: 60%;
				flex-shrink: 0;
				font-weight: 600;
			}
			select {
                width: 60%;
                max-width: 60%;
				margin-right: 0;
			}
			input {
                width: 60%;
                max-width: 60%;
				border: 1px solid #ccc;
				margin-right: 0;
			}
		}

		.basket-button-dim-variants-wrapper {
			margin-bottom: 1rem;
		}

		> button.opc-button {
			margin-top: 2rem;
		}



	}


}