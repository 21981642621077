/*###############################################

@Title: Topbar
@Description:


###############################################*/


// namespace vars

$_topbar-bg: #fff;
$_topbar-padding: 1rem;
$_topbar-group-divider-color: #eee;


#topbar-login-form {
	display: none;
}

// topbar layout styles

.header-search-wrapper{
	@extend %mw-custom;
	height: 9.5rem;
	transition: all 0.3s ease-in-out;
	transform: translateY(-9.5rem);
	background-color: $light-grey;
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	z-index: 99999;
	.header-search-wrapper-inner{
		@extend %mw-inner;
		position: relative;
		width: 100%;
		.header-search{
			display: flex;
			justify-content: center;
		}
		.opc-button.close{
			z-index: 99999;
			position: absolute;
			right: 0;
			top: 10px;
		}
	}
}
.search-is-open{
	.header-search-wrapper{
		overflow: visible;
		transform: translateY(0);
	}
}


.topbar-wrapper {
	@extend %mw-custom;
	background: $_topbar-bg;
	padding-top: $_topbar-padding;
	padding: 0 20px;
	display: none;
	z-index: 1;
	@include mq($min-width: map-get($breakpoints, m)) {
		display: block;
	}
	@include mq($min-width: 1680px) {
		padding: 0;
	}

	.topbar-inner {
		@extend %mw-inner;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 5rem;
		.topbar-actions {
			display: flex;
			.topbar-group {
				&:first-child{
					padding-left: 0;
				}
			}
		}
		.topbar-navigation {
			margin-left: 0;
			padding-left: 0;
			flex-grow: 0;
			.opc-navigation {
				ul {
					display: flex;
					li {
						&:hover {
							a {
								background: transparent;
								color: $brand-color-1;
							}
						}
						a {
							padding: 0.5rem 1rem;
							text-decoration: none;
							color: $text-color;
							&::before {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	
	.topbar-group {
		display: inline-block;
		text-align: left;
		padding-left: 1rem;
		margin-left: 1rem;
		position: relative;
		font-size: 1.4rem;
		font-weight: 400;
		&:first-child {
			&::before {
				display: none;
			}
		}
		.opc-button {
			font-size: 1.4rem;
			display: flex;
		}
		select {
			font-weight: 600;
			border: none;
		}
		a {
			text-decoration: none;
			color: $text-color;
			&:hover {
				color: $brand-color-1;
			}
		}

		.count {
			&::before {
				content: '(';
			}
			&::after {
				content: ')';
			}
		}

		.opc-language-selection {
			display: flex;
			align-items: center;
			&.is-open{
				background-color: transparent;
				ul{
					background-color: transparent;
					li{
						background-color: transparent;
						a{
							background-color: transparent;
						}
					}
				}
			}
			.language-selection-label {
				display: none;
				padding: 0 .5rem 0 0;
				color: $text-color;
				.current-selection {
					display: none;
				}
			}
			ul {
				position: static;
				display: flex;
				background-color: transparent;
				color: $text-color;
				li {
					border: none !important;
					display: flex;
					align-items: center;
					>span{

						padding: 0.5rem 0;
						margin-right: .5rem;
						margin-left: .5rem;
						color: $text-color;
						text-transform: lowercase;
						display: block;
					}
					a {
						padding: 0.5rem 0;
						margin-right: .5rem;
						margin-left: .5rem;
						color: $text-color;
						text-transform: lowercase;
						display: block;
						&:hover{
							color: $brand-color-1;
							background-color: transparent;
						}
					}
					position: relative;
					&:first-child{
						&::after{
							content: "";
							display: block;
							width: 0;
							border-right: 1px solid $text-color;
							height: 20px;
						}
					}
					&.is-active {
						>span{
							opacity: 1;
							color: $text-color;
							font-weight: 600;
							background-color: transparent;
						}
						a {
							opacity: 1;
							color: $text-color;
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	.admin-panel {}
}