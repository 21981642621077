.opc-carousel {
    .carousel-slide-content {
        padding: 2rem;
        .carousel-slide-content-inner {
            background: rgba(#fff, 0.6);
            max-width: 40rem;
            padding: 2rem;
        }
    }
    .op-mediaslider-outer{
        .op-mediaslider-controls{
            top: 46%;
        }
        .opc-article-list {
            >li {
                .article-list-item{
                    padding-left: 20px;
                    padding-right: 35px;
                }
            }
        }
    }
    h2 {
        span {
            @extend .h1;
        }
    }
}