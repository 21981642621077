/*###############################################

@Title: The Grid
@Description:
This file set the grid the correct way

###############################################*/

// basic grid config
$_opc-grid-columns: 12 !default;
$_opc-grid-gutter-width: 15px !default;
$_opc-grid-column-width: 120px !default;
$susy: ( 
    columns: $_opc-grid-columns, 
    gutter-position: inside-static, 
    column-width: $_opc-grid-column-width, 
    gutter: $_opc-grid-gutter-width/$_opc-grid-column-width
);

// normal opc-grid row
.opc-row,
%opc-row {
    @include break;
    @include susy-clearfix;
    display: flex;
    flex-wrap: wrap;
    margin-left: -$_opc-grid-gutter-width;
    margin-right: -$_opc-grid-gutter-width;
}

// normal grid col
.opc-col,
%opc-col {
    position: relative;
    padding-left: $_opc-grid-gutter-width;
    padding-right: $_opc-grid-gutter-width;
    margin-bottom: 2rem;
    width: span(12);
}

// fixed width grid 
[data-op-col="1"] {
    width: span(1);
    max-width: span(1);
}

[data-op-col="2"] {
    width: span(2);
    max-width: span(2);
}

[data-op-col="3"] {
    width: span(3);
    max-width: span(3);
}

[data-op-col="4"] {
    width: span(4);
    max-width: span(4);
}

[data-op-col="5"] {
    width: span(5);
    max-width: span(5);
}

[data-op-col="6"] {
    width: span(6);
    max-width: span(6);
}

[data-op-col="7"] {
    width: span(7);
    max-width: span(7);
}

[data-op-col="8"] {
    width: span(8);
    max-width: span(8);
}

[data-op-col="9"] {
    width: span(9);
    max-width: span(9);
}

[data-op-col="10"] {
    width: span(10);
    max-width: span(10);
}

[data-op-col="11"] {
    width: span(11);
    max-width: span(11);
}

[data-op-col="12"] {
    width: span(12);
    max-width: span(12);
}

// global grid extensions for multiple breakpoints (mobile first! first value = smallest device)
[data-op-col="12-6-3"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(3);
        max-width: span(3);
    }
}

[data-op-col="12-6-4"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        width: span(4);
        max-width: span(4);
    }
}

[data-op-col="12-6"] {
    width: 100%;
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(6);
        max-width: span(6);
    }
}

[data-op-col="12-8"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(8);
        max-width: span(8);
    }
}

[data-op-col="12-4"] {
    @include mq($min-width: map-get($breakpoints, s)) {
        width: span(12);
        max-width: span(12);
    }
    @include mq($min-width: map-get($breakpoints, m)) {
        width: span(4);
        max-width: span(4);
    }
}