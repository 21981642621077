/*###############################################

@Title: Static Content
@Description:
This file contains the static content styles

###############################################*/

.opc-static-content {
    &[data-op-style="span2"] {
        width: span(2);
        max-width: span(2);
    }
    &[data-op-style="span4"] {
        width: span(4);
        max-width: span(4);
    }
    &[data-op-style="span6"] {
        width: span(6);
        max-width: span(6);
    }
    &[data-op-style="span8"] {
        width: span(8);
        max-width: span(8);
    }
    &[data-op-style="span10"] {
        width: span(10);
        max-width: span(10);
    }
    &[data-op-type="editable"] {
        div[id^="staticcontent-"]:empty {
            padding: 4rem;
            border: 3px dotted #ccc;
            border-radius: 10px;
            text-align: center;
            color: #ccc;
            margin-bottom: 10px;
            &::before {
                content: 'WYSIWYG-CONTENT';
            }
        }
    }
    .opc-button {
        background: #333;
        border: 1px solid #fff;
        color: #fff;
    }
}