/*###############################################

@Title: Mainnav
@Description:


###############################################*/

// namespace vars

$mainnav-bg: $light-grey;
$mainnav-padding: 1rem;


// mainnav layout styles

.mainnav-area {
	position: relative;
	opacity: 0;
	pointer-events: none;
	// transition: opacity .3s ease-in-out;
	&.is-open{
		opacity: 1;
		pointer-events: all;
	}
}

.mainnav-wrapper {
	@extend %mw-custom;
	position: relative;
	z-index: 100;
	background: $mainnav-bg;
	box-shadow: 0px 10px 6px rgba($black, .15);
	display: none;
	padding-top: 8rem;
	padding-bottom: 8rem;
	position: absolute;
	right: 0;
	left: 0;
	min-height: 48rem;

	@include mq($min-width: map-get($breakpoints, m)) {
		display: block;
	}

	@include mq($min-width: map-get($breakpoints, xl)) {}
	
	.mainnav-inner {
		@extend %mw-inner;
	}
	
	.opc-navigation {
		.navigation-level-1 {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			@include mq($min-width: map-get($breakpoints, xxl)) {
				justify-content: space-between;
			}
			
			&::after {
				display: none;
			}
			
			>li {
				font-size: 2rem;
				font-weight: 300;
				flex-basis: calc(100% / 2);
				margin-bottom: 4rem;
				@include mq($min-width: map-get($breakpoints, m)) {
					flex-basis: calc(100% / 3);
				}
				@include mq($min-width: map-get($breakpoints, xxl)) {
					flex-basis: initial;
				}

				a {
					color: $dark-blue;
					padding-right: 2rem;

					&::before {
						display: none;
					}

					&:hover {
						background-color: transparent;
					}
				}

				.navigation-level-2 {

					>li {
						font-size: 1.6rem;
						font-weight: 300;

						a {
							color: $text-color;
							padding-left: 0;

							&::before {
								display: none;
							}

							&:hover {
								background-color: transparent;
								color: $brand-color-1;
							}
						}
					}
				}
			}
		}
	}
}


@supports(position: sticky) {
	.mainnav-wrapper[data-op-sticky="true"] {
		position: sticky;
		top: 7rem;
		transition: transform 0.2s ease-in-out;
		width: 100%;

		&.sticky-inactive {
			//position: fixed;
			transform: translateY(-119px);
		}
	}
}