// Version: 2.5.0
$tns-css-prefix: op-mediaslider;

.#{$tns-css-prefix}-outer {
  padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
  [hidden] { display: none !important; }
  [aria-controls], [data-action] { cursor: pointer; }
  &.ms-touch {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; // Hides the scrollbar.
    -ms-scroll-chaining: none; // Prevents Metro from swiping to the next tab or app.
    -ms-scroll-snap-type: mandatory; // Forces a snap scroll behavior on your images.
    -ms-scroll-snap-points-x: snapInterval(0%, 100%); // Defines the y and x intervals to snap to when scrolling.
  }
}
.#{$tns-css-prefix}-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
  > .#{$tns-css-prefix}-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.#{$tns-css-prefix}-horizontal.#{$tns-css-prefix}-subpixel {
  white-space: nowrap;
  > .#{$tns-css-prefix}-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
  }
}
.#{$tns-css-prefix}-horizontal.#{$tns-css-prefix}-no-subpixel {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  > .#{$tns-css-prefix}-item {
    float: left;
    margin-right: -100%;
  }
}
.#{$tns-css-prefix}-no-calc {
  position: relative;
  left: 0;
}

.#{$tns-css-prefix}-gallery {
  position: relative;
  left: 0;
  min-height: 1px; // make sure slider container is visible
  // overflow: hidden;
  > .#{$tns-css-prefix}-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
  }
  > .#{$tns-css-prefix}-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
  }
}
.#{$tns-css-prefix}-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
  &.loaded { opacity: 1; }
}
.#{$tns-css-prefix}-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}
.#{$tns-css-prefix}-ovh { overflow: hidden; }
.#{$tns-css-prefix}-hdx { overflow-x: hidden; }
.#{$tns-css-prefix}-hdy { overflow-y: hidden; }
.#{$tns-css-prefix}-visually-hidden { position: absolute; left: -10000em; }
.#{$tns-css-prefix}-transparent { opacity: 0; visibility: hidden; }

.#{$tns-css-prefix}-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.#{$tns-css-prefix}-normal, .#{$tns-css-prefix}-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}
