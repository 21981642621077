/*###############################################

@Title: Buttons
@Description:
This file contains the Button styles

###############################################*/

// namespace vars
$_opc-button-base-progress-scale:             0.8 !default;
$_opc-button-base-border-radius:              50px !default;

$_opc-button-primary-link-color:                $primary-button-color !default;
$_opc-button-primary-link-color-hover:          darken($_opc-button-primary-link-color, 20%) !default;
$_opc-button-secondary-link-color:              $secondary-button-color !default;
$_opc-button-secondary-link-color-hover:        darken($_opc-button-secondary-link-color, 20%) !default;

$_opc-button-primary-border-radius:             $_opc-button-base-border-radius !default;
$_opc-button-primary-color:                     rgba(#fff, 0.8) !default;
$_opc-button-primary-color-hover:               rgba(#fff, 1) !default;
$_opc-button-primary-bg:                        $primary-button-color !default;
$_opc-button-primary-bg-hover:                  lighten($_opc-button-primary-bg, 10%) !default;
$_opc-button-primary-progress-color:            $progress-color !default;
$_opc-button-primary-success-color:             $success-color !default;

$_opc-button-secondary-border-radius:           $_opc-button-base-border-radius !default;
$_opc-button-secondary-color:                   rgba(#000, 0.8) !default;
$_opc-button-secondary-color-hover:             rgba(#000, 1) !default;
$_opc-button-secondary-bg:                      $secondary-button-color !default;
$_opc-button-secondary-bg-hover:                lighten($secondary-button-color, 10%) !default;
$_opc-button-secondary-progress-color:          $progress-color !default;
$_opc-button-secondary-success-color:           $success-color !default;

$_opc-button-loud-border-radius:                $_opc-button-base-border-radius !default;
$_opc-button-loud-color:                        rgba(#fff, 0.8) !default;
$_opc-button-loud-color-hover:                  rgba(#fff, 1) !default;
$_opc-button-loud-bg:                           $loud-button-color !default;
$_opc-button-loud-bg-hover:                     lighten($loud-button-color, 10%) !default;
$_opc-button-loud-progress-color:               $progress-color !default;
$_opc-button-loud-success-color:                $success-color !default;

$_opc-button-font-size-s:                       $_opc-font-size-s !default;
$_opc-button-font-size-m:                       $_opc-font-size-m !default;
$_opc-button-font-size-l:                       $_opc-font-size-l !default;
$_opc-button-font-size-xl:                      $_opc-font-size-xl !default;

$_opc-button-disabled-color:					rgba(#000, 0.8) !default;
$_opc-button-disabled-opacity:					0.65;

.opc-button {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0 map-get($padding, 'normal')*2;
    height: map-get($heights, 'normal');
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    outline: none;
    font-size: $_opc-button-font-size-m;

    svg {
        margin-right: map-get($padding, 'tiny');
        font-size: 1.4em;
        flex-shrink:0;
    }

    &.is-progress {
        pointer-events: none;
        transform: scale($_opc-button-base-progress-scale);
    }

    &.is-hidden {
        display: none;
    }

	&:disabled {
		cursor: not-allowed;
		opacity: $_opc-button-disabled-opacity;
	}

    /* --------------------------------------------------------------- */
    // styles
    &[data-op-style="primary"] {
        color: $_opc-button-primary-color;
        background: $_opc-button-primary-bg;
        border-radius: $_opc-button-primary-border-radius;
        &:hover, &:focus {
            color: $_opc-button-primary-color-hover;
            background: $_opc-button-primary-bg-hover;
			&:disabled {
        		color: $_opc-button-primary-color;
        		background: $_opc-button-primary-bg;
			}
        }

        &[data-op-type="radio"] {
            label {
                color: $_opc-button-primary-color;
                background: $_opc-button-primary-bg;
                border-radius: $_opc-button-primary-border-radius;
                &:hover, &:focus {
                    color: $_opc-button-primary-color-hover;
                    background: $_opc-button-primary-bg-hover;
					&:disabled {
						color: $_opc-button-primary-color;
						background: $_opc-button-primary-bg;
					}
                }
            }
        }

        &.is-progress { background: $_opc-button-primary-progress-color !important; }
        &.is-success { background: $_opc-button-primary-success-color !important; }


    }
    &[data-op-style="secondary"] {
        color: $_opc-button-secondary-color;
        background: $_opc-button-secondary-bg;
        border-radius: $_opc-button-secondary-border-radius;

        &:hover, &:focus {
            color: $_opc-button-secondary-color-hover;
            background: $_opc-button-secondary-bg-hover;
			&:disabled {
				color: $_opc-button-secondary-color;
				background: $_opc-button-secondary-bg;
			}
        }

        &.is-progress { background: $_opc-button-secondary-progress-color !important; }
        &.is-success { background: $_opc-button-secondary-success-color !important; }
    }
    &[data-op-style="loud"],
    &[data-op-style="basket"] {
        background: $_opc-button-loud-bg;
        color: $_opc-button-loud-color;
        border-radius: $_opc-button-loud-border-radius;
        &:hover, &:focus {
            color: $_opc-button-loud-color-hover;
            background: $_opc-button-loud-bg-hover;
			&:disabled {
				background: $_opc-button-loud-bg;
				color: $_opc-button-loud-color;
			}
        }

        &[data-op-type="radio"] {
            background: transparent;
            border: none;

            label {
                color: $_opc-button-loud-color;
                background: $_opc-button-loud-bg;
                border-radius: $_opc-button-loud-border-radius;
                &:hover, &:focus {
                    color: $_opc-button-loud-color-hover;
                    background: $_opc-button-loud-bg-hover;
					&:disabled {
						background: $_opc-button-loud-bg;
						color: $_opc-button-loud-color;
					}
                }
            }
        }

        &.is-progress { background: $_opc-button-loud-progress-color !important; }
        &.is-success { background: $_opc-button-loud-success-color !important; }
    }
    &[data-op-style="primary-lined"] {
        background: transparent;
        border-color: $_opc-button-primary-bg;
        color: $_opc-button-primary-bg;
        border-radius: $_opc-button-primary-border-radius;
        &:hover, &:focus {
            color: $_opc-button-primary-bg-hover;
            border-color: $_opc-button-primary-bg-hover;
			&:disabled {
				border-color: $_opc-button-primary-bg;
				color: $_opc-button-primary-bg;
			}
        }

        &[data-op-type="radio"] {
            label {
                background: transparent;
                border-color: $_opc-button-primary-bg;
                color: $_opc-button-primary-bg;
                border-radius: $_opc-button-primary-border-radius;
                &:hover, &:focus {
                    background: $_opc-button-primary-bg;
                    color: $_opc-button-primary-color;
                    border-color: $_opc-button-primary-bg-hover;
					&:disabled {
						background: transparent;
						border-color: $_opc-button-primary-bg;
						color: $_opc-button-primary-bg;
					}
                }
            }
            input:checked + label {
                background: $_opc-button-primary-bg;
                color: $_opc-button-primary-color;
            }
        }

        &.is-progress {
            border-color: $_opc-button-primary-progress-color !important;
            color: $_opc-button-primary-progress-color !important;
        }
        &.is-success {
            border-color: $_opc-button-primary-success-color !important;
            color: $_opc-button-primary-success-color !important;
        }

    }
    &[data-op-style="secondary-lined"] {
        background: transparent;
        border-color: $_opc-button-secondary-bg;
        color: $_opc-button-secondary-color;
        border-radius: $_opc-button-secondary-border-radius;
        &:hover, &:focus {
            color: $_opc-button-secondary-color-hover;
            border-color: $_opc-button-secondary-bg-hover;
			&:disabled {
				border-color: $_opc-button-secondary-bg;
        		color: $_opc-button-secondary-color;
			}
        }

        &[data-op-type="radio"] {

            label {
                background: transparent;
                border-color: $_opc-button-secondary-bg;
                color: $_opc-button-secondary-bg;
                border-radius: $_opc-button-secondary-border-radius;
                &:hover, &:focus {
                    background: $_opc-button-secondary-bg;
                    color: $_opc-button-secondary-color;
                    border-color: $_opc-button-secondary-bg-hover;
					&:disabled {
						background: transparent;
						border-color: $_opc-button-secondary-bg;
						color: $_opc-button-secondary-bg;
					}
                }
            }
            input:checked + label {
                background: $_opc-button-secondary-bg;
                color: $_opc-button-secondary-color;
            }
        }

        &.is-progress {
            border-color: $_opc-button-secondary-progress-color !important;
            color: $_opc-button-secondary-progress-color !important;
        }
        &.is-success {
            border-color: $_opc-button-secondary-success-color !important;
            color: $_opc-button-secondary-success-color !important;
        }
    }
    &[data-op-style="loud-lined"] {
        background: transparent;
        border-color: $_opc-button-loud-bg;
        color: $_opc-button-loud-bg;
        border-radius: $_opc-button-loud-border-radius;
        &:hover, &:focus {
            color: $_opc-button-loud-bg-hover;
            border-color: $_opc-button-loud-bg-hover;
			&:disabled {
				border-color: $_opc-button-loud-bg;
        		color: $_opc-button-loud-bg;
			}
        }

        &[data-op-type="radio"] {

            label {
                background: transparent;
                border-color: $_opc-button-loud-bg;
                color: $_opc-button-loud-bg;
                border-radius: $_opc-button-loud-border-radius;
                &:hover, &:focus {
                    background: $_opc-button-loud-bg;
                    color: $_opc-button-loud-color;
                    border-color: $_opc-button-loud-bg-hover;
					&:disabled {
						background: transparent;
						color: $_opc-button-loud-color;
                    	border-color: $_opc-button-loud-bg-hover;
					}
                }
            }
            input:checked + label {
                background: $_opc-button-loud-bg;
                color: $_opc-button-loud-color;
            }
        }

        &.is-progress {
            border-color: $_opc-button-loud-progress-color !important;
            color: $_opc-button-loud-progress-color !important;
        }
        &.is-success {
            border-color: $_opc-button-loud-success-color !important;
            color: $_opc-button-loud-success-color !important;
        }
    }

    &[data-op-style="primary-color"] {
        background: transparent;
        border-color: transparent;
        color: $_opc-button-primary-bg;
        border-radius: 0;
        &:hover, &:focus {
            color: $_opc-button-primary-bg-hover;
			&:disabled {
        		color: $_opc-button-primary-bg;
			}
        }

        &.is-progress { color: $_opc-button-primary-progress-color !important; }
        &.is-success { color: $_opc-button-primary-success-color !important; }
    }
    &[data-op-style="secondary-color"] {
        background: transparent;
        border-color: transparent;
        color: $_opc-button-secondary-bg;
        border-radius: 0;
        &:hover, &:focus {
            color: $_opc-button-secondary-bg-hover;
			&:disabled {
				color: $_opc-button-secondary-bg;
			}
        }
        &.is-progress { color: $_opc-button-secondary-progress-color !important; }
        &.is-success { color: $_opc-button-secondary-success-color !important; }
    }
    &[data-op-style="loud-color"] {
        background: transparent;
        border-color: transparent;
        color: $_opc-button-loud-bg;
        border-radius: 0;

        &:hover, &:focus {
            color: $_opc-button-loud-bg-hover;
			&:disabled {
        		color: $_opc-button-loud-bg;
			}
        }
        &.is-progress { color: $_opc-button-loud-progress-color !important; }
        &.is-success { color: $_opc-button-loud-success-color !important; }
    }

    /* --------------------------------------------------------------- */
    // types
    &[data-op-type="link"] {
        background: none;
        padding-left: map-get($padding, 'normal') / 2;
        padding-right: map-get($padding, 'normal') / 2;
        &:hover, &:focus {
            background: none;
        }
        &[data-op-style="primary"],
        &[data-op-style="primary-lined"] {
            color: $_opc-button-primary-bg;
            &:hover, &:focus {
                color: $_opc-button-primary-bg-hover;
				&:disabled {
            		color: $_opc-button-primary-bg;
				}
            }
        }
        &[data-op-style="secondary"],
        &[data-op-style="secondary-lined"] {
            color: $_opc-button-secondary-bg;
            &:hover, &:focus {
                color: $_opc-button-secondary-bg-hover;
				&:disabled {
            		color: $_opc-button-secondary-bg;
				}
            }
        }
        &[data-op-style="loud"],
        &[data-op-style="loud-lined"]{
            color: $_opc-button-loud-bg;
            &:hover, &:focus {
                color: $_opc-button-loud-bg-hover;
				&:disabled {
            		color: $_opc-button-loud-bg;
				}
            }
        }
    }

	&[data-op-type="text"] {
		margin: 0;
		margin-right: 1rem;

        i, svg {
            display:none;
        }
    }

    &[data-op-type="icon"] {
        position: relative;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        overflow: visible;
        text-align: center;
        vertical-align: middle;
        flex-shrink: 0;

        i,
		svg {
            margin: 0 !important;
            font-size: 1.4em;
            vertical-align: middle;
        }

		&:focus {
			outline: none;
		}
        span {
            display: none;
        }
    }

    &[data-op-type="radio"] {
        border-radius: 0;
        background: transparent;
        padding: 0;
        margin: 0;
        border: none;

        label {
            height: 100%;
            line-height: 1;
            border: 1px solid transparent;
            border-radius: 0 !important;

            &::before,
            &::after {
                display: none;
            }
        }
    }

    /* --------------------------------------------------------------- */
    // sizes
    &[data-op-size="tiny"] {
        font-size: $_opc-button-font-size-s;
        height: map-get($heights, 'tiny');
        padding: 0 map-get($padding, 'tiny')*2;
        &[data-op-type="link"] {
            padding-left: map-get($padding, 'tiny') / 2;
            padding-right: map-get($padding, 'tiny') / 2;
        }

        i { margin-right: map-get($padding, 'tiny'); }

        &[data-op-type="icon"] {
            width: map-get($heights, 'tiny');
        }

        &[data-op-type="radio"] {

            height: auto;
            line-height: 1;
            padding: 0;

            label {
                height: map-get($heights, 'tiny');
                line-height: map-get($heights, 'tiny');
                padding: 0 map-get($padding, 'tiny')*2;
            }
        }

    }
    &[data-op-size="small"] {
        font-size: $_opc-button-font-size-m;
        height: map-get($heights, 'small');
        padding: 0 map-get($padding, 'small')*2;
        &[data-op-type="link"] {
            padding-left: map-get($padding, 'small') / 2;
            padding-right: map-get($padding, 'small') / 2;
        }

        i {
            margin-right: map-get($padding, 'small');
        }

        &[data-op-type="icon"] {
            width: map-get($heights, 'small');
        }

        &[data-op-type="radio"] {

            height: auto;
            line-height: 1;
            padding: 0;

            label {
                height: map-get($heights, 'small');
                line-height: map-get($heights, 'small');
                padding: 0 map-get($padding, 'small')*2;
            }
        }
    }
    &[data-op-size="medium"] {
        font-size: $_opc-button-font-size-m;
        height: map-get($heights, 'medium');
        padding: 0 map-get($padding, 'medium')*2;
        &[data-op-type="link"] {
            padding-left: map-get($padding, 'medium') / 2;
            padding-right: map-get($padding, 'medium') / 2;
        }
        i {
            margin-right: map-get($padding, 'medium');
        }

        &[data-op-type="icon"] {
            width: map-get($heights, 'medium');
        }

        &[data-op-type="radio"] {

            height: auto;
            line-height: 1;
            padding: 0;

            label {
                height: map-get($heights, 'medium');
                line-height: map-get($heights, 'medium');
                padding: 0 map-get($padding, 'medium')*2;
            }
        }
    }
    &[data-op-size="normal"] {
        font-size: $_opc-button-font-size-m;
        height: map-get($heights, 'normal');
        padding: 0 map-get($padding, 'normal')*2;
        &[data-op-type="link"] {
            padding-left: map-get($padding, 'normal') / 2;
            padding-right: map-get($padding, 'normal') / 2;
        }
        i {
            margin-right: map-get($padding, 'normal');
        }
        &[data-op-type="icon"] {
            width: map-get($heights, 'normal');
        }

        &[data-op-type="radio"] {

            height: auto;
            line-height: 1;
            padding: 0;

            label {
                height: map-get($heights, 'normal');
                line-height: map-get($heights, 'normal');
                padding: 0 map-get($padding, 'normal')*2;
            }
        }
    }
    &[data-op-size="large"] {
        font-size: $_opc-button-font-size-l;
        height: map-get($heights, 'large');
        padding: 0 map-get($padding, 'large')*2;
        &[data-op-type="link"] {
            padding-left: map-get($padding, 'large') / 2;
            padding-right: map-get($padding, 'large') / 2;
        }
        i {
            margin-right: map-get($padding, 'large');
        }
        &[data-op-type="icon"] {
            width: map-get($heights, 'large');
        }

        &[data-op-type="radio"] {

            height: auto;
            line-height: 1;
            padding: 0;

            label {
                height: map-get($heights, 'large');
                line-height: map-get($heights, 'large');
                padding: 0 map-get($padding, 'large')*2;
            }
        }
    }
    &[data-op-size="huge"] {
        font-size: $_opc-button-font-size-xl;
        height: map-get($heights, 'huge');
        padding: 0 map-get($padding, 'huge')*2;
        &[data-op-type="link"] {
            padding-left: map-get($padding, 'huge')/2;
            padding-right: map-get($padding, 'huge')/2;
        }
        i {
            margin-right: map-get($padding, 'huge');
        }
        &[data-op-type="icon"] {
            width: map-get($heights, 'huge');
        }

        &[data-op-type="radio"] {

            height: auto;
            line-height: 1;
            padding: 0;

            label {
                height: map-get($heights, 'huge');
                line-height: map-get($heights, 'huge');
                padding: 0 map-get($padding, 'huge')*2;
            }
        }
    }
}


.opc-button-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 2rem;

    &[data-op-align="left"] { justify-content: flex-start;}
    &[data-op-align="right"] {
        justify-content: flex-end;
        .opc-button {
            margin-left: 1rem;
        }
    }
    &[data-op-align="center"] { justify-content: center;}
}