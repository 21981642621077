/*###############################################

@Title: Assets
@Description:
This file contains the assets styles

###############################################*/

.opc-asset {
    &[data-op-type="gallery"] {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        max-height: auto;
        justify-content: center;
        >li {
            flex-grow: 1;
            flex-basis: 8rem;
            max-width: 8rem;
            margin: 0 0.5rem;
            padding: 1rem;
            align-content: center;
            display: flex;
            align-items: center;
            &.is-selected {
                border: 1px solid $brand-color-1;
            }
            img {
                height: auto;
                width: 100%;
                max-height: inherit;
                width: 6rem;
                flex-shrink: 0;
            }
        }
    }
}