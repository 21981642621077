/*###############################################

@Title: Category Navigation
@Description:
This file contains the category navigation styles

###############################################*/

.opc-category-navigation {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: -$_opc-grid-gutter-width;
        margin-right: -$_opc-grid-gutter-width;
        
        li {
            padding: 0 $_opc-grid-gutter-width;
            margin-bottom: $_opc-grid-gutter-width*2;
            width: 100%;
            flex-basis: span(6);
            width: span(6);
            max-width: span(6);

            @include mq($min-width: map-get($breakpoints, s)) {
                flex-basis: span(6);
                width: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(4);
                width: span(4);
                max-width: span(4);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: span(3);
                width: span(3);
                max-width: span(3);
            }
        }
        
        .category-item {
            .category-item-image {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1rem;
                height: 18rem;
                background: #eee;
                overflow: hidden;
                flex-shrink: 0;
				img {
                    max-width: 100%;
                    flex-shrink: 0;
                }
            }
            .category-item-title {
                margin-bottom: 1rem;
                
                @extend .h3;
                a {
                    height: 2.4rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    white-space: nowrap;
                    color: #000;
                    &:hover {
                        color: lighten(#000, 20%);
                    }
                }
            }
            .category-item-description {
                @extend .text;
                height: 3.8rem;
                overflow: hidden;
                display: none;

                @include mq($min-width: map-get($breakpoints, s)) {
                    display: block;
                }

                &:empty {
                    display:none;
                }
            }
        }
    }
}