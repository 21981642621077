/*###############################################

@Title: More Button
@Description:
This file contains the more button styles

###############################################*/

.opc-more-button {
    position: relative;
    text-align: center;
    opacity: 0.6;
    &[data-op-type="icon"] {
        border: 1px solid #ccc;
        border-radius: 50%;
    }
    &:hover {
        opacity: 1;
    }
    i {
        margin: 0 !important;
    }
    svg {
        pointer-events: none;
    }
    .more-button-content {
        position: absolute;
        top: 0;
        right: 0;
        text-align: left;
        list-style: none;
        margin: 0;
        padding: 0;
        background: #333;
        opacity: 0;
        color: #fff;
        visibility: hidden;
        font-size: 1.4rem;
        li {
            padding: 0;
            line-height: 2;
            border-bottom: 1px solid #666;
            white-space: nowrap;
            margin-bottom: 0 !important;
            button,
            .opc-button {
                background: transparent !important;
                color: #fff;
                width: auto !important;
                opacity: 0.8;
                padding: 0 2rem;
                svg {
                    display: none;
                }
                span {
                    display: block;
                }
                border: none !important;
                margin-bottom: 0 !important;
            }
            &:hover {
                background: #222 !important;
                
                button {
                    color: #fff;
                    opacity: 1;
                    box-shadow: none !important;
                }
            }
        }
    }
    &[mobile-left] {
        @include mq($max-width: map-get($breakpoints, s)) {
            .more-button-content {
                left: 0;
                right: auto;
            }
        }
    }
    &:focus {
        opacity: 1;
        outline: none;
        .more-button-content {
            opacity: 1;
            visibility: visible;
        }
    }
}