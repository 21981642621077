$opc-cms2-sidebar-width: 400px !default;
$opc-cms2-sidebar-transition: -402px !default;
$opc-cms2-sidebar-shadow: rgba(0,0,0,0.2) !default;

$opc-cms2-sidebar-background: #15181d !default;
$opc-cms2-sidebar-color: #fdfffc !default;
$opc-cms2-sidebar-blue: #2ec4b6 !default;
$opc-cms2-sidebar-red: #e71d36 !default;
$opc-cms2-sidebar-yellow: #ff9f1c !default;
$opc-cms2-sidebar-purple: #392F5A !default;
$opc-cms2-sidebar-orange: #fc8200 !default;

$opc-cms2-button-action-color: $opc-cms2-sidebar-color !default;
$opc-cms2-button-action-bg: $opc-cms2-sidebar-background !default;
$opc-cms2-button-update-color: $opc-cms2-sidebar-color !default;
$opc-cms2-button-update-bg: $opc-cms2-sidebar-orange !default;

$opc-cms2-slider-default-color: #BBB !default;
$opc-cms2-slider-selected-color: $opc-cms2-sidebar-orange !default;

.opc-cms-configuration-sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    width: $opc-cms2-sidebar-width;
    height: 100%;
    border: none;
    box-shadow: $opc-cms2-sidebar-shadow 0 0 16px 0px;
    background: $opc-cms2-sidebar-background;
    color: $opc-cms2-sidebar-color;
    transition: all ease 0.3s;
    z-index: 1899;

    &.is-active {
        &.right {
            right: 0;

            .sidebar-handle {
                transform: rotate(0deg);
            }
        }
        &.left {
            left: 0;

            .sidebar-handle {
                transform: rotate(-180deg);
            }
        }
    }

    &.right {
        right: $opc-cms2-sidebar-transition;
        left: auto;

        .sidebar-handle {
            left: -3rem;

            svg {
                transform: rotate(-180deg);
            }
        }
    }
    &.left {
        left: $opc-cms2-sidebar-transition;
        right: auto;

        .sidebar-handle {
            right: -3rem;
        }
    }

    .sidebar-handle {
        position: absolute;
        width: 3rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $opc-cms2-sidebar-background;
        top: 50%;
        margin-top: -2.5rem;
        cursor: pointer;
    }

    .sidebar-top {
        padding: 1rem;
        background: $opc-cms2-sidebar-orange;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .sidebar-top-inner {
            display: flex;
            align-items: center;
        }

        &.active {
            .opc-button {
                &[data-op-style="add-cms-content"], &[data-op-style="back-to-mainnav"] {
                    display: flex;
                }
            }
        }

        .opc-button {
            &[data-op-style="add-cms-content"], &[data-op-style="back-to-mainnav"] {
                color: $opc-cms2-button-action-color;
                background: $opc-cms2-sidebar-orange;
                position: absolute;
                right: 1rem;
                display: none;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                border-radius: 50%;
                border: 1px solid $opc-cms2-button-action-color;

                &:hover, &:focus {
                    color: $opc-cms2-button-action-color;
                    background: lighten($opc-cms2-sidebar-orange, 2%);
                }

                &.is-progress { background: $_opc-button-primary-progress-color !important; }
                &.is-success { background: $_opc-button-primary-success-color !important; }
            }

            &[data-op-style="back-to-mainnav"] {
                right: auto;
                left: 1rem;
                border: none;
            }
        }
    }
    .sidebar-body {
        flex-grow: 1;
        padding: 1rem;
        overflow: auto;
        overflow-x: hidden;
        position: relative;

        .cms-sections-wrapper {
            > ul {
                > li {
                    &.active {
                        > ul {
                            left: 0;
                            visibility: visible;
                        }
                    }

                    > ul {
                        position: absolute;
                        left: 150%;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        min-height: 1px;
                        z-index: 2;
                        overflow-y: auto;
                        overflow-x: hidden;
                        visibility: hidden;
                        padding: 1rem;
                        background: $opc-cms2-sidebar-background;

                        transition: left 0.5s ease-in-out,
                                    visibility 0.5s ease-in-out;
                    }
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin-bottom: 1rem;
                .cms-box-element {
                    background: lighten($opc-cms2-sidebar-background, 2%);

                    .cms-box-element-infos {
                        display: flex;
                        align-items: stretch;

                        .cms-box-element-sortno {
                            padding-left: 1rem;
                            padding-right: 1rem;
                            background: lighten($opc-cms2-sidebar-background, 5%);
                            color: lighten($opc-cms2-sidebar-background, 50%);
                            display: flex;
                            align-items: center;
                            font-weight: 600;
                            justify-content: center;
                            min-width: 3rem;
                            flex-shrink: 1;
                        }
                        .cms-box-element-body {
                            flex-grow: 1;
                            flex-shrink: 0;
                            padding-left: 1rem;
                            padding-right: 1rem;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .cms-box-element-additional {
                        display: none;
                        font-size: small;
                        padding: 1rem;

                        .additional-info {
                            display: flex;
                            align-items: center;
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                            align-content: center;

                            .additional-info-icon {
                                margin-right: 1rem;
                                display: flex;
                                align-items: center;
                            }

                            &[data-cms-action="locate-content"] {
                                justify-content: center;
                                margin-top: 2rem;
                                transition: background 0.5s ease-in-out;
                                cursor: pointer;

                                &:hover {
                                    background: lighten($opc-cms2-button-action-bg, 5%);
                                }
                            }
                        }
                    }

                    &.show {
                        .cms-box-element-additional {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
    .sidebar-bottom {
        padding: 1rem;
        background: lighten($opc-cms2-sidebar-background, 2%);
        font-size: $_opc-font-size-s;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;

            li {
                &.edit-mode {
                    margin-right: auto;
                }
                &.update {
                    margin-left: auto;
                }
            }
        }

        .cms-edit-toggle {
            display: flex;
            align-items: center;

            > span {
                margin-right: 1rem;
            }
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 54px;
            height: map-get($heights, tiny);
            margin-bottom: 0;

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $opc-cms2-slider-default-color;

            &::before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                transition: .4s;
            }

            &.round {
                border-radius: 34px;

                &:before {
                        border-radius: 50%;
                }
            }
        }

        input:checked + .slider {
              background-color: $opc-cms2-slider-selected-color;
        }

        input:focus + .slider {
              box-shadow: 0 0 1px $opc-cms2-slider-selected-color;
        }

        input:checked + .slider:before {
              transform: translateX(26px);
        }
    }

    .opc-button {
        &[data-op-style="cms-action"] {
            color: $opc-cms2-button-action-color;
            background: $opc-cms2-button-action-bg;

            &:hover, &:focus {
                color: $opc-cms2-button-action-color;
                background: lighten($opc-cms2-button-action-bg, 2%);
            }

            &.is-progress { background: $_opc-button-primary-progress-color !important; }
            &.is-success { background: $_opc-button-primary-success-color !important; }
        }
        &[data-op-style="cms-color"] {
            color: lighten($opc-cms2-button-action-bg, 20%);
            background: lighten($opc-cms2-sidebar-background, 2%);

            &:hover, &:focus {
                color: lighten($opc-cms2-button-action-bg, 20%);
                background: lighten($opc-cms2-button-action-bg, 5%);
            }

            &.is-progress { background: $_opc-button-primary-progress-color !important; }
            &.is-success { background: $_opc-button-primary-success-color !important; }
        }
        &[data-op-style="cms-action-lined"] {
            color: $opc-cms2-button-action-color;
            background: transparent;

            &:hover, &:focus {
                color: $opc-cms2-button-action-color;
                background: transparent;
            }

            &.is-progress {
                color: $_opc-button-primary-progress-color;
                background: transparent !important;
            }
            &.is-success {
                color: $_opc-button-primary-success-color;
                background: transparent !important;
            }
        }

        &[data-op-style="cms-update"] {
            color: $opc-cms2-button-update-color;
            background: $opc-cms2-button-update-bg;

            &:hover, &:focus {
                color: $opc-cms2-button-update-color;
                background: lighten($opc-cms2-button-update-bg, 2%);
            }

            &.is-progress { background: $_opc-button-primary-progress-color !important; }
            &.is-success { background: $_opc-button-primary-success-color !important; }
        }

        &[data-op-style="cms-update-lined"] {
            color: $opc-cms2-button-update-color;
            background: transparent;

            &:hover, &:focus {
                color: $opc-cms2-button-update-color;
                background: transparent;
            }

            &.is-progress {
                color: $_opc-button-primary-progress-color;
                background: transparent !important;
            }
            &.is-success {
                color: $_opc-button-primary-success-color;
                background: transparent !important;
            }
        }
    }
}
.cms-contextmenu {
    color: #FFF;
    background-color: #333;
    font-size: smaller;
    position: absolute;
    min-width: 150px;
    left: -999999999px;
    z-index: 10000;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 1rem 2rem;
            cursor: pointer;
            background-color: #333;


            &.link {
                padding: 0;
                display: flex;

                a {
                    padding: 1rem 2rem;
                    color: #FFF;
                    width: 100%;
                }
            }

            &.separator {
                padding: 0;
                height: 1px;
                background-color: lighten(#333, 5%);
            }

            &:hover {
                background-color: lighten(#333, 5%);
            }

            &.disabled {
                pointer-events: none;
                color: lighten(#333, 20%);
            }
        }
    }
}

.cms-modal {
    .cms-modal-header {
        @extend .h3;
    }

    .modal-form-toggle {
        display: flex;
        margin-bottom: 1rem;
        position: relative;

        button.opc-button {
            flex-grow: 1;
            border-radius: 0 !important;

            &.active {
                border-color: $opc-cms2-sidebar-orange;
                background: $opc-cms2-sidebar-orange;
                color: $opc-cms2-sidebar-color;

                &::after {
                    background-color: $opc-cms2-sidebar-orange;
                }
            }

            &::after {
                content: ' ';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -4px;
                height: 4px;
                background-color: transparent;
            }
        }
    }

    .add-article-list-wrapper {
        label {
            display: none;
        }
        .opc-button {
            &[data-struct-action="add-fromstring"] {
                display: none;
            }
        }
        &.show {
            label {
                display: block;
                font-family: inherit;
                font-weight: 600;
            }
            .opc-button {
                &[data-struct-action="add-fromstring"] {
                    display: inline-flex;
                }
            }
        }
        .add-article-list {
            max-height: 200px;
            overflow: auto;
            margin-bottom: 1rem;

            .add-article-list-item {
                margin-top: -1px;
                border: 1px solid #CCC;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-top: 0;
                }

                .item-description {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                .item-action {
                    margin-left: auto;

                    .opc-button {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

.opc-cms-section {
    position: relative;

    &.edit {
        border: 2px solid $opc-cms2-sidebar-orange;

        .opc-cms-section-name {
            display: block;
        }
    }

    .opc-cms-section-name {
        display: none;
        background-color: $opc-cms2-sidebar-orange;
        color: $opc-cms2-sidebar-color;
        position: absolute;
        font-size: 1rem;
        top: -2.5rem;
        left: -2px;
        padding: 0.5rem 1rem;
    }

    .opc-cms-box {
        &.highlight {
            position: relative;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                opacity: 0.9;
                animation: highlight 3s ease;
                background-color: transparent;
                // animation-name: highlight;
                // animation-duration: 2s;
            }
        }
    }
}

@keyframes highlight {
    0% {
        opacity: 0.9;
        background-color: $opc-cms2-sidebar-orange;
    }
    25% {
        opacity: 0.9;
        background-color: $opc-cms2-sidebar-orange;
    }
    100% {
        opacity: 0;
        background-color: transparent;
    }
}