/*###############################################

@Title: Brand Slider
@Description:
This file contains the brand slider styles

###############################################*/

// local namespace 
$_opc-brand-slider-dot-bg:			#ccc !default;
$_opc-brand-slider-dot-size:		1.2rem !default;

.opc-brand-slider {
	> ul {
		list-style: none;
		margin: 0;
		padding: 0;		
		display: flex;
		flex-wrap: nowrap;
		overflow-x:auto;
		-webkit-overflow-scrolling: touch;

		> li {
			width: 20%;
			flex-shrink: 0;
			margin-right: 2rem;
			
			a {
				img {
					max-width: 100%;
					display: block;
					margin: 0 auto;
				}
			} 
		}
	}
	
}