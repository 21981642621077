/*###############################################

@Title: Compare Button
@Description:
This file contains the compare button styles

###############################################*/

.opc-compare-button {
    @extend .opc-button;
    opacity: 0.4;
    &[data-op-type="icon"] {}
    &:hover {
        opacity: 0.8;
    }
    &.is-active {
        opacity: 1;
    }
    &:focus {
        outline: none;
    }
    svg {
        font-size: 1.4em;
    }
}

.opc-compare-count {
    font-weight: bold;
    &::before {
        content: "(";
    }
    &::after {
        content: ")";
    }
    &:empty {
        display: none;
    }
}
