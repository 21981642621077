.opc-notifications {
    .notifications-dimmer {
        position: fixed;
        z-index: 1981;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
        margin: 0;
        background-color: #252525;
        opacity: .5;
    }

    .notifications-modal {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 0;
        overflow-y: auto;
        z-index: 1981;
    }

    .notifications-dialog {
        position: relative;
        margin: 5% auto;
        min-height: 110px;
        max-width: 500px;
        padding: 24px 24px 0 24px;
        outline: 0;
        background-color: #fff;

        &[data-op-capture="1"]:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            z-index: 1;
        }
    }

    .notifications-reset {
        position: absolute !important;
        display: inline !important;
        width: 0 !important;
        height: 0 !important;
        opacity: 0 !important;
    }

    .notifications-commands {
        position: absolute;
        right: 4px;
        margin: -14px 24px 0 0;
        z-index: 2;

        button {
            display: none;
            width: 10px;
            height: 10px;
            margin-left: 10px;
            padding: 10px;
            border: 0;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;

            &.notifications-close {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAh0lEQVQYlY2QsQ0EIQwEB9cBAR1CJUaI/gigDnwR6NBL/7/xWLNrZ2b8EwGotVpr7eOitWa1VjugiNB7R1UPrKrWe0dEAHBbXUqxMQbeewDmnHjvyTm7C3zDwAUd9c63YQdUVdu6EAJzzquz7HXvTiklt+H9DQFYaxFjvDqllFyMkbXWvfpXHjJrWFgdBq/hAAAAAElFTkSuQmCC);
            }

            &.notifications-maximize {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAOUlEQVQYlWP8//8/AzGAhYGBgaG4uBiv6t7eXkYmooxjYGAgWiELsvHYFMCcRX2rSXcjoSBiJDbAAeD+EGu+8BZcAAAAAElFTkSuQmCC);
            }
        }
        //button
    }
    //commands
    .notifications-header {
        margin: -24px;
        margin-bottom: 0;
        padding: 16px 24px;
        background-color: #fff;
    }

    .notifications-body {
        min-height: 56px;

        .notifications-content {
            padding: 16px 24px 16px 16px;
        }
    }

    .notifications-footer {
        padding: 4px;
        margin-left: -24px;
        margin-right: -24px;
        min-height: 43px;
        background-color: #fff;

        .notifications-buttons {
            &[data-op-type="primary"] {
                text-align: right;

                .opc-button {
                    margin: 4px;
                }
            }

            &[data-op-type="auxiliary"] {
                float: left;
                clear: none;
                text-align: left;

                .opc-button {
                    margin: 4px;
                }
            }

            .opc-button {
                min-width: 88px;
                min-height: 35px;
            }
        }
    }

    .notifications-handle {
        position: absolute;
        display: none;
        width: 10px;
        height: 10px;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMS8xNEDQYmMAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQ0lEQVQYlaXNMQoAIAxD0dT7H657l0KX3iJuUlBUNOsPPCGJm7VDp6ryeMxMuDsAQH7owW3pyn3RS26iKxERMLN3ugOaAkaL3sWVigAAAABJRU5ErkJggg==);
        transform: scaleX(1) /*rtl:scaleX(-1)*/;
        cursor: se-resize;
    }

    // pass overflow control to the content
    &.is-no-overflow {
        .notifications-body {
            .notifications-content {
                overflow: hidden !important;
            }
        }
    }
    // pass padding control to the content
    &[data-op-no-padding="1"] {
        &.is-maximized {
            .notifications-body {
                .notifications-content {
                    left: 0;
                    right: 0;
                    padding: 0;
                }
            }
        }

        &:not(.is-maximized) {
            .notifications-body {
                margin-left: -24px;
                margin-right: -24px;

                .notifications-content {
                    padding: 0;
                }
            }
        }

        &.is-resizable {
            .notifications-body {
                .notifications-content {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
    // has maximize box
    &.is-maximizable {
        .notifications-commands {
            button {
                &.notifications-maximize, &.notifications-restore {
                    display: inline-block;
                }
            }
        }
    }
    // has close box
    &.is-closable {
        .notifications-commands {
            button {
                &.notifications-close {
                    display: inline-block;
                }
            }
        }
    }
    // maximizes the dialog
    &.is-maximized {
        .notifications-dialog {
            width: 100% !important;
            height: 100% !important;
            max-width: none !important;
            margin: 0 auto !important;
            top: 0 !important;
            left: 0 !important;
        }

        &.is-modeless .notifications-modal {
            position: fixed !important;
            min-height: 100% !important;
            max-height: none !important;
            margin: 0 !important;
        }

        .notifications-commands {
            button {
                &.notifications-maximize {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAASklEQVQYlZWQ0QkAMQhDtXRincOZX78KVtrDCwgqJNEoIB3MPLj7lRUROlpyVXGzby6zWuY+kz6tj5sBMTMAyVV3/595RbOh3cAXsww1raeiOcoAAAAASUVORK5CYII=);
                }
            }
        }
    }
    // resizable  dialog
    &.is-resizable, &.is-maximized {

        .notifications-dialog {
            padding: 0;
        }

        .notifications-commands {
            margin: 14px 24px 0 0;
        }

        .notifications-header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 16px 24px;
        }

        .notifications-body {
            min-height: 224px;
            display: inline-block;

            .notifications-content {
                position: absolute;
                top: 50px;
                right: 24px;
                bottom: 50px;
                left: 24px;
                overflow: auto;
            }
        }

        .notifications-footer {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
        }
    }

    &.is-resizable:not(.is-maximized) {
        .notifications-dialog {
            //max-width + none resizable padding.
            min-width: 548px;
        }

        .notifications-handle {
            display: block;
        }
    }
    // makes the dialog movable
    &.is-movable:not(.is-maximized) {

        .notifications-header {
            cursor: move;
        }
    }
    // makes the dilog modeless (non-modal)
    &.is-modeless {

        .notifications-dimmer, .notifications-reset {
            display: none;
        }

        .notifications-modal {
            overflow: visible;
            max-width: none;
            max-height: 0;
        }
        // has pin box
        &.is-pinnable {
            .notifications-commands {
                button {
                    &.notifications-pin {
                        display: inline-block;
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQklEQVQYlcWPMQ4AIAwCqU9u38GbcbHRWN1MvKQDhQFMEpKImGJA0gCgnYw0V0rwxseg5erT4oSkQVI5d9f+e9+xA0NbLpWfitPXAAAAAElFTkSuQmCC);
                    }
                }
            }
        }

        &.is-unpinned {
            .notifications-modal {
                position: absolute;
            }

            .notifications-commands {
                button {
                    &.notifications-pin {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAO0lEQVQYlWP8//8/AzGAiShV6AqLi4txGs+CLoBLMYbC3t5eRmyaWfBZhwwYkX2NTxPRvibKjRhW4wMAhxkYGbLu3pEAAAAASUVORK5CYII=);
                    }
                }
            }
        }

        &:not(.is-unpinned) {
            .notifications-body {
                max-height: 500px;
                overflow: auto;
            }
        }
    }
    //basic
    &[data-op-basic="1"] {
        .notifications-header{
            opacity: 0;
        }
        .notifications-footer {
            visibility:hidden;
        }
    }
    //frameless
    &.is-frameless {
        .notifications-header{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            min-height:60px;
            margin: 0;
            padding:0;
            opacity:0;
            z-index: 1;
        }
        .notifications-footer{
            display:none;
        }
        .notifications-body {
            .notifications-content {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
            }
        }
        &:not(.is-resizable){
            .notifications-dialog{
                padding-top:0;
                .notifications-commands{
                    margin-top:0;
                }
            }
        }
    }
}

//helpers
//hides body overflow
.is-no-overflow {
    overflow: hidden !important;
    outline: none;

    &.is-fixed {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll!important;
    }
}
//disables selection
[data-op-no-selection="1"], [data-op-no-selection="1"] * {
    user-select: none;
}

@media screen and (max-width: 568px) {
    .opc-notifications {
        .notifications-dialog {
            min-width: 150px;
        }

        &:not(.is-maximized) {
            .notifications-modal {
                padding: 0 5%;
            }

            &.notifications-resizable {
                .notifications-dialog {
                    min-width: initial;
                    min-width: auto /*IE fallback*/;
                }
            }
        }
    }
}

//fix FF missing outline
@-moz-document url-prefix() {
  .opc-notifications {
     button:focus{
     outline: 1px dotted #3593D2;
     }
  }
}

// transition
.opc-notifications {
    // setup
    .notifications-dimmer, .notifications-modal {
        transform: translate3d(0, 0, 0);
        transition-property: opacity, visibility;
        transition-timing-function: linear;
        transition-duration: 250ms;
    }

    // &.notifications-hidden {
    //     .notifications-dimmer, .notifications-modal {
    //         visibility: hidden;
    //         opacity: 0;
    //     }
    // }
    &.is-hidden {
        .notifications-dimmer, .notifications-modal {
            visibility: hidden;
            opacity: 0;
        }
    }
    //in
    &.is-progress-in:not(.is-hidden) {
        .notifications-dialog {
            animation-duration: 500ms;
        }
    }
    //out
    &.is-progress-out.is-hidden {
        .notifications-dialog {
            animation-duration: 250ms;
        }
    }
    //helper animation
    .notifications-dialog.notifications-shake {
        animation-name: notifications-shake;
        animation-duration: .1s;
        animation-fill-mode: both;
    }

    @keyframes notifications-shake {
        0%, 100% {
            transform: translate3d(0, 0, 0);
        }

        10%, 30%, 50%, 70%, 90% {
            transform: translate3d(-10px, 0, 0);
        }

        20%, 40%, 60%, 80% {
            transform: translate3d(10px, 0, 0);
        }
    }
    // slide
    &[data-op-slide="1"] {
        //in
        &.is-progress-in:not(.is-hidden) {
            .notifications-dialog {
                animation-name: notifications-slideIn;
                animation-timing-function: cubic-bezier( 0.175, 0.885, 0.320, 1.275 );
            }
        }
        //out
        &.is-progress-out.is-hidden {
            .notifications-dialog {
                animation-name: notifications-slideOut;
                animation-timing-function: cubic-bezier( 0.600, -0.280, 0.735, 0.045 );
            }
        }
    }
    // zoom
    &[data-op-zoom="1"] {
        //in
        &.is-progress-in:not(.is-hidden) {
            .notifications-dialog {
                animation-name: notifications-zoomIn;
            }
        }
        //out
        &.is-progress-out.is-hidden {
            .notifications-dialog {
                animation-name: notifications-zoomOut;
            }
        }
    }
    // fade
    &[data-op-fade="1"] {
        //in
        &.is-progress-in:not(.is-hidden) {
            .notifications-dialog {
                animation-name: notifications-fadeIn;
            }
        }
        //out
        &.is-progress-out.is-hidden {
            .notifications-dialog {
                animation-name: notifications-fadeOut;
            }
        }
    }
    // pulse
    &[data-op-pulse="1"] {
        //in
        &.is-progress-in:not(.is-hidden) {
            .notifications-dialog {
                animation-name: notifications-pulseIn;
            }
        }
        //out
        &.is-progress-out.is-hidden {
            .notifications-dialog {
                animation-name: notifications-pulseOut;
            }
        }
    }
    // flip vertical
    &[data-op-flipx="1"] {
        //in
        &.is-progress-in:not(.is-hidden) {
            .notifications-dialog {
                animation-name: notifications-flipInX;
            }
        }
        //out
        &.is-progress-out.is-hidden {
            .notifications-dialog {
                animation-name: notifications-flipOutX;
            }
        }
    }
    // flip vertical
    &[data-op-flipy="1"] {
        //in
        &.is-progress-in:not(.is-hidden) {
            .notifications-dialog {
                animation-name: notifications-flipInY;
            }
        }
        //out
        &.is-progress-out.is-hidden {
            .notifications-dialog {
                animation-name: notifications-flipOutY;
            }
        }
    }
}


/////////
//pulse//
@keyframes notifications-pulseIn {
    0%, 20%, 40%, 60%, 80%, 100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(.9, .9, .9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(.97, .97, .97);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes notifications-pulseOut {
    20% {
        transform: scale3d(.9, .9, .9);
    }

    50%, 55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }

    100% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }
}
//pulse//
/////////

////////
//zoom//
@keyframes notifications-zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(.25, .25, .25);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes notifications-zoomOut {
    0% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    100% {
        opacity: 0;
        transform: scale3d(.25, .25, .25);
    }
}
//zoom//
////////


////////
//fade//
@keyframes notifications-fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes notifications-fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
//fade//
////////

/////////
//flipx//
@keyframes notifications-flipInX {
    0% {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transition-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transition-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    100% {
        transform: perspective(400px);
    }
}


@keyframes notifications-flipOutX {
    0% {
        transform: perspective(400px);
    }

    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }

    100% {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}
//flipx//
/////////



/////////
//flipy//
@keyframes notifications-flipInY {
    0% {
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transition-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transition-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }

    100% {
        transform: perspective(400px);
    }
}

@keyframes notifications-flipOutY {
    0% {
        transform: perspective(400px);
    }

    30% {
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1;
    }

    100% {
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0;
    }
}
//flipy//
/////////

////////
//slide//
@keyframes notifications-slideIn {
    0% {
        margin-top: -100%;
    }

    100% {
        margin-top: 5%;
    }
}

@keyframes notifications-slideOut {
    0% {
        margin-top: 5%;
    }

    100% {
        margin-top: -100%;
    }
}
//slide//
////////

/***************
 Notifier
***************/

.opc-notifications-notifier {
    position: fixed;
    width: 0;
    overflow: visible;
    z-index: 1982;
    transform: translate3d(0,0,0);

    .notifications-message {
        position: relative;
        width: 260px;
        max-height: 0;
        padding: 0;
        opacity: 0;
        margin: 0;
        transform: translate3d(0,0,0);
        transition-duration: 250ms;
        transition-timing-function: linear;

        &.is-visible {
            transition-duration: 500ms;
            transition-timing-function: cubic-bezier( 0.175, 0.885, 0.320, 1.275 );
            opacity: 1;
            max-height: 100%;
            padding: 15px;
            margin-top: 10px;
        }

        &.is-success {
            background: rgba(91, 189, 114,.95);
        }

        &.is-error {
            background: rgba(217, 92, 92,.95);
        }

        &.is-warning {
            background: rgba(252, 248, 215, 0.95);
        }

        .notifications-close {
            position: absolute;
            top: 0;
            right: 0;
            width:16px;
            height:16px;
            cursor: pointer;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAABGdBTUEAALGPC/xhBQAAAFBJREFUGBl1j0EKADEIA+ve/P9f9bh1hEihNBfjVCO1v7RKVqJK4h8gM5cAPR42AkQEpSXPwMTyoi13n5N9YqJehm3Fnr7nL1D0ZEbD5OubGyC7a9gx+9eNAAAAAElFTkSuQmCC);
            background-repeat: no-repeat;
            background-position: center center;
            background-color:rgba(0, 0, 0, 0.5);
            border-top-right-radius: 2px;
        }
    }

    &[data-op-notifier-top="1"] {
        top: 10px;
    }

    &[data-op-notifier-bottom="1"] {
        bottom: 10px;
    }

    &[data-op-notifier-right="1"] {
        right: 10px;

        .notifications-message {
            right: -320px;

            &.is-visible {
                right: 290px;
            }
        }
    }

    &[data-op-notifier-left="1"] {
        left: 10px;

        .notifications-message {
            left: -300px;

            &.is-visible {
                left: 0;
            }
        }
    }

    &[data-op-notifier-center="1"] {
        left: 50%;

        .notifications-message {
            transform: translateX(-50%);

            &.is-visible {
                left: 50%;
                transition-timing-function: cubic-bezier(0.57, 0.43, 0.1, 0.65);
            }
        }

        &[data-op-notifier-top="1"] {
            .notifications-message {
                top: -300px;

                &.is-visible {
                    top: 0;
                }
            }
        }

        &[data-op-notifier-bottom="1"] {
            .notifications-message {
                bottom: -300px;

                &.is-visible {
                    bottom: 0;
                }
            }
        }
    }
}