/*###############################################

@Title: Table List
@Description:
This file contains the table list

###############################################*/

.opc-tablelist {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .tablelist-head,
    .tablelist-body li {
        display: flex;
        border-bottom: 1px solid #eee;
    }
    .tablelist-head {
        font-weight: 600;
        border-bottom: 1px solid #ccc;
        display: none;
        @include mq($min-width: map-get($breakpoints, s)) {
            display: flex;
        }
    }
    .tablelist-body li {
        flex-wrap: wrap;
        padding: 1rem 0;
        @include mq($min-width: map-get($breakpoints, s)) {
            flex-wrap: nowrap;
            padding: 0;
        }
        &:hover {
            background: #eee;
        }
        &.is-active {
            background: #eee;
        }
    }
    .tablelist-column {
        padding: 0 1rem 0.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-wrap: nowrap;
        width: 100%;
        @include mq($min-width: map-get($breakpoints, s)) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 1rem;
        }
        .tablelist-column-label {
            font-weight: 600;
            margin-right: 0.5rem;
            margin-bottom: 0.2rem;
            display: block;
            width: 40%;
            flex-shrink: 0;
            @include mq($min-width: map-get($breakpoints, s)) {
                display: none;
            }
        }
        .tablelist-column-value {
            width: 60%;
            @include mq($min-width: map-get($breakpoints, s)) {
                width: 100%;
            }
            a {
                color: #000;
                &:hover {
                    color: lighten(#000, 40%);
                }
            }
        }
        &.column-image {
            .tablelist-img {
                display: none;
                margin: 0 auto;
                margin-bottom: 1rem;
                .opc-asset {
                    max-width: 100%;
                }
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                width: 10rem;
                flex-shrink: 0;
                .tablelist-img {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 1rem;
                    img {
                        object-fit: contain;
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        height: auto;
                        flex-shrink: 0;
                    }
                    .opc-asset {
                        width: 8rem;
                    }
                }
            }
            @include mq($min-width: map-get($breakpoints, m)) {}
        }
        &.column-articleno {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 10rem;
                flex-shrink: 0;
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                width: 10rem;
                flex-shrink: 0;
            }
        }
        &.column-details {
            @include mq($min-width: map-get($breakpoints, s)) {
                flex-grow: 1;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-grow: 1;
            }
            dl {
                dt {
                    font-weight: 600;
                    margin-bottom: 0.2rem;
                    opacity: 0.6;
                }
                dd {
                    margin-bottom: 1rem;
                }
            }
        }
        &.column-price {
            @include mq($min-width: map-get($breakpoints, s)) {
                width: 12rem;
                flex-shrink: 0;
                text-align: right;
                justify-content: flex-end;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 12rem;
                flex-shrink: 0;
            }
            .opc-price {
                flex-direction: column;
                .price-basis,
                .price-service,
                .price-promotion {
					align-self: inherit;
					 @include mq($min-width: map-get($breakpoints, s)) {
						align-self: flex-end;
						margin-right: 0;
            		}
                }
            }
        }
        &.column-actions {
            justify-content: space-between;


            .opc-basket-button {
                &[data-op-modal] {
                    span {display:none;}
                    width: 4rem;
                    height: 4rem;
                    padding: 0;
                    justify-content: center;
                    margin-left: 1rem;

                    svg  {
                        margin-right: 0;
                    }
                }
            }


            @include mq($min-width: map-get($breakpoints, s)) {
                width: 20rem;
                flex-shrink: 0;
                flex-wrap: wrap;
                align-content: flex-start;
                justify-content: flex-end;
                .opc-basket-button {
                    margin-right: 0;
                    margin-left: 0;
                    order: 1;
                    width: auto;
                    flex-grow: 0;
                    flex-wrap: nowrap;
                    width: 100%;
                    justify-content: flex-end;
                    &[data-op-type="single-dim"] {
                        .basket-button-dim-1 {
                            margin-bottom: 1rem;
                            margin-bottom: 0;
                        }
                    }
                    .opc-button {
                        flex-grow: 0;
                    }
                    &[data-op-modal] {
                        span {display:none;}
                        width: 4rem;
                        height: 4rem;
                        padding: 0;
                        justify-content: center;
                        margin-left: 1rem;
                    }

                }
                .opc-favorite-button {
                    margin-left: 1rem;
                    margin-bottom: 1rem;
                    &:first-of-type {
                        margin-left: 0;
                    }
                }
                .opc-compare-button {
                    margin-left: 1rem;
                    margin-bottom: 0;
                }
                .opc-more-button {
                    order: 4;
                    margin-bottom: 0;
                    margin-left: 1rem;
                }
            }
        }
        .opc-price {
            .price-basis,
            .price-service,
            .price-promotion {
                font-size: inherit;
            }
        }
    }
    .tablelist-actions {
        justify-content: flex-end;
        flex-wrap: wrap;
        >.opc-favorite-button,
        >.opc-compare-button,
        >.opc-basket-button .opc-button,
        >.opc-basket-button .basket-button-quantity,
        >.opc-more-button {
            margin-left: 0.5rem;
            height: map-get($heights, 'small') !important;
            width: map-get($heights, 'small') !important;
        }
        .opc-basket-button {
            width: 100%;
            justify-content: flex-end;
        }
    }
}