.home-banner {
    margin: 2rem 0 8rem;

    @include mq($min-width: map-get($breakpoints, m)) {
        margin-bottom: 4rem 0 12rem;
    }

    &__image {
        width: 100%;
        height: auto;
        margin-bottom: 1em;
    }

    &__content, &__short {
        margin-bottom: 1em;
        font-size: 1.8rem;
    }
}