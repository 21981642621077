.opc-breadcrumb {
    display: flex;
    font-size: 1.6rem;
    font-weight: 400;
    color: $brand-color-1;
    margin-top: 2rem;
    margin-bottom: 0;
    // padding: 10px 20px 0;
    // @include mq($min-width: map-get($breakpoints, m)) {
    //     padding: 0;
    // }
    @include mq($min-width: map-get($breakpoints, l)) {
        margin-top: 0;
    }

    .breadcrumb-pretext{
        display: none;
    }

    .breadcrumb-navigation li{
        word-break: break-all;
        &::after{
            display: none;
        }
        &::before {
            content: '/';
            width: auto;
            height: auto;
            margin-left: .5rem;
            margin-right: 1rem;
            display: inline-block;
            background-image: none;
            vertical-align: top;
        }
        &:first-child,
        &.is-active{
            &::before{
                display: none;
            }
        }
        &.is-active{
            display: none;
        }
    }
} 