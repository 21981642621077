.list-filter {
	display: flex;
	background: #eee;
	padding: 1rem;
	margin-bottom: 2rem;

	.opc-form-field {
		margin-right: 1rem;
		margin-bottom: 0;
	}
}