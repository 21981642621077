/*###############################################

@Title: Newsletter
@Description:
This file contains the newsletter styles

###############################################*/

.opc-newsletter {
    .opc-message {
        text-align: center;
        svg {
            margin: 0 auto;
            display: block;
            margin-bottom: 1rem;
            font-size: 3rem;
        }
    }
}