.opc-button {
    &[data-op-style="loud"],
    &[data-op-style="basket"] {
        background: $_opc-button-loud-bg;
        background-image: linear-gradient(to bottom right, $dark-blue, $blue);
        color: $_opc-button-loud-color;
        border-radius: $_opc-button-loud-border-radius;
        border: none;
        &:hover, &:focus {
            color: $_opc-button-loud-color-hover;
            background: $_opc-button-loud-bg-hover;
            background-image: linear-gradient(to bottom right, lighten($dark-blue, 2%), lighten($blue, 2%));
        }

        &.is-progress { background: $_opc-button-loud-progress-color !important; }
        &.is-success { background: $_opc-button-loud-success-color !important; }
    }

    &[data-op-style="loud-lined"] {
        background: transparent;
        border-color: $_opc-button-loud-bg;
        color: $_opc-button-loud-bg;
        border-radius: $_opc-button-loud-border-radius;
        &:hover, &:focus {
            color: $_opc-button-loud-bg-hover;
            border-color: $_opc-button-loud-bg-hover;
        }

        &[data-op-type="radio"] {

            label {
                background: transparent;
                border-color: $_opc-button-loud-bg;
                color: $_opc-button-loud-bg;
                border-radius: $_opc-button-loud-border-radius;
                &:hover, &:focus {
                    background: $_opc-button-loud-bg;
                    color: $_opc-button-loud-color;
                    border-color: $_opc-button-loud-bg-hover;
                }
            }
            input:checked + label {
                background: $_opc-button-loud-bg;
                color: $_opc-button-loud-color;
            }
        }

        &.is-progress { 
            border-color: $_opc-button-loud-progress-color !important; 
            color: $_opc-button-loud-progress-color !important; 
        }
        &.is-success { 
            border-color: $_opc-button-loud-success-color !important; 
            color: $_opc-button-loud-success-color !important; 
        }
    }

    &.shadow {
        box-shadow: 0px 10px 15px rgba(0,0,0,0.2);

        &:hover {
            box-shadow: 0px 10px 15px rgba(0,0,0,0.5);
        }
    }
    &[data-op-style="loud"],
    &[data-op-style="loud-lined"]{
        padding: 2.4rem 3rem;
    }
}