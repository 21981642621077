/*###############################################

@Title: Offcanvas Nav
@Description:


###############################################*/


/*
 Types:
 - full: for a fullscreen menu
 - side: for a side menu that moves the content to the right 
*/
$offcanvas-type: 'side'; // side or full
$offcanvas-width: 100%; // width of side menu
$offcanvas-color: $text-color;

.offcanvas-menu-wrapper {

	position: fixed;
	top: 88px;
	left: 0;
	bottom: 0;
	background: $light-grey;
	overflow-x: hidden;
	overflow-y: auto;
	transition: opacity 0.2s ease-in-out;
	
	@include mq($min-width: map-get($breakpoints, m)) {
		top: 15rem;
	}

	@if $offcanvas-type=='full' {

		@extend %mw;
		right: 0;
		// hide when not active
		visibility: hidden;
		z-index: -10;
		opacity: 0;

		// show when offcanvas-menu-open class is set to body
		.offcanvas-menu-open & {
			visibility: visible;
			z-index: 1000;
			opacity: 1;
		}


	}

	@else if $offcanvas-type=='side' {

		width: $offcanvas-width;
		// left: -$offcanvas-width;
		// z-index: -1;
		opacity: 0;
		pointer-events: none;
		
		.offcanvas-menu-open & {
			// transform: translateX(100%);
			pointer-events: all;
			z-index: 1000;
			opacity: 1;
		}
	}

	.offcanvas-menu-inner {
		@extend %mw-inner;
		.opc-mini-label {
			padding: 1rem 2rem 0;
			margin-bottom: 0.5rem;
			color: $text-color;
			font-weight: 600;
			margin-top: 2rem;
			font-size: 2.4rem;
			text-transform: none;
			font-weight: normal;
			color: black;
			opacity: 1;
			margin-top: 5rem;

			&:first-child {
				// margin-top: 0;
			}
		}

		display: flex;
		height: 100%;
		flex-direction: row;
	}

	.offcanvas-menu-head {
		//background: red;

		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 2rem 2rem 3rem;
		flex-shrink: 0;

		.offcanvas-brand {
			width: 100%;

			a {
				display: block;
			}

			img {
				width: 100%;
				max-width: 18rem;
			}
		}

		.offcanvas-menu-close {
			background: transparent;
			color: #fff;
			padding: 0;

			svg {
				margin: 0;
				font-size: 3.6rem;
			}
		}


	}

	.offcanvas-menu-body,
	.offcanvas-menu-body-level2 {
		padding-top: 5rem;
		width: 100%;
		flex-shrink: 0;
		transition: all 0.2s ease-in-out;
	}
	.offcanvas-menu-body{
		&.is-inactive{
			transform: translateX(-100%);
		}
	}
	.offcanvas-menu-body-level2{
		&.is-active{
			transform: translateX(-100%);
		}
	}


	.offcanvas-group {

		margin-bottom: 2rem;
		color: #fff;

		.offcanvas-favorites,
		.offcanvas-compare {

			//border-bottom: 1px solid rgba($offcanvas-color,0.1);

			a {
				display: block;
				color: rgba($offcanvas-color, 0.8);
				padding: 2rem;

				&:hover {
					color: rgba($offcanvas-color, 1);
				}
			}

			.opc-compare-count,
			.favorite-count {
				display: block;
				background: $brand-color-1;

				&::before,
				&::after {
					display: none;
				}

				position: absolute;
				right: 2rem;
				top: 50%;
				transform: translateY(-50%);
				width: 2.4rem;
				height: 2.4rem;
				font-size: 1.2rem;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				&:empty {
					display: none;
				}
			}

		}

		.opc-button.logout{
			display: inline-flex;
			color: $white;
			margin-left: 2rem;
		}
		&.admin-mobile-panel{
			margin-top: 4rem;
		}

		.offcanvas-lang {
			padding: .75rem 2rem;
			display: inline-block;
			margin-top: 2.5rem;
			
			.opc-language-selection {
				display: flex;
				align-items: center;
				font-size: 1.6rem;
				&.is-open{
					background-color: transparent;
					ul{
						background-color: transparent;
						li{
							background-color: transparent;
							>span{
								background-color: transparent;
							}
							a{
								background-color: transparent;
							}
						}
					}
				}
				.language-selection-label {
					padding: .75rem .5rem .75rem 0;
					color: $text-color;

					.current-selection {
						display: none;
					}
				}

				ul {
					position: static;
					display: flex;
					background-color: transparent;
					color: $text-color;
					li {
						border: none !important;
						>span {
							padding: 0.5rem 0;
							margin-right: 1rem;
							color: $text-color;
						}
						a {
							padding: 0.5rem 0;
							margin-right: 1rem;
							color: $text-color;
						}
						&.is-active {
							>span {
								opacity: 1;
								color: $text-color;
								font-weight: 700;
							}
							a {
								opacity: 1;
								color: $text-color;
								font-weight: 700;
							}
						}
					}

				}
			}
		}
		&.submenu {
			.navigation-level-2 {
				display: block !important;
				li {
					display: none;
					&[data-visibility="top"] {
						display:block;
						>  {
							font-weight: 300;
						}
					}
					&[data-visibility="both"] { 
						display: block;
						>  {
							font-weight: 300;
						}
					}
				}
			}
		}	
	}


	.offcanvas-body {
	}

	.offcanvas-item {

		a {
			display: block;
			color: rgba($offcanvas-color, 0.8);
			padding: .75rem 2rem;
			font-size: 2rem;

			&:hover {
				color: rgba($offcanvas-color, 1);
			}
		}
		.opc-button.logout{
			margin-top: 2rem;
		}
	}

	.opc-navigation {
		color: #fff;

		a {
			color: rgba($offcanvas-color, 0.8);

			&:hover {
				color: rgba($offcanvas-color, 1);
				background: rgba(#000, 0.1);
			}

			&.is-active {
				background: $brand-color-1;
			}
		}
		.beratung{
			a{
				color: $brand-color-1;
			}
		}





		&[data-op-navtype="aside-click"] {

			.has-children {

				position: relative;

				.navigation-toggler {
					z-index: 2;
					top: 1.5rem;
					right: 2rem;
					width: 3rem;
					height: 3rem;
					position: absolute;
					display: block;
					background: transparent;
					border-radius: 0.5rem;
					cursor: pointer;
					transition: all 0.2s ease-in-out;

					&::before {
						content: '';
						background: transparent;
						position: absolute;
						right: -2rem;
						top: -1.5rem;
						bottom: -1.5rem;
						left: -1.5rem;
					}

					&::after {
						content: '';
						position: absolute;
						right: 0;
						top: 0;
						width: 3rem;
						height: 3rem;
						display: block;
						background-image: inline-svg($op-icon-angle-down-svg-thin, '#000');
						background-position: center;
						background-size: 100%;
						background-repeat: no-repeat;
					}
				}
				
				&.is-open {
					
					>.navigation-toggler {
						transform: rotate(180deg);
						&::after{
							background-image: inline-svg($op-icon-angle-down-svg-thin, $brand-color-1);
						}
					}

				}

				&.is-active {
					>.navigation-toggler {
						//pointer-events: none;
					}
				}

			}


			.navigation-level-1 {
				>li {
					margin-bottom: 0;
					>a {
						padding: 1rem 2rem;
						font-weight: 300;
						font-size: 2.4rem;
					}
					&.is-open {
						background: transparent;
						>a {
							font-weight: 300;
							color: $brand-color-1;
						}
						>.navigation-level-2 {
							display: block;
							margin-bottom: 5rem;
						}
					}
					&.is-active {
						>a {
							color: $brand-color-1;
						}
					}
				}
			}

			.navigation-level-2 {
				display: none;
				>li {
					border-bottom: none;
					>a {
						padding: .75rem 2rem;
						font-size: 2rem;
						font-weight: 400;
					}
					.navigation-toggler{
						top: .75rem;
					}
					&.is-open {
						>a {
							color: $offcanvas-color;
						}
						>.navigation-level-3 {
							display: block;
							margin-bottom: 5rem;
						}
					}
					&.is-active {
						>a {
							color: $brand-color-1;
						}
					}
				}
			}

			.navigation-level-3 {
				display: none;

				>li {
					>a {
						padding: .75rem 2rem;
						font-size: 1.8rem;
						font-weight: 400;
					}

					&.is-open {
						>a {
							color: $brand-color-1;
						}

						>.navigation-level-4 {
							display: block;
						}
					}

					&.is-active {
						>a {
							color: $brand-color-1;
							font-weight: 300;
						}
					}
				}
			}

			.navigation-level-4 {
				display: none;

				>li {
					>a {
						padding: 2rem;
					}

					&.is-open {
						>a {
							font-weight: bold;
							color: $offcanvas-color;
							padding: 2rem;
						}

						>.navigation-level-5 {
							display: block;
						}
					}

					&.is-active {
						>a {
							color: $brand-color-1;
						}
					}
				}
			}
		}
	}

	.opc-button.beratung{
		margin: 1rem 2rem;
	}
}



.viewport {
	background-color: $white;

	@if $offcanvas-type=='side' {
		//transform: translateX(0);
		transition: all 0.2s ease-in-out;

		.offcanvas-menu-open & {
			// transform: translateX($offcanvas-width);
		}
	}

}

.offcanvas-menu-body-level2{
	.back-link{
		font-size: 2.4rem;
		padding: 1rem 2rem 2rem 5rem;
		margin-bottom: 2rem;
		display: block;
		&::after{
			content: "";
			position: absolute;
			left: 1.2rem;
			top: 1rem;
			width: 3rem;
			height: 3rem;
			display: block;
			background-image: inline-svg($op-icon-angle-right-svg-thin, '#000');
			background-position: center;
			background-size: 100%;
			background-repeat: no-repeat;
			transform: rotate(180deg);
		}
	}

	.submenu{
		display: none;
		&.is-open{
			display: block;
		}
	}
} 
.offcanvas-menu-body{
	.offcanvas-group{
		&.unternehmen{
			margin-top: 4rem;
		}
		[data-submenu-class]{
			.opc-navigation{
				.navigation-level-1{
					li{
						&:first-child{
							position: relative;
							a{
								&::after{
									content: "";
									position: absolute;
									right: 2rem;
									top: 1rem;
									width: 3rem;
									height: 3rem;
									display: block;
									background-image: inline-svg($op-icon-angle-right-svg-thin, '#000');
									background-position: center;
									background-size: 100%;
									background-repeat: no-repeat;
								}
							}
							.navigation-toggler{
								display: none;
							}
						}
					}
				}
			}
		}
	}
}