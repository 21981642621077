.content-snippet {
    display: flex;
    border-radius: 4px;

    &.card {
        background: $dark-blue;
        background-image: linear-gradient(to bottom right, $dark-blue, $blue);

        .content-snippet-image {
            display: none;
            width: 100%;
            overflow: hidden;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            @include mq($min-width: map-get($breakpoints, m)) {
                display: block;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .content-snippet-text {
            width: 100%;
            flex-basis: 100%;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mq($min-width: map-get($breakpoints, m)) {
                width: 47%;
                flex-basis: 47%;
                padding: 4rem;
            }

            .snippet-text-information {
                margin-bottom: 2rem;

                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-bottom: 4rem;
                }

                p {
                    font-size: 1.8rem;
                    color: $white;

                    @include mq($min-width: map-get($breakpoints, m)) {
                        font-size: 2.8rem;
                    }
                }
            }

            .snippet-text-button {
                .opc-button {
                    font-size: 1.8rem;
                    height: 5rem;
                    font-weight: 400;

                    @include mq($min-width: map-get($breakpoints, m)) {
                        font-size: 2rem;
                        height: 6rem;
                    }
                }

            }
        }
    }

    &.tile {
        &.category {
            width: 100%;
            flex-basis: 100%;
            height: 325px;
            padding: 2rem;

            @include mq($min-width: map-get($breakpoints, m)) {
                width: span(4);
                flex-basis: span(4);
            }

            .category-wrapper {
                flex-grow: 1;
                background-color: $dark-blue;
                position: relative;

                .content-snippet-image {
                    height: 100%;

                    .category-item-image {
                        height: 100%;
                        width: 100%;
                        display: flex;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .content-snippet-text {
                    position: absolute;
                    left: 50%;
                    bottom: 2rem;

                    .snippet-text-button {
                        position: relative;
                        left: -50%;
                        opacity: 0.9;
                    }
                }
            }
        }
    }
}

.content-snippets {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
}