/*###############################################

@Title: Page Details
@Description:


###############################################*/

.page-details {
    .article-head {
        //margin-top: 4rem;
        display: flex;
        position: relative;
        align-items: flex-start;
        flex-wrap: wrap;
        @include mq($max-width: map-get($breakpoints, 'medium')) {
            // max !
            flex-wrap: wrap;
        }
        @include mq($min-width: map-get($breakpoints, 'medium')) {
            // min !
            margin-left: -2rem;
            margin-right: -2rem;
        }
        .article-flags {
            padding: 0 $_opc-grid-gutter-width;
            position: absolute;
            right: 0;
        }
        .opc-price{
            margin-bottom: 2rem;
            margin-top: 1rem;
            font-size: 2rem!important;
            font-weight: 500!important;
            .price-basis,
            .price-service,
            .price-promotion {
                font-size: 2rem!important;
                font-weight: 500!important;
            }
            .price-info{
                margin-bottom: 3px;
            }
            &.show-basis{
                .price-basis{
                    font-weight: 400!important;
                    &::after{
                            top: 56%;
                    }
                }
            }
        }
        .article-image {
            width: auto; //background: green;
            padding: $_opc-grid-gutter-width;
            flex-shrink: 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-direction: row;
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: calc(100% / 1.5);
                flex-direction: row;
            }
            width: 100%;
            max-width: 100%;
            order: 2;
            @include mq($min-width: map-get($breakpoints, xxxl)) {
                // margin-right: -10rem;
            }
            .opc-asset {
                order: 2;
                margin-bottom: 2rem;
                flex-shrink: 0;
            }
            .opc-asset[data-op-type="gallery"] {
                max-height: auto;
                order: 3;
                @include mq($min-width: map-get($breakpoints, m)) {
                    order: 1;
                    width: 10rem;
                    flex-direction: column;
                }
                @include mq($min-width: map-get($breakpoints, xl)) {
                    width: 20rem;
                }
                li{
                    flex-basis: auto;
                    max-width: 16rem;
                    padding: 1rem;
                    border-color: #EEEEEE;
                    margin-bottom: 1.4rem;
                    background-color: #EEEEEE;
                    // margin-right: 4rem;
                    @include mq($min-width: map-get($breakpoints, xl)) {
                        margin-right: 4rem;
                    }
                    &.is-selected{
                        border-color: $brand-color-1;
                    }
                    img {
                        max-height: none;
                        max-width: 100%;
                        width: auto;
                        background-blend-mode: multiply;
                    }
                }
            }
            img {
                max-width: 100%;
                max-height: inherit;
                height: inherit;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                max-height: 100%;
                flex-grow: 1;
                img {
                    max-width: calc(100% - 10rem);
                    max-height: inherit;
                    max-height: 67.5rem;
                    height: inherit;
                }
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                img {
                    max-width: calc(100% - 20rem);
                }
            }
        }
        .article-infos {
            width: auto; //background: red;
            padding: 20px 0;
            order: 1;
            flex-basis: 100%;
            h1{
                font-size: 2.4rem;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                padding: $_opc-grid-gutter-width;
                h1{
                    font-size: 3.2rem;
                }
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: calc(100% / 3);
                h1{
                    font-size: 4rem;
                }
            }
            flex-grow: 1;
            .opc-stock {
                margin-bottom: 2rem;
            }
            .article-art-nr {
                margin-bottom: 1.5rem;
            }
            .article-desc {
                color: $text-color;
                margin-bottom: 0;
                font-size: 1.8rem;
                font-weight: 300;
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        margin: 1rem 0 1rem 2rem;
                    }
                    >br{
                        display: none;
                    }
                    +ul{
                        margin-top: 0;
                    }
                }
            }
            .article-actions {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                max-width: 52rem;
                @include mq($min-width: map-get($breakpoints, s)) {
                    //flex-wrap: nowrap;
                }
                @include mq($min-width: map-get($breakpoints, m)) {
                    //flex-wrap: wrap;
                }
                .opc-basket-button,
                .opc-favorite-button,
                .opc-compare-button {
                    margin-bottom: 1rem;
                    margin-right: 0;
                    flex-grow: 1;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        flex-grow: 0;
                        margin-right: 1rem;
                    }
                }
                .opc-basket-button {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 0;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        // width: auto;
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                        //width: auto;
                    }
                    .opc-button {
                        //margin-left: 1rem;
                        //flex-grow: 1;
                        //width: 100%;
                        flex-grow: 1;
                        width: 100%;
                        @include mq($min-width: map-get($breakpoints, s)) {
                            width: auto;
                        }
                        @include mq($min-width: map-get($breakpoints, s)) {
                            //flex-grow: 0;
                        }
                        svg{
                            display: none;
                        }
                    }
                    .basket-button-dim-1 {
                        margin: 0 auto;
                        margin-bottom: 1rem;
                        // width: 120px;
                        flex-grow: 1;
                        @include mq($min-width: map-get($breakpoints, s)) {
                            margin: 0;
                        }
                        label{
                            margin-right: 10px;
                        }
                        input{
                            width: 80px;
                            margin: 10px 0;
                        }
                    }
                    .opc-button,
                    &.opc-button {
                        width: 100%;
                        margin-bottom: 0;
                        margin-right: 0;
                        flex-grow: 1;
                        height: auto;
                        flex-basis: 50%;
                        height: 5rem;
                        @include mq($min-width: map-get($breakpoints, s)) {
                            flex-grow: 1;
                            width: auto;
                            margin-right: 0;
                        }
                        @include mq($min-width: map-get($breakpoints, s)) {
                            //flex-grow: 0;
                        }
                        svg{
                            display: none;
                        }
                    }
                }
                @include mq($min-width: map-get($breakpoints, m)) {}
            }
            .retailer-button-wrapper{
                display: flex;
                // margin-top: 1rem;
                .retailer{
                    flex-grow: 1;
                    width: 100%;
                    max-width: 52rem;
                    height: 5rem;
                }
            }
            .compare-button-wrapper{
                display: flex;
                // margin-top: 1rem;
                .opc-button{
                    flex-grow: 1;
                    width: 100%;
                    max-width: 52rem;
                    height: 5rem;
                    border-color: $dark-grey;
                    font-size: 16px;
                    &:hover{
                        border-color: $grey;
                        color: $brand-color-1;
                    }
                    svg{
                        // display: none;
                    }
                }
            }
            h1{
                .brand-name,
                .article-name{
                    word-break: break-word;
                }
            }
        }
    }
    .section-details-light {
        background: transparent;
        max-width: 100%;
        overflow: hidden;
        .opc-tabs {
            font-size: 1.6rem;
            &[data-op-type="accordion"] {
                margin-top: 2rem;
            }
            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;
                    background: transparent;
                    &::before,
                    &::after {
                        border-bottom: none;
                        background: transparent;
                    }
                    li {
                        border: none;
                        color: #000;
                        background-color: transparent!important;
                        margin-right: 4rem;
                        font-size: 2rem;
                        @include mq($min-width: map-get($breakpoints, l)) {
                            font-size: 2.4rem;
                        }
                        &.is-active {
                            color: #000;
                            background: #eee;
                            border-bottom: none;
                            &::after {
                                display: none;
                            }
                            a{
                                border-bottom-color: $brand-color-1;
                            }
                        }
                        a {
                            font-size: inherit;
                            color: $text-color;
                            padding: 3.5rem 0;
                            border-bottom: 5px solid transparent;
                            font-weight: 300;
                            &:hover{
                                color: $brand-color-1;
                            }
                            &::before{
                                display: none;
                            }
                        }
                    }
                }
                .navigation-level-1{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    li{
                        font-size: 2rem;
                        @include mq($min-width: map-get($breakpoints, l)) {
                            font-size: 2.4rem;
                        }
                        a{
                            color: $text-color;
                            padding: 3.5rem 0;
                            margin-right: 4rem;
                            border-bottom: 5px solid transparent;
                            &:hover{
                                color: $brand-color-1;
                            }
                            &.is-open{
                                border-bottom-color: $brand-color-1;
                            }
                            &::before{
                                display: none;
                            }
                        }
                        &:last-of-type{
                            a{
                                margin-right: 0;
                            }
                        }
                    }
                }
                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }
    .section-details-default {
        background: #fff;
        max-width: 100%;
        overflow: hidden;
        .opc-tabs {
            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;
                    background: #eee;
                    &::before,
                    &::after {
                        border-bottom: none;
                        background: inherit;
                        background: #eee;
                    }
                    li {
                        border: none;
                        color: #000;
                        &.is-active {
                            color: #000;
                            background: #fff;
                            border-bottom: none;
                            &::after {
                                display: none;
                            }
                        }
                        a {
                            font-size: 1.6rem;
                        }
                    }
                }
                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }

    ul.artSpec{
        margin: 0;
        padding: 0;
        li.artSpecGroup{
            list-style-type: none;
            >label{
                display: none;
            }
            ul{
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                font-size: 1.8rem;
                li.artSpecAttr{
                    list-style-type: none;
                    flex-basis: 100%;
                    margin-bottom: 1rem;
                    @include mq($min-width: map-get($breakpoints, l)) {
                        flex-basis: calc(100% / 3);
                        padding-right: 4rem;
                        &:nth-child(3n){
                            padding-right: 0;
                        }
                    }
                    display: flex;
                    flex-direction: column;
                    label{
                        font-weight: 700;
                        padding: 1rem 0 0;
                    }
                    span{
                        padding: 1rem 0 2rem;
                        border-bottom: 1px solid $grey;
                    }
                }
            }
        }

    }
    .opc-compare-button{
        opacity: 1;
        color: $text-color;
        padding-left: 0;
        padding-right: 0;
        margin-top: 10px;
        font-size: 1.4rem;
    }
}

.spec-wrapper {
    padding-top: 2rem;
    [data-op-type="accordion"] & {
        padding-top: 0;
    }
    .opc-art-spec {
		display: flex;
		margin-left: -$_opc-grid-gutter-width;
		margin-right: -$_opc-grid-gutter-width;
		width: calc(100% + ($_opc-grid-gutter-width * 2));
        flex-wrap: wrap;
        
        .art-spec-group {
			padding: $_opc-grid-gutter-width;
			width: 100%;
			

			@include mq($min-width: map-get($breakpoints, s)) {
				width: 50%;
			}
			@include mq($min-width: map-get($breakpoints, m)) {
				width: 33.3%;
			}
        }
    }
}

.opc-notifications{
    &[data-op-style="retailer"]{
        .notifications-dialog{
            width: 100%;
            max-width: 80rem;
        }
    }
}

.retailer-modal-content{
    h3{}
    .retailer-logos{
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 5rem 0;
            padding: 0;
            align-items: stretch;
            li{
                a{
                    display: block;
                    height: 100%;
                    color: $text-color;
                    &:hover{
                        color: $brand-color-1;
                    }
                }
                list-style-type: none;
                margin: 0;
                padding: 0;
                flex-basis: 50%;
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: calc(100% / 3);
                }
                .retailer-logo{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                    .image{
                        flex-grow: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    img{
                        max-width: 24rem;
                    }
                    .text{
                        text-align: center;
                    }
                }
            }
        }
    }
    p{
        text-align: center;
    }
}
.dmas-documents-title{
    margin: 4rem 0 1rem;
    &:first-of-type{
        margin-top: 2rem;
    }
}
ul.dmas-documents{
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.8rem;
    align-items: stretch;
    >li{
        list-style-type: none;
        flex-basis: 100%;
        margin-bottom: 1rem;
        word-break: break-all;
        position: relative;
        // border-bottom: 1px solid $grey;
        &::after{
            content: "";
            width: calc(100% - 4rem);
            height: 1px;
            background-color: $grey;
            display: block;
            position: absolute;
            bottom: 1px;
        }
        &:nth-child(2n){
            &::after{
                width: 100%;
            }
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            flex-basis: calc(100% / 2);
            padding-right: 4rem;
            &:nth-child(2n){
                padding-right: 0;
            }
        }
        display: flex;
        flex-direction: column;
        label{
            font-weight: 700;
            padding: 1rem 0 0;
        }
        >span{
            padding: .5rem 0 1rem;
            // &:last-of-type{
            // }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}