/*###############################################

@Title: Content
@Description:


###############################################*/

// namespace vars
$_header-bg: #fff; // header background color
$_header-offcanvas-menu-trigger-color: $brand-color-1; // color of the hamburger menu icon
$_header-padding: 2rem; // top and bottom padding of the header
$_header-offcanvas-menu-margin: 2rem; // space between hamburger menu and brand
$_header-spacing: 4rem; // Spacing between the header sections eg. brand, search, actions


// display config
$always-show-offcanvas-menu-trigger: 'false'; // define if you want to show the hamburger menu just on mobile or always    
$show-offcanvas-menu-trigger: map-get($breakpoints, m); // set breakpoint to define when the hamburger will shown



// header layout styles
.header-wrapper {
	@extend %mw-custom;
	background: $_header-bg;
	box-shadow: 0 0 3px #ccc;
	position: sticky;
	top: 0;
	z-index: 200;

	padding-top: 2rem;
	padding-bottom: 1.5rem;
	height: 9rem;
	@include mq($min-width: map-get($breakpoints, m)) {
		box-shadow: none;
		position: relative;
		padding-top: 0;
		padding-bottom: 0;
		height: auto;
	}

	@include mq($min-width: map-get($breakpoints, xl)) {}

	.header-inner {
		@extend %mw-inner;
		//@include clearfix();
		display: flex;
		align-items: center;
		flex-wrap: nowrap;

		height: auto;
	}


	// offcanvas menu

	.offcanvas-menu-trigger {
		margin-left: $_header-offcanvas-menu-margin;
		margin-right: 0;
		border: none;
		padding: 0.5rem;
		background: none;
		cursor: pointer;
		order: 5;

		&:hover {
			opacity: 1;
		}

		span {
			@include burger(25px, 2px, 5px, $_header-offcanvas-menu-trigger-color);
		}

		&.is-active {
			span {
				@include burger-to-cross;
			}
		}


		display: block;
		@include mq($min-width: map-get($breakpoints, m)) {
			display: none;
		}


	}


	// logo 
	.brand {
		margin-right: $_header-spacing / 4;
		margin-left: 0;
		order: 2;
		padding: 0 0 0.5rem;
		width: 8rem;
		a {
			display: block;
		}
		
		@include mq($min-width: map-get($breakpoints, m)) {
			margin-left: 0;
			a {
				margin-top: -6rem;
			}
			width: 13rem;
			max-width: 13rem;
		}

		img {
			display: block;
			width: 100%;
		}

		@include mq($min-width: map-get($breakpoints, s)) {
			margin-right: $_header-spacing / 2;
		}

		@include mq($min-width: map-get($breakpoints, m)) {
			margin-right: $_header-spacing / 1.5;
			flex-grow: 0;
		}

		@include mq($min-width: map-get($breakpoints, xl)) {
			margin-right: $_header-spacing;
			margin-right: $_header-spacing * 2;
		}



	}


	// header search 
	.header-menu {
		flex: 1;
		display: none;
		margin-right: $_header-spacing / 2;
		width: 100%;
		min-width: 100%;
		order: 5;
		position: absolute;
		justify-content: flex-start;
		font-size: 1.8rem;
		
		&::before {
			content: '';
			display: block;
		}
		
		@include mq($min-width: map-get($breakpoints, m)) {
			display: flex;
			margin-right: 0;
			margin-top: 0;
			min-width: 0;
			order: 2;
			position: relative;
		}

		@include mq($min-width: map-get($breakpoints, xl)) {
			margin-right: $_header-spacing;
		}

		.navigation-level-1{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			li{
				font-size: 2rem;
				@include mq($min-width: map-get($breakpoints, l)) {
					font-size: 2.4rem;
				}
				a{
					color: $text-color;
					padding: 3.5rem 0;
					margin-right: 4rem;
					border-bottom: 5px solid transparent;
					&:hover{
						color: $brand-color-1;
					}
					&.is-open{
						border-bottom-color: $brand-color-1;
					}
					&::before{
						display: none;
					}
				}
				&:last-of-type{
					a{
						margin-right: 0;
					}
				}
			}
		}
	}

	// header actions
	.header-actions {
		display: flex;
		order: 4;
		flex-grow: 1;
		justify-content: flex-end;
		position: relative;
		// flex-basis: 42.2rem;
		flex-basis: auto;
		align-items: center;

		.beratung{
			font-size: 1.8rem;
			font-weight: 300;
			margin-right: 2rem;
			display: none;
		}

		@include mq($min-width: map-get($breakpoints, m)) {
			flex-grow: 0;
			margin-bottom: 1rem;
			.opc-basket-info {
				&:hover {
					.mini-basket {
						display: block;
					}
				}
			}
		}
		@include mq($min-width: map-get($breakpoints, l)) {
			.beratung{
				display: flex;
			}
		}

		>.opc-compare-info,
		>.opc-favorite-info,
		>.opc-basket-info,
		>.search-toggle,
		>.account-link,
		>.search-toggle-desktop {
			margin: 0;
			display: flex;

			>.opc-button {
				margin: 0;
				padding: 0;
				width: 4.8rem;
				height: 3.2rem;
				color: $black;
				justify-content: flex-end;
				overflow: visible;

				&:hover {
					color: $brand-color-1;
				}

				>svg {
					font-size: 2.8rem;
					margin: 0;
				}

				@include mq($min-width: map-get($breakpoints, s)) {}

				@include mq($min-width: map-get($breakpoints, m)) {
					width: 5rem;
					height: 4rem;
					margin-left: 2rem;

					>svg {
						font-size: 4.2rem;
						margin: 0;
					}
				}
			}

			.account-link-user-menu {
				opacity: 0;
				visibility: hidden;
				position: absolute;
				z-index: -100;
				top: calc(100% + 2rem);
				right: 0;
				min-width: 32rem;
				max-width: 52rem;
				min-height: 20rem;
				background: #fff;
				color: $text-color;
				border-radius: .5rem;
				box-shadow: 0 0 10px rgba(#000, .3);
				transition: all .3s ease-in;
				padding: 3rem;
				width: auto;

				@include mq($min-width: map-get($breakpoints, m)) {
					width: 52rem;
				}

				a {
					color: $text-color;
				}

				.opc-navigation {
					margin-bottom: 1rem;

					a {
						font-weight: 300;
						line-height: 1.2;
						font-size: 1.6rem;
					}
				}

				h3 {
					a {
						font-size: 2rem;
						white-space: nowrap;
					}

					margin-bottom: 2rem;
					margin-left: -1rem;
				}

				svg {
					color: $text-color;
					font-size: 4rem;
				}

				.logout-btn {
					float: right;
					padding: 0;

					a {
						padding-right: 4rem;
					}

					svg {
						margin-left: -4rem;
					}

					&:hover {
						a {
							font-weight: 500;
							color: $brand-color-1;
						}

						svg {
							color: $brand-color-1;
						}
					}

				}

				.navigation-level-1 {
					display: flex;
					flex-wrap: wrap;

					>li {
						flex-basis: 100%;

						>a {
							font-weight: 500;

							&::before {
								content: "";
								display: none;
							}

							&:hover {
								background: transparent;
								font-weight: 500;
								color: $brand-color-1;
							}
						}

						.navigation-level-2 {
							>li {
								>a {
									font-weight: 300;
									padding-left: 0;

									&::before {
										content: '';
										background-image: inline-svg($op-icon-angle-right-svg, $text-color);
										margin-right: 0.5rem;
										width: 1em;
										height: 1em;
										display: inline-block;
										background-position: center;
										background-size: 100%;
										background-repeat: no-repeat;
										opacity: 1;
										color: $text-color;
									}

									&:hover {
										background: transparent;
										font-weight: 500;
										color: $brand-color-1;
									}
								}
							}
						}
					}

					@include mq($min-width: map-get($breakpoints, m)) {
						>li {
							flex-basis: calc(50% - 1.5rem);
						}
					}
				}
			}

			&:hover {
				.account-link-user-menu {
					opacity: 1;
					visibility: visible;
					z-index: 100001;
				}
			}

			>a {
				color: $brand-color-1;

				&:hover {
					color: lighten(#000, 40%);
				}
			}
		}

		
		.opc-compare-info{
			display: none;
			@include mq($min-width: map-get($breakpoints, m)) {
				display: block;
			}
			>a{
				>svg{
					display: block!important;
				}
				>span{
					display: none;
				}
				.compare-count {
					width: 2.2rem;
					height: 2.2rem;
					display: block;
					background: transparent;
					border-radius: 2px;
					position: absolute;
					bottom: -5px;
					right: -3px;
					top: auto;
					transform: none;
					font-weight: 700;
					font-size: 1.2rem;
					color: $text-color;
					text-align: center;
					background: $white;
					border: 2px solid $text-color;
					border-radius: 999px;
	
					&:empty {
						display: none;
					}
				}
			}
			@include mq($min-width: map-get($breakpoints, m)) {
				>.opc-button{
					margin-left: 15px;
				}
			}
		}

		>.search-toggle-desktop{
			display: none;
			@include mq($min-width: map-get($breakpoints, m)) {
				display: block;
			}
		}

		>.account-link {
			@include mq($min-width: map-get($breakpoints, m)) {
				// display: none;
			}

			.op-icon-user {
				position: relative;
				z-index: -2;
			}

			.account-link-login-indicator {
				display: none;
				width: 3.2rem;
				height: 3.2rem;
				position: absolute;
				bottom: -6px;
				right: 0px;
				background-size: 70%;
				background-repeat: no-repeat;
				background-position: center center;

				&::before {
					content: "";
					width: 1.8rem;
					height: 1.8rem;
					background: $brand-color-3;
					box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
					position: absolute;
					bottom: 5px;
					right: 7px;
					z-index: -2;
					border-radius: 50%;
				}
			}
		}

		>.opc-favorite-info {
			// display: none;

			@include mq($min-width: map-get($breakpoints, m)) {
				display: flex;
			}

			.favorite-count {
				width: 2.2rem;
				height: 1.9rem;
				display: block;
				background: transparent;
				border-radius: 2px;
				position: absolute;
				bottom: 2px;
				right: 0;
				top: auto;
				transform: none;
				font-weight: 700;
				font-size: 1.2rem;
				color: $text-color;

				&:empty {
					display: none;
				}
			}
		}




		.opc-basket-info {
			.basket-info-count {
				width: 2.2rem;
				height: 2.2rem;
				display: block;
				background: transparent;
				border-radius: 2px;
				position: absolute;
				bottom: -5px;
				right: -3px;
				top: auto;
				transform: none;
				font-weight: 700;
				font-size: 1.2rem;
				color: $text-color;
				text-align: center;
				background: $white;
				border: 2px solid $text-color;
				border-radius: 999px;

				&:empty {
					display: none;
				}
			}
		}

		.search-toggle {
			cursor: pointer;
			// margin-right: 0.5rem;
			width: 4.8rem;
			height: 3.2rem;
			padding-left: 0;
			padding-right: 0;
			background: transparent;
			justify-content: flex-end;

			svg {
				font-size: 2.8rem;
				margin: 0;
			}

			@include mq($min-width: map-get($breakpoints, s)) {
				// width: 5rem;
			}

			@include mq($min-width: map-get($breakpoints, m)) {
				&.search-toggle {
					display: none;
				}
			}


		}

	}
}

@supports(position: sticky) {

	// sticky behaviour
	.header-wrapper[data-op-sticky="true"] {
		position: sticky;
		top: 0;
		transition: transform 0.2s ease-in-out;
		width: 100%;

		&.sticky-inactive {
			//position: fixed;
			transform: translateY(-7rem);
			transform: translateY(-100%);
		}
	}
}