/*###############################################

@Title: Price
@Description:
This file contains the price styles

###############################################*/

$_opc-price-color: $brand-color-1 !default;
$_opc-price-currency-position : "after" !default; // set position of currency. after or before. default is after
.opc-price {
    display: flex;
		margin-bottom: 1rem;
		align-items: flex-end;
    .price-label {
        margin-right: 0.5rem;
        display: none;
    }
    .price-basis,
    .price-service,
    .price-promotion {
        display: flex;
        font-size: 1.8rem;
        color: $_opc-price-color;
        font-weight: 600;
        margin-right: 0;
        align-items: flex-end; // set alignment of elements (in this case all items are aligned to the bottom)
        .prod-pre-info {
            margin-right: 0.4rem;
        }
        .price-currency {
            text-transform: uppercase;
            order: 1;
            margin-right: 0.4rem;
            @if $_opc-price-currency-position=="after" {
                order: 2;
            }
        }
        .price {
            order: 2;
            margin-right: 0.4rem;
            @if $_opc-price-currency-position=="after" {
                order: 1;
            }
        }
    }
    .price-info {
        order: 5;
				display: block;
				font-size: 1.2rem;
				opacity: .7;
    }

	&.is-basis {
		.price-promotion, .price-service {
			display: none;
		}
	}

	&.is-promo {
		.price-basis, .price-service {
			display: none;
		}
	}

	&.is-service {
		.price-promotion, .price-basis {
			display: none;
		}
	}

	// when base price is active
	&.is-basis {
		&.is-promo:not(.is-service) {
			.price-basis {
				text-decoration: line-through;
				display:none!important;
				order: 2;
				color: #999;
				font-size: 1.4rem;
        	}
			.price-promotion {
				order: 1;
			}
			.price-service {
				display: none;
			}
			.price-basis, .price-promotion {
				display: inherit;
			}
		}
		&.is-service:not(.is-promo) {
			.price-basis {
				text-decoration: line-through;
				display:none!important;
				order: 2;
				color: #999;
				font-size: 1.4rem;
        	}
			.price-service {
				order: 1;
			}
			.price-promotion {
				display: none;
			}
			.price-basis, .price-service {
				display: inherit;
			}
		}

		&.is-service.is-promo:not(.show-basis) {
			.price-basis {
				text-decoration: line-through;
				display:none!important;
				order: 2;
				color: #999;
				font-size: 1.4rem;
        	}
			.price-service {
				order: 1;
			}
			.price-basis, .price-service {
				display: inherit;
			}
		}
		&.show-basis{
			position: relative;
			.price-basis{
				position: absolute;
				top: calc(-100% + 3px);
				left: 0;
				font-weight: 400;
				z-index: 2;
				text-decoration: none!important;
				&::after{
						content: "";
						display: block;
						width: 100%;
						height: 0;
						border-top: 1px solid #999;
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
				}
			}
		}
	}

	&.is-service.is-promo:not(.is-basis) {
		.price-promotion {
			text-decoration: line-through;
			order: 2;
			color: #999;
			font-size: 1.4rem;
		}
		.price-service {
			order: 1;
		}
		.price-basis {
			display: none;
		}
		.price-promotion, .price-service {
				display: inherit;
			}
	}
}