/*###############################################

@Title: History
@Description:
This file contains the history styles

###############################################*/

// namespace vars & mapping
$_opc-history-margin : 0.5rem !default;
$_opc-history-color: inherit !default;
$_opc-history-color-hover: $brand-color-1 !default;
// styles 
.opc-history {
    // pretext of the history
    .history-pretext {
        display: inline-block;
        margin-right: $_opc-history-margin;
        vertical-align: top;
    } // history itself
    .history-navigation {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;
        vertical-align: top;
        li {
            float: left;
            margin-right: $_opc-history-margin; // icon after the text / link
            &::after {
                content: '';
                width: 1em;
                height: 1em;
                display: inline-block;
                background-image: inline-svg($op-icon-angle-right-svg, #000);
                background-size: 80%;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
            } // is-active state for history element
            &.is-active {
                font-weight: bold; // disable the after element for the active item
                &::after {
                    display: none;
                }
            }
            a {
                text-decoration: none;
                color: $_opc-history-color;
                &:hover {
                    color: $_opc-history-color-hover;
                }
            }
        }
    }
}