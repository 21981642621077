$opc-quickorder-dropdown-bg-color: #FFF !default;
$opc-quickorder-dropdown-border-color: #CCC !default;
$opc-quickorder-dropdown-loading-border-color: $brand-color-1 !default;
$opc-quickorder-result-bg-color: #FFF !default;
$opc-quickorder-result-color: #000 !default;
$opc-quickorder-message-bg-color: #EEE !default;

.opc-basket-list-quickorder {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq($min-width: map-get($breakpoints, m)) {
        padding: 15px 0px;
    }

    > .quickorder-title {
        margin-bottom: 10px;
        width: 100%;
    }

    > .opc-form-field {
        width: 100%;
        position: relative;

        @include mq($min-width: map-get($breakpoints, m)) {
            flex-basis: 50%;
            width: 50%;
            max-width: 50%;
            margin-right: 1rem;
        }
    }

    .quickorder-search-result-list-wrapper {
        display: none;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 10;
        background: $opc-quickorder-dropdown-bg-color;
        border: 1px solid $opc-quickorder-dropdown-border-color;
        border-top: none;

        &::before {
            content: '';
            width: 3rem;
            height: 3rem;
            background: transparent;
            display: none;
            border-radius: 50%;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            top: 0.5rem;
            margin-left: -1.5rem;
            border: 2px solid transparent;
            border-top: 2px solid $opc-quickorder-dropdown-loading-border-color;
            animation-name: rotater;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        @keyframes rotater {
            50% {
                transform: rotate(400deg);
            }
        }

        &.is-showing {
            display: block;

            &::before {
                display: none;
            }
        }

        &.is-loading {
            display: block;
            min-height: 5rem;
            &::before {
                display: block;
            }
        }

        .quickorder-search-result-list {
            width: 100%;
            margin: 0px;
            display: flex;
            flex-wrap: wrap;
            z-index: 2;
            padding: 0px;
            min-height: 3rem;
            max-height: 30rem;
            overflow-y: auto;

            li {
                width: 100%;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                display: flex;
                align-items: center;
                background: $opc-quickorder-result-bg-color;
                margin-bottom: 0.5rem;
                min-height: 7.5rem;

                &:hover {
                    cursor: pointer;
                }

                .list-item-image {
                    flex-basis: 6rem;
                    margin-right: 2rem;
                    flex-shrink: 0;
                    flex-grow: 0;
                    padding: 0.5rem 0px;

                    img {
                        margin: 0 auto;
                        display: block;
                        max-width: 100%;
                        max-height: 75px;
                    }
                }

                .list-item-info-wrapper {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }

                .list-item-info {
                    max-width: 100%;
                    flex-basis: 1%;
                    flex-grow: 1;
                    padding: 0.5rem 0px;
                    color: $opc-quickorder-result-color;

                    .list-item-title {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        overflow: hidden;

                        margin-bottom: 0.4em;
                        font-size: 1.4rem;
                        font-weight: bold;

                        .article-flag-wrapper {
                            margin-left: 1rem;
                        }
                    }
                }

                .list-item-basket-action {
                    display: flex;
                    margin-left: auto;

                    .basket-button-quantity {
                        width: 3rem;
                    }

                    .opc-button {
                        > span {
                            display: none;

                            @include mq($min-width: map-get($breakpoints, m)) {
                                display: block;
                            }
                        }
                    }
                }

                .list-item-basket-button {
                    margin-left: 2rem;
                    height: 100%;

                    .opc-button {
                        width: 7.5rem;
                        height: 100%;
                    }
                }

                .opc-message {
                    margin-bottom: 0;
                    width: 100%;
                }
            }

            > li:last-child {
                border-bottom: none;
            }
        }

        .opc-quickorder-message {
            height: 4rem;
            position: relative;
            z-index: 2;
            background: $opc-quickorder-message-bg-color;
            margin: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}