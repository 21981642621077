/*###############################################

@Title: Basket Info
@Description:
This file contains the basket info styles

###############################################*/

// basket info button
$_opc-basket-button-font-size : 3.8rem !default;

// mini basket head
$_opc-basket-head-padding : 2rem !default;
$_opc-basket-head-bg : transparent !default;
$_opc-basket-head-color : inherit !default;
$_opc-basket-head-font-size : 1.8rem !default;

// mini basket content      
$_opc-basket-body-padding : 0 !default;
$_opc-basket-body-bg : transparent !default;
$_opc-basket-body-color : inherit !default;
$_opc-basket-body-font-size : 1.4rem !default;

// mini basket footer       
$_opc-basket-footer-padding : 2rem !default;
$_opc-basket-footer-bg : transparent !default;
$_opc-basket-footer-color : inherit !default;
$_opc-basket-footer-font-size : 1.4rem !default;


.opc-basket-info {
    position: relative;
    z-index: 1000; 
    
    // initial anchor button
    >.opc-button {
        font-size: $_opc-basket-button-font-size;
        border: none;
        background: none;
        margin-left: 0.5rem;
        cursor: pointer;
        position: relative;
        padding: 0 0.5rem;
        svg {
            font-size: 4rem;
        }
        &:hover {}
        .basket-info-count {
            width: 1.8rem;
            height: 1.8rem;
            display: block;
            background: $brand-color-1;
            border-radius: 50%;
            position: absolute;
            top: 0.4rem;
            right: 1.4rem;
            right: 50%;
            transform: translateX(calc(50% + 2px));
            transform: translateX(50%) translateX(2px);
            font-size: 1.1rem;
            text-align: center;
            line-height: 1.8rem;
            color: #fff;
            &:empty {
                display: none;
            }
        }
    } 
    
    // mini basket
    .mini-basket {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 100;
        display: none;
        background: #fff;
        box-shadow: 1px 1px 3px rgba(#000, 0.3);
        min-width: 30rem;
        max-width: 30rem;
        width: 30rem; 
        
        // header of basket item
        .mini-basket-head {
            padding: $_opc-basket-head-padding ($_opc-basket-head-padding / 2);
            background: $_opc-basket-head-bg;
            color: $_opc-basket-head-color;
            font-size: $_opc-basket-head-font-size;
            border-bottom: 1px solid #ccc;
            @include mq($min-width: map-get($breakpoints, m)) {
                padding: $_opc-basket-head-padding;
            }
            .mini-basket-quantity {
                &::before {
                    content: '(';
                }
                &::after {
                    content: ')';
                }
            }
        }
        &.is-open {
            display: block;
        }
        .mini-basket-body {
            max-height: 30rem;
            overflow-x: hidden;
            overflow-y: auto;
            padding: $_opc-basket-body-padding;
            background: $_opc-basket-body-bg;
            color: $_opc-basket-body-color;
            font-size: $_opc-basket-body-font-size;
        }
        .mini-basket-footer {
            border-top: 1px solid #ccc;
            padding: ($_opc-basket-footer-padding / 2);
            background: $_opc-basket-footer-bg;
            color: $_opc-basket-footer-color;
            font-size: $_opc-basket-footer-font-size;
            @include mq($min-width: map-get($breakpoints, m)) {
                padding: ($_opc-basket-footer-padding / 2) $_opc-basket-footer-padding;
            }
            .mini-basket-footer-total {
                padding: 0 0 2rem;
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-weight: bold;
                b {
                    font-weight: normal;
                }
            } 
            
            // fullwidth button for checkout
            .opc-button {
                width: 100%;
                text-align: center;
            }
        } 
        
        // mini basket itemlist 
        .basket-item-list {
            list-style: none;
            margin: 0;
            padding: 0; 
            
            // item 
            .basket-item {
                display: flex;
                align-items: center;
                padding: 1rem;
                position: relative;
                border-bottom: 1px solid #f5f5f5;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding: 1rem 2rem;
                }
                &:hover {
                    background: #f5f5f5;
                    z-index: 10;
                } 
                
                // thumbnail
                .basket-item-thumb {
                    width: 4rem;
                    max-width: 4rem;
                    margin-right: 1.5rem;
                    flex-shrink: 0;
                    align-self: flex-start;
                    img {
                        max-width: 100%;
                    }
                }
                
                // info
                .basket-item-info {
                    flex-grow: 1;
                    .basket-item-title {
                        width: 100%;
                        display: block;
                        padding-right: 2rem;
                        margin-bottom: 0.4rem;
                        a {
                            font-weight: bold;
                            color: #000;
                            &:hover {
                                color: lighten(#000, 20%);
                            }
                        }
                    }
                    .basket-item-article-number {
                        width: 100%;
                        display: block;
                        color: #999;
                        font-size: 1.2rem;
                        margin-bottom: 0.4rem;
                    }
                    .basket-item-quantity {}
                    .mini-basket-unit {
                        margin-right: 1rem;
                    }
                    .basket-item-price-wrapper {
                        float: right;
                        .basket-item-currency {
                            color: $brand-color-1;
                            font-weight: bold;
                        }
                        .basket-item-price {
                            color: $brand-color-1;
                            font-weight: bold;
                        }
                    }
                } 
                
                // actions (delete)
                .basket-item-actions {
                    position: absolute;
                    right: 1rem;
                    top: 1rem;
                    .opc-button {
                        color: #000;
                        background: transparent;
                        padding: 0.2rem;
                        line-height: 1;
                        svg {
                            font-size: 1.6rem !important;
                        }
                        &:hover {
                            background: none;
                            color: crimson;
                        }
                    }
                }
            }
        }
    }
}