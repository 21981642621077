/*###############################################

@Title: Filter
@Description:
This file contains the filter styles

###############################################*/

$_opc-filter-tag-bg: $darker-grey;
$_opc-filter-tag-color: #fff !default;
$_opc_filter-toggle-bg: rgba(#eee, 0.8) !default;
$_opc_filter-toggle-bg-hover: rgba(#eee, 1) !default;
$noUi-css-prefix: opc-rangeslider !default;

.opc-filter-set, .opc-item-list {
	.item-marker {
		width: 1.2em;
		height: 1.2em;
		display: none;
		margin-right: 1rem;
	}
}

// filter set wrapper
.opc-filter-set {
    position: relative;
    margin-bottom: 4rem;
    font-size: 1.6rem;
    .filter-toggle {
        background: $_opc_filter-toggle-bg;
        padding: 1rem 2rem;
        text-align: center;
        margin-bottom: 2rem;
        cursor: pointer;
        display: block;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: none;
        }
        &:hover {
            background: $_opc_filter-toggle-bg-hover;
        }
        i {
            margin-right: 1rem;
        }
    } // shows selected filter entries
    .filter-set-selection {}
    // to reset the filters
    .filter-reset {
        display: inline-block;
        padding: 0;
        opacity: 0.6;
        color: crimson;
        &:hover {
            opacity: 1;
        }
    } // filter set list to define gutter and padding of the filters
    .filter-set-list {
        flex-direction: column;
        display: none;
        margin-bottom: 2rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex !important;
        }
        &.is-open {
            display: flex;
        }
        .filter-set-list-item {
            padding: 0;
            width: 100%;
            max-width: 100%;
        }
    } // horizontal filter set
    &[data-op-layout="horizontal"] {
        .filter-set-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            flex-direction: row;
            margin-left: -$_opc-grid-gutter-width;
            margin-right: -$_opc-grid-gutter-width;
            display: none;
            &.is-open {
                display: flex;
            }
            .filter-set-list-item {
                padding: 0 $_opc-grid-gutter-width;
                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-basis: span(6);
                    width: span(6);
                    max-width: span(6);
                }
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: span(3);
                    width: span(3);
                    max-width: span(3);
                }
            }
        }
    }
}

// filter itself
.opc-filter {
    margin-bottom: 2rem;
    width: 100%;
    position: relative;
    z-index: 10; // filter head
    .filter-head {
        padding: 10px 0;
        svg {
            font-size: 1.4em;
        }
    } // filter body
    .filter-body {
        margin-top: -1px;
        background: #fff;
        border: 1px solid #ccc;
        width: 100%; // filter body search
        .filter-body-search {
            padding: 0;
            input {
                border: none;
                background: rgba(#ccc, 0.1);
                padding: 1rem 2rem;
                width: 100%;
            }
        } // filter body inner (contains the filter ocntent with a max height)
        .filter-body-inner {
            border-top: 1px solid #ccc;
            overflow: hidden;
            overflow-y: auto;
            max-height: 200px;
        } // filter reset
        .filter-body-reset {
            border-top: 1px solid #ccc;
            padding: 0.5rem 2rem;
            a {
                color: crimson;
                opacity: 0.6;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    /*
    Types
  */
    // range filter
    &[data-op-type="range"] {
        .filter-body-inner {
            padding: 1rem 2rem;
            border-top: none;
            position: relative;
        }

        .slider-values {
            position: absolute;
            left: 50%;
            bottom:4rem;
            transform: translateX(-50%);
            background: #d9d9d9;
            color: #000;
            border-radius: 5px;
            display: block;
            padding: 0.25rem 1rem;
        }
    } // search filter
    &[data-op-type="search"] {}
    // classic filter with search and a list
    &[data-op-type="select"] {}
    /*
    Styles
  */
    // filter in dropdown
    &[data-op-style="dropdown"] {
        background: #fff;
        .filter-head {
            padding: 1.5rem 2rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            position: relative;
            svg {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 2rem;
            }
        }
        .filter-body {
            margin-top: -1px;
            background: #fff;
            border: 1px solid #ccc;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            display: none;
            .filter-body-search {
                padding: 0;
                input {
                    border: none;
                    background: rgba(#ccc, 0.1);
                }
            }
        }
    } // filter classic
    &[data-op-style="classic"] {
        .filter-head {
            font-size: 2.2rem;
            svg {
                display: none;
            }
        }
    }
    &.is-open {
        z-index: 12;
        .filter-head {
            &::after {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
        .filter-body {
            display: block;
        }
    }
}

// filter tags
.filter-tag-list {
    margin-bottom: 1rem;
    .tag {
        padding: 1rem 3.5rem 1rem 2rem;
        display: inline-block;
        margin-right: 0.5rem;
        margin-bottom: 1rem;
        background: $_opc-filter-tag-bg;
        color: $_opc-filter-tag-color;
        border-radius: 5rem;
        font-size: 1.6rem;
        position: relative;
        .tag-type {
            opacity: 0.8;
            margin-right: 0.5rem;
        }
        .tag-value {
            font-weight: bold;
			display: inline-flex;
			align-items: baseline;
        }
        .tag-remove {
            margin-left: 1rem;
            opacity: 0.6;
            font-weight: bold;
            padding: 0 1.5rem 0 1rem;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-indent: -9999px;
            overflow: hidden;
            background-image: inline-svg($op-icon-cross-svg, '#fff');
            background-size: 1.6rem;
            background-repeat: no-repeat;
            background-position: calc(100% - 1rem) center;
            &:hover {
                opacity: 1;
            }
            &::before {
                content: 'x';
            }
        }
    }
}

// select list für filter
.select-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }
        label {
            display: block;
            padding: 1rem 2rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
                background: rgba(#000, 0.05);
            }
            &::before {
                content: "";
                display: inline-block;
                width: 1.2em;
                height: 1.2em;
                background-image: inline-svg($op-icon-check-svg, '#eee');
                background-size: cover;
                background-position: center;
                margin-right: 1rem;
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }
        }
        input:checked+label {
            &::before {
                opacity: 1;
                color: #1da775;
                background-image: inline-svg($op-icon-check-svg, $success-color);
            }
        }
        &:only-child {
            label {
                //pointer-events: none;
            }
        }
    }
}

.range-slider {
    margin: 0;
    &[data-op-orientation="horizontal"] {
        margin: 39px 0 5px 0;
    }
}

/*! nouislider - 10.1.0 - 2017-07-28 17:11:18 */

/* Functional styling;
 * These styles are required for.#{$noUi-css-prefix}Slider to function.
 * You don't need to change these rules to apply your design.
 */

.#{$noUi-css-prefix}-target,
.#{$noUi-css-prefix}-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.#{$noUi-css-prefix}-target {
    position: relative;
    direction: ltr;
}

.#{$noUi-css-prefix}-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    /* Fix 401 */
}

.#{$noUi-css-prefix}-connect {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.#{$noUi-css-prefix}-origin {
    position: absolute;
    height: 0;
    width: 0;
}

.#{$noUi-css-prefix}-handle {
    position: relative;
    z-index: 1;
}

.#{$noUi-css-prefix}-state-tap .#{$noUi-css-prefix}-connect,
.#{$noUi-css-prefix}-state-tap .#{$noUi-css-prefix}-origin {
    -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}

.#{$noUi-css-prefix}-state-drag * {
    cursor: inherit !important;
}

/* Painting and performance;
  * Browsers can paint handles in their own layer.
  */

.#{$noUi-css-prefix}-base,
.#{$noUi-css-prefix}-handle {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
  */

.#{$noUi-css-prefix}-horizontal {
    height: 1rem;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle {
    width: 2rem;
    height: 2rem;
    left: -1rem;
    top: -0.75rem;
    &::before,
    &::after {
        display: none;
    }
}

.#{$noUi-css-prefix}-vertical {
    width: 18px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
}

/* Styling;
  */

.#{$noUi-css-prefix}-target {
    background: #eee;
    border-radius: 50px;
    border: 1px solid transparent; //box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.#{$noUi-css-prefix}-connect {
    background: $brand-color-1;
    border-radius: 4px; //box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

/* Handles and cursors;
  */

.#{$noUi-css-prefix}-draggable {
    cursor: ew-resize;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-draggable {
    cursor: ns-resize;
}

.#{$noUi-css-prefix}-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.#{$noUi-css-prefix}-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Handle stripes;
  */

.#{$noUi-css-prefix}-handle:before,
.#{$noUi-css-prefix}-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 14px;
    top: 6px;
}

.#{$noUi-css-prefix}-handle:after {
    left: 17px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle:before,
.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle:after {
    top: 17px;
}

/* Disabled state;
  */

[disabled] .#{$noUi-css-prefix}-connect {
    background: #B8B8B8;
}

[disabled].#{$noUi-css-prefix}-target,
[disabled].#{$noUi-css-prefix}-handle,
[disabled] .#{$noUi-css-prefix}-handle {
    cursor: not-allowed;
}

/* Base;
  *
  */

.#{$noUi-css-prefix}-pips,
.#{$noUi-css-prefix}-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.#{$noUi-css-prefix}-pips {
    position: absolute;
    color: #ccc;
}

/* Values;
  *
  */

.#{$noUi-css-prefix}-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.#{$noUi-css-prefix}-value-sub {
    color: #ccc;
    font-size: 10px;
}

/* Markings;
  *
  */

.#{$noUi-css-prefix}-marker {
    position: absolute;
    background: #CCC;
}

.#{$noUi-css-prefix}-marker-sub {
    background: #AAA;
}

.#{$noUi-css-prefix}-marker-large {
    background: #AAA;
}

/* Horizontal layout;
  *
  */

.#{$noUi-css-prefix}-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.#{$noUi-css-prefix}-value-horizontal {
    -webkit-transform: translate3d(-50%, 50%, 0);
    transform: translate3d(-50%, 50%, 0);
}

.#{$noUi-css-prefix}-marker-horizontal.#{$noUi-css-prefix}-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.#{$noUi-css-prefix}-marker-horizontal.#{$noUi-css-prefix}-marker-sub {
    height: 10px;
}

.#{$noUi-css-prefix}-marker-horizontal.#{$noUi-css-prefix}-marker-large {
    height: 15px;
}

/* Vertical layout;
  *
  */

.#{$noUi-css-prefix}-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.#{$noUi-css-prefix}-value-vertical {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
    padding-left: 25px;
}

.#{$noUi-css-prefix}-marker-vertical.#{$noUi-css-prefix}-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.#{$noUi-css-prefix}-marker-vertical.#{$noUi-css-prefix}-marker-sub {
    width: 10px;
}

.#{$noUi-css-prefix}-marker-vertical.#{$noUi-css-prefix}-marker-large {
    width: 15px;
}

.#{$noUi-css-prefix}-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
    border: none;
    font-weight: bold;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle-upper .#{$noUi-css-prefix}-tooltip {
    left: auto;
    right: 0;
    transform: none;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle-lower .#{$noUi-css-prefix}-tooltip {
    left: 0;
    right: auto;
    transform: none;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}
