/*###############################################

@Title: Page Checkout
@Description:


###############################################*/

.page-checkout {
    .content-box {
        .content-box-head {
            padding: 2rem;
            position: relative; // title
            h2 {
                margin: 0;
                padding: 0;
                margin-bottom: 1rem;
                span {
                    font-size: 3rem;
                    font-size: inherit;
                    width: 3rem;
                    margin-right: 0;
                    display: inline-block;
                }
            } // toggler
            .content-box-signalisation {
                position: absolute;
                right: 2rem;
                top: 2rem; //width: 2rem;
                //height: 2rem;
                text-align: center;
                font-size: 2rem;
                line-height: 2rem;
                display: flex;
                align-items: center;
                svg {
                    margin-left: 2rem;
                    color: $success-color;
                }
                .opc-button {
                    opacity: 1;
                    visibility: visible;
                }
                svg {
                    opacity: 1;
                    transition: all 0.4s ease-in-out;
                }
            }
            .content-box-head-subline {
                padding: 0 0 0 3rem;
                opacity: 1; //transition: all 0.2s ease-in-out;
                span {
                    display: inline-block;
                    margin-right: 2rem;
                    margin-bottom: 0.5rem;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.is-open {
            .content-box-head {
                padding-bottom: 0;
                @include mq($min-width: map-get($breakpoints, m)) {}
                .content-box-toggler {
                    transform: translateY(-50%) rotate(-180deg);
                }
                .content-box-head-subline {
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                }
                .content-box-signalisation {
                    .opc-button {
                        opacity: 0;
                        visibility: hidden;
                    }
                    svg {
                        opacity: 0;
                    }
                }
            }
        }
        &.is-open {
            .content-box-body {
                display: block;
            }
        }
        .content-box-body {
            display: none;
        }
        &[data-op-isdone="true"] {
            .content-box-signalisation {
                visibility: visible;
                opacity: 1;
            }
        }
        &[data-op-isdone="false"] {
            .content-box-signalisation {
                .opc-button {
                    visibility: hidden;
                    opacity: 0;
                }
                .op-icon-check {
                    color: #ddd;
                }
            }
            &.is-open {
                .content-box-signalisation {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
        &[data-op-type="summary"] {
            .opc-button {
                &[data-op-isok="false"] {
                    pointer-events: none;
                    opacity: 0.25;
                }
            }
        }
    }
    .checkout-addresses {
        @extend %opc-row;
        display: flex;
        flex-wrap: wrap;
        .shipping-address {
            padding: $_opc-grid-gutter-width;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 50%;
            }
        }
        .billing-address {
            padding: $_opc-grid-gutter-width;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 50%;
            }
        }
        #address-form {
            display: none;
            padding: 1rem;
            border: 1px solid #eee;
            .opc-col {
                margin-bottom: 0;
            }
        }
    }
    .delivery-selection {
        .opc-radio {
            b {}
            span {
                display: inline-block;
                display: block;
            }
            a {
                display: block;
                margin-top: 0.5rem;
                color: blue;
                text-decoration: underline;
            }
            .shipping-price {
                //position: absolute;
                //right: 2rem;
                //top: 1.5rem;
                display: inline-block;
                margin-left: 1rem;
                color: orange;
                font-weight: bold; //float: right;
                position: absolute;
                right: 1rem;
                top: 1rem;
                span {
                    display: inline-block;
                    margin-top: 0.5rem;
                }
            }
        }
    }
    .payment-selection {
        .payment-icon {}
        .payment-text {
            font-weight: bold;
        }
        .payment-info {
            display: block;
        }
    }
}