/*###############################################

@Title: Page Account
@Description:


###############################################*/

.page-account {
    .section-inner-aside {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: block; 
        }
    }
    h2 {
        position: relative;
        .opc-button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .opc-navigation[data-op-navtype="simple"] {
        .navigation-level-1 {
            >li {
                margin-bottom: 2rem;
                >a {
                    &::before {
                        display: none;
                    }
                }
                &.is-active {
                    >a {
                        font-weight: bold;
                    }
                }
            }
        }
        .navigation-level-2 {
            >li {
                >a {
                    padding-left: 1rem;
                }
                &:hover {
                    >a {
                        background: #f8f8f8;
                    }
                }
                &.is-active {
                    >a {
                        font-weight: bold;
                        background: #eee;
                    }
                }
            }
        }
    }
	.opc-button-wrapper[data-op-align="right"] {
		justify-content: center;
		@include mq($min-width: map-get($breakpoints, m)) {
			justify-content: flex-end;
		}
		.opc-button {
			margin: 0 auto;
			width: 100%;
			white-space: normal;
			@include mq($min-width: map-get($breakpoints, s)) {
				width: auto;
				white-space: nowrap;
			}
			@include mq($min-width: map-get($breakpoints, m)) {
				margin: inherit;
			}
		}

	}
}

// list for order details
.last-orders-details-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
            .list-item-column {}
        }
    }
    .list-item {
        flex-wrap: wrap;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-wrap: nowrap;
        }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0 0 1rem;
        display: flex;
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0.5rem 2rem 0 0;
        }
        .opc-button {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        &:nth-child(1) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 40%; //flex-shrink: 0;
                flex-grow: 1;
            }
        }
        &:nth-child(2) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 10%;
                flex-shrink: 0; //flex-grow: 1;
            }
        }
        &:nth-child(3) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
                flex-shrink: 0;
                justify-content: flex-end;
            }
        }
        &:nth-child(4) {
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 15%;
                flex-shrink: 0;
                justify-content: flex-end;
            }
        }
        &:nth-child(5) {
            .opc-button {
                margin-bottom: 0.5rem;
                font-size: 1.4rem;
				justify-content: center;
				width: 100%;
				@include mq($min-width: map-get($breakpoints, m)) {
					width: inherit;
					// justify-content: flex-end;
					font-size: 1.2rem;
	            }
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 20%;
                width: 18rem;
                flex-shrink: 0;
                justify-content: flex-end;
                padding-right: 0;
                flex-wrap: nowrap;
            }
        }
        .list-item-label {
            flex-shrink: 0;
            display: block;
            width: 35%;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
		&.list-item-column-actions {
			align-items: normal;
			.opc-button {
				flex-grow: 1;
			}
		}
    }
}

// lieferadressen
.shipping-address-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, xs)) {
            display: flex;
        }
    }
    .list-item {
        flex-wrap: wrap;
        position: relative;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include mq($min-width: map-get($breakpoints, xs)) {
            flex-wrap: nowrap;
            padding-top: 0;
            padding-bottom: 0;
        }
        .button-text-edit {
            display: block;
        }
        .button-text-save {
            display: none !important;
        }
        .delete-list-item {
            display: inline-flex;
        } // styles for edit mode
        &.is-changing {
            .button-text-save {
                display: block !important;
                @include mq($min-width: map-get($breakpoints, xs)) {
                    display: none !important;
                }
            }
            .button-text-edit {
                display: none !important;
            }
            .delete-list-item {
                display: none !important
            }
            .opc-form-field-group,
            .list-item-column  {
                width: 100%;
            }
        }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0.5rem 0 0;
        width: auto;
        flex-grow: 0;
        
        @include mq($min-width: map-get($breakpoints, xs)) {
            padding: 0.5rem 2rem 0 0;
            flex-grow: 1;
            width: 100%;
        }
        .opc-button {
            padding-left: 0.5rem;
			padding-right: 0.5rem;
			width: 100%;

			@include mq($min-width: map-get($breakpoints, xs)) {
				width: unset;
			}
        }
        &:nth-child(1) {
            //border-bottom: 1px solid #eee;
            font-weight: bold;
            @include mq($min-width: map-get($breakpoints, xs)) {
                border-bottom: none;
                font-weight: normal;
                flex-grow: 0;
                width: 10%;
                min-width: 8rem;
            }
        }
        &:nth-child(2) {
			//border-bottom: 1px solid #eee;
			font-weight: bold;
            @include mq($min-width: map-get($breakpoints, xs)) {
                border-bottom: none;
                font-weight: normal;
                width: 25%;
                flex-grow: 0;
            }
        }
        &:nth-child(3) {
            //border-bottom: 1px solid #eee;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, xs)) {
                border-bottom: none;
                width: 20%;
                flex-grow: 1;
            }
        }
        &:nth-child(4) {
			text-align: left;
            margin-bottom: 2rem;
            width: 100%;

            .opc-button {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
                border: 1px solid $brand-color-2;
                svg {
                    margin-right: 1rem !important;
                    &:only-child {
                        margin-right: 0 !important;
                    }
                }
                span {
                    display: block;
                }
            }
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            @include mq($min-width: map-get($breakpoints, xs)) {
				margin-bottom: 0;
                flex-direction: row;
                .opc-button {
                    flex-grow: 1;
                    margin-right: 1rem;
                    &:last-child,
                    &:only-child {
                        margin-right: 0;
                    }
                }
            }
            @include mq($min-width: map-get($breakpoints, xs)) {
                text-align: right;
                width: 30rem;
                padding-right: 0;
                flex-direction: row;
                position: static;
                display: block;
                width: 10%;
                flex-grow: 0;
                min-width: 10rem;
                text-align: right;
                .opc-button {
                    margin-right: 0;
                    border: none;
                    flex-shrink: 0;
                    span {
                        display: none;
                    }
                }
            }
        }
        .list-item-label {
            display: inline-block;
            width: 35%;
            display: none;

            @include mq($min-width: map-get($breakpoints, xs)) {
                display: none;
            }
        }
        .opc-form-field {
            margin-bottom: 0;
        }
        .opc-form-field-group {
            width: auto;
            display: inline-flex;
        }
    }
}

// meine warenkoerbe
.last-baskets-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
        }
    }
    .list-item {
        flex-wrap: wrap;
        position: relative;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-wrap: nowrap;
        }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0.5rem 0 0.5rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0.5rem 2rem 0 0;
        }
        .opc-button {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        &:nth-child(1) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
                flex-shrink: 0;
            }
        }
        &:nth-child(2) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 20%;
                flex-shrink: 0;
                flex-grow: 1;
            }
        }
        &:nth-child(3) {
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 20%;
                flex-shrink: 0;
            }
        }
        &:nth-child(4) {
            .opc-button {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
            }
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            padding-top: 0.5rem;
            @include mq($min-width: 380px) {
                position: absolute;
                right: 0;
                top: 1rem;
                width: auto;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 30rem;
                padding-right: 0;
                display: block;
                position: static;
            }
			&.list-item-column-actions {
				align-items: normal;
			}
        }
        .list-item-label {
            display: inline-block;
            width: 40%;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
    }
}

// meine bestellungen
.last-offers-list {
    .item-list-head {
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
        }
    }
    .list-item {
        flex-wrap: wrap;
        position: relative; ///margin: 1rem 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-wrap: nowrap;
        }
    } // style of the cols
    .list-item-column {
        width: 100%;
        padding: 0.5rem 0 0.5rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0.5rem 2rem 0 0;
        }
        .opc-button {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        &:nth-child(1) {
            @include mq($min-width: map-get($breakpoints, tiny)) {
                //width: 20%;
                flex-shrink: 0;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
                flex-shrink: 0;
            }
        }
        &:nth-child(2) {
            @include mq($min-width: map-get($breakpoints, tiny)) {
                //width: 20%;
                flex-shrink: 0;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 20%;
                flex-shrink: 0; //flex-grow: 1;
            }
        }
        &:nth-child(3) {
            @include mq($min-width: map-get($breakpoints, tiny)) {
                //width: 20%;
                flex-shrink: 0;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 10%;
                flex-shrink: 0;
            }
        }
        &:nth-child(4) {
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 20%;
                flex-shrink: 0;
            }
        }
        &:nth-child(5) {
            .opc-button {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
            }
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            @include mq($min-width: map-get($breakpoints, tiny)) {
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                text-align: right;
                width: 30rem;
                padding-right: 0;
                flex-direction: row;
                position: static;
                display: block;
            }
			&.list-item-column-actions {
				align-items: normal;
			}
        }
        .list-item-label {
            display: inline-block;
            width: 30%;
            @include mq($min-width: 400px) {
                //display: block;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
    }
}

.account-user-data {
    margin-bottom: 2rem;
	padding-bottom: 4rem;
    h3 {
        margin-bottom: 0.5em;
        opacity: 0.6;
    }
    dl {
        font-size: 1.4rem; //margin-bottom: 2rem;
        border-bottom: 1px solid #eee;
        padding: 1rem 0 0;
        dt {
            font-size: 1em;
            margin-bottom: 0;
        }
        dd {
            padding: 1rem 0;
            &.editmode {
                padding: 0;
            }
            .opc-form-field {
                margin: 0;
                margin-right: 3rem;
                .form-field-element {
                    input,
                    select {
                        border: none;
                        padding: 1rem 0;
                        font-weight: bold;
                        background: #f8f8f8;
                    }
                }
            }
        }
    }
    .edit-btn {
        position: absolute;
        right: 1.5rem;
        bottom: 1rem; //transform: translateY(-50%);
        //display: none;
    }
    .editmode {
        display: none;
    }
    .opc-col {
        &:hover,
        &.is-changing {
            .opc-button {
                display: block;
            }
        }
    }
    .op-icon-check {
        display: none;
    }
    .is-changing {
        dd {
            display: none;
        }
        .editmode {
            display: block;
        }
        .op-icon-edit {
            display: none;
        }
        .op-icon-check {
            display: block;
        }
    }
}

#filter-form {
    .opc-button {
        margin-top: 24px;
    }
}

// STATISTIC-OVERVIEW
#statistic-overview {
    h2 { font-size: 24px }
    .account-user-data { padding-bottom: 4rem }
// STATISTIC-OVERVIEW - Anzahl Bestellungen
.statistics-ow-list-best {
    .list-item-column {
        min-height: 50px;
        padding: 5px 0;
        font-size: 14px;
        justify-content: flex-end;
        &:nth-child(1) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 16%;
            }
        }
        &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15%;
            }
            text-align: right;
        }
        &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 6%;
            }
            text-align: right;
        }
    }
    .list-item-column-br {
        border-right: 1px solid #CCC;
        padding-right: 10px;
    }

}

// STATISTIC-OVERVIEW - Anzahl Bestellungen 2
.statistics-ow-list-best2 {
    .list-item-column {
        min-height: 50px;
        padding: 5px 0;
        font-size: 14px;
        justify-content: flex-end;
        &:nth-child(1) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 16%;
            }
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11), &:nth-child(12), &:nth-child(13) {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 7%;
            }
            text-align: right;
        }
    }
}

.statistics-ow-list-cat {
    .list-item-column {
        justify-content: flex-end;
    }
    .list-item-column-1 {
        justify-content: flex-start;
    }
}
}