/*###############################################

@Title: Footer
@Description:


###############################################*/

// namespace vars
$_footer-bg: 					$light-grey;	// background of footer
$_footer-inner-bottom-bg:		$_footer-bg;	// background of bottom part of the footer
$_footer-padding:				4rem;	// padding of the footer
$_footer-inner-bottom-padding: 	6rem;	// padding of the bottom part of the footer

$_footer-color:					$black;
$_footer-color-hover:			$black;


// footer layout styles

.footer-wrapper {
	@extend %mw-custom;
	margin-top: auto;
	background: $_footer-bg;
	padding: 0;
	// overflow: hidden;

	.footer-inner {
		@extend %mw-inner;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		padding-left: $mw-padding / 3;
		padding-right: $mw-padding / 3;
		
		@include mq($min-width: map-get($breakpoints, m)) {
			flex-direction: row;
			padding-left: $mw-padding;
			padding-right: $mw-padding;
		}
		@include mq($min-width: map-get($breakpoints, xl)) {
			padding-left: $mw-padding;
			padding-right: $mw-padding;
		}

		.opc-row {
			width: calc(100% + (#{$_opc-grid-gutter-width} * 2));
			padding-top: $_footer-padding;
		}

		.footer-inner-top {
			padding-top: 5rem;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			@include mq($min-width: map-get($breakpoints, m)) {
				padding-top: 12rem;
				flex-wrap: nowrap;
			}

			h2 {
				font-size: 2.4rem;
				font-weight: 500;
				margin: 2.5rem 0 1.2rem;
				padding: 0;
				color: $black;
			}

			.footer-column {
				flex-grow: 1;
				flex-basis: 100%;
				width: 100%;
				display: none;
				flex-basis: calc(100% / 4);
				width: calc(100% / 4);
				// padding-left: 1.5rem;
				// @include mq($min-width: map-get($breakpoints, l)) {
				// 	padding-left: 0;
				// }
				
				&:nth-child(1){
					@include mq($min-width: map-get($breakpoints, l)) {
						display: block;
					}
					img{
						max-width: 13rem;
					}
				}
				&:nth-child(2),
				&:nth-child(3){
					.opc-navigation[data-op-navtype="simple"] .navigation-level-2>li>a {
						padding: .8rem 0;
					}
					@include mq($min-width: map-get($breakpoints, m)) {
						display: block;
					}
				}
				&:nth-child(4){
					display: block;
					h2,
					.opc-navigation{
						display: none;
						@include mq($min-width: map-get($breakpoints, m)) {
							display: block;
						}
					}
					.footer-social {
						margin-top: 3rem;
						text-align: center;
						transform: scale(1.5);
						@include mq($min-width: map-get($breakpoints, m)) {
							transform: scale(1);
							margin-top: 4rem;
							text-align: left;
						}
						a {
							font-size: 2rem;
							color: $black;
							margin-right: 2rem;
							line-height: 2;
							&:hover{
								color: $brand-color-1;
							}
						}
					}
				}
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4){
					.opc-navigation[data-op-navtype="simple"] .navigation-level-2>li>a {
						&:hover{
							color: $brand-color-1;
						}
					}
				}

			}

		}
	}

	.footer-inner-payment {
		@extend %mw-inner;
		display: flex;
		flex-wrap: wrap;
		padding-top: $_footer-padding / 2;
		padding-bottom: $_footer-padding / 2;
		border-top: 1px solid rgba(#fff, 0.2);
		justify-content: center;
		width: 100%;

		@include mq($min-width: map-get($breakpoints, m)) {
			justify-content: flex-start;
		}

		img {
			margin-right: 1rem;
		}
	}

	.footer-col-categories {
		display: none;

		@include mq($min-width: map-get($breakpoints, m)) {
			display: block;
		}
	}

	.footer-inner-bottom {
		@extend %mw-inner;
		background: $_footer-inner-bottom-bg;
		position: relative;
		width: 100%;
		padding-top: $_footer-inner-bottom-padding / 2;
		display: flex;
		flex-wrap: wrap;
		font-size: 1.8rem;
		color: $_footer-color;
		flex-direction: column;
		align-items: center;
		padding-bottom: 80px;
		
		@include mq($min-width: map-get($breakpoints, m)) {
			padding-bottom: $_footer-inner-bottom-padding;
		}
		@include mq($min-width: map-get($breakpoints, l)) {
			align-items: flex-start;
			flex-direction: row;
			flex-wrap: nowrap;
			margin-top: 8rem;
		}
		.footer-bottom-nav {
			float: left;
			width: auto;
			flex-basis: calc(100% / 4);
			text-align: center;
			margin-bottom: 2rem;
			display: none;
			@include mq($min-width: map-get($breakpoints, l)) {
				display: block;
				float: left;
				width: auto;
				text-align: left;
				margin-bottom: 0;
			}
			a {
				color: $_footer-color;
				margin-right: 1rem;
				&:hover {
					color: $_footer-color-hover;
				}
			}
		}
		.footer-copy {
			flex-grow: 1;
			flex-basis: calc(100% / 4 * 3);
			justify-content: center;
			margin-bottom: 4rem;
			margin-top: 4rem;
			@include mq($min-width: map-get($breakpoints, l)) {
				margin-top: 0;
				justify-content: flex-start;
				margin-bottom: 0;
			}
			display: flex;
			flex-wrap: wrap;
			.footer-bottom-nav{
				display: block;
				flex-basis: 100%;
				order: -1;
				margin-bottom: 4rem;
				@include mq($min-width: map-get($breakpoints, m)) {
					margin-left: 40px;
					flex-basis: auto;
					order: inherit;
					margin-bottom: 0;
				}
				a{
					&:hover{
						color: $brand-color-1;
					}
				}
			}
			span{
				flex-basis: 100%;
				text-align: center;
				@include mq($min-width: map-get($breakpoints, m)) {
					flex-basis: auto;
					text-align: left;
				}
			}
			>a {
				flex-basis: 100%;
				margin-top: 9.5rem;
				height: 100%;
				flex-basis: 100%;
				margin-top: 10.5rem;
				height: 100%;
				text-align: center;
				@include mq($min-width: map-get($breakpoints, l)) {
					flex-basis: 100%;
					margin-top: 5.5rem;
					text-align: left;

				}
				img {
					height: 67px;
				}
			}
		}
	}

	.footer-col {
		width: span(12);
		max-width: span(12);
		padding-right: $_opc-grid-gutter-width;
		padding-left: $_opc-grid-gutter-width;
		padding-bottom: $_opc-grid-gutter-width * 2;
		text-align: center;
		color: $_footer-color;

		&.footer-col-services {
			//text-align: left;
		}

		h2 {
			font-size: 2.8rem;
			margin: 0 0 3rem;
			padding: 0;
			color: #fff;
		}

		@include mq($min-width: map-get($breakpoints, m)) {
			width: span(3);
			max-width: span(3);
			text-align: left;
		}
		@include mq($min-width: map-get($breakpoints, xl)) {
			padding-bottom: $_header-padding;
			width: span(3);
			max-width: span(3);
		}

		.opc-static-content {
			@extend .text;
		}

		p {
			color: $_footer-color;
		}
        label {
            color: $_footer-color;
		}
	}

	// nav im footer
	.opc-navigation {

		&[data-op-navtype="simple"] {
			a {
				color: $_footer-color;
				padding-left: 0;
				padding-right: 0;
				transition: font-weight	0.5 ease-in-out;
				font-size: 1.8rem;

				&:hover {
					font-weight: 400;
				}
				&::before {
					display: none;
				}
			}
			// nav level 1
			.navigation-level-1 {
				>li {
					>a {
						padding: 0.5rem 0;
					}
				}
			}
			// nav level 2
			.navigation-level-2 {
				>li {
					>a {
						white-space: nowrap;
						padding-left: 0;
						padding-right: 0;
						transition: font-weight	0.5 ease-in-out;
					}
					&:hover {
						>a {
							font-weight: 400;
							background-color: transparent;
						}
					}
				}
			}
		}
	}

}