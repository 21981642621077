/*###############################################

@Title: Loader
@Description:
Loading animations

###############################################*/

.siteloader {
	width: 100vw;
	background: rgba(239,239,239,.6);
	height: 100vh;
	z-index: 99999999;
	position: fixed;

	&.is-progressing {
		min-height: 6px;
		overflow-x: hidden;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background-color: #fc8200;
			width: 100%;
			height: 6px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: -20%;
			bottom: 0;
			background-color: #f89b37;
			width: 20%;
			height: 6px;
			-webkit-animation: 2s cubic-bezier(.65,.815,.735,.395) infinite is-progressing-animation;
			animation: 2s cubic-bezier(.65,.815,.735,.395) infinite is-progressing-animation;
		}
	}
}

@-webkit-keyframes is-progressing-animation {
    0% {
        left: -20%;
    }

    100% {
        left: 100%;
    }
}

@keyframes is-progressing-animation {
    0% {
        left: -20%;
    }

    100% {
        left: 100%;
    }
}