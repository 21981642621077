 /*###############################################

@Title: Direct Search Suggest
@Description:
This file contains the directsearch suggest styles

###############################################*/

 .opc-directsearch-suggest {
     display: flex;
     width: 100%;
     flex-wrap: wrap;
     position: relative;
     z-index: 2;
     background-color: $light-grey;
     border: none;
     &::after{
         content: "";
         position: absolute;
         top: 0;
         bottom: 0;
         width: 10000px;
         left: -5000px;
         z-index: -1;
         background: $light-grey;
     }
     .directsearch-suggest-row {
         display: flex;
         width: 100%;
         max-width: 100%;
         flex-wrap: wrap;
         margin-left: -2rem;
         margin-right: -2rem;
     }
     .directsearch-suggest-column {
         min-width: 30rem;
         flex-grow: 1;
         align-content: flex-start;
         &:empty {
             display: none !important;
         }
         .directsearch-suggest-column-section {
             margin-bottom: 2rem;
             .column-section-title {
                 font-size: 2rem;
                 color: $brand-color-1;
                 font-weight: 500;
                 text-transform: none;
                 opacity: 1;
                 margin-bottom: 2rem;
            }
            .column-section-list {}
            &:last-of-type {
                 margin-bottom: 0;
            }
            &.no2{
                font-size: 1.6rem;
                @include mq($min-width: map-get($breakpoints, s)) {
                    font-size: inherit;
                }
                 .column-section-list {
                    margin-left: -2rem;
                    li{
                         border-bottom-color: transparent;
                    }
                 }

            }
         }
         .searchTerm {
             background: rgba(orange, 0.5);
         }
         .CatItem-ArtName1 {
             font-weight: 400;
         }
         .CatItem-ArtNo {}
         .CatItem-ArtInternalNo {}
         .Url-Canonical {}
         .CatStruct-Name {
             font-weight: 400;
             padding-left: 2rem;
         }
         .Url-Main {}
         .CatStruct-Name {
             
         }
         .CatStruct-Number {display:none;}
         .list-item-image {
             width: 11rem;
             height: 9rem;
             margin-right: 1rem;
             flex-shrink: 0;
             position: relative;
             background-color: $white;
             img {
                 margin: 0 auto;
                 display: block;
                 max-width: 100%;
                 max-height: 100%;
                 flex-shrink: 0;
             }
             &::after{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: $light-grey;
                mix-blend-mode: multiply;
             }
         }
         .list-item-info { 
            width: 100%; 
            max-width: 100%; 
            display: flex;
            flex-wrap: wrap;
        }
         a {
             color: #000;
         }
         .list-item-brand {
             font-weight: 700;
             order: 1;
             margin-right: 0.5rem;
            }
            .list-item-name {
                font-weight: 400;
                opacity: 1;
                order: 2;
            }
            .list-item-additional-name {}
            .list-item-art-nr {
                //  display:none;
                opacity: 1;
                order: 9;
                margin-top: .5rem;
                flex-basis: 100%;
                &::before{
                    content: "Art.";
                    margin-right: .5rem;
                }
            }
            .list-item-description {
             order: 4;
             display:block;
             white-space: nowrap;
             text-overflow: ellipsis;
             //max-height: 4rem;
             position: relative;
             overflow: hidden;
             max-width: 100%;
             flex-basis: 100%;
            }
         .list-item-price {
             display: none;
             .price-basis {}
             .price-promo {}
         }
     }
     h3 {
         @extend h4;
         color: $brand-color-3;
         margin-bottom: 0.2em;
         font-size: 1.2rem;
         text-transform: uppercase;
     }
     &[data-op-type="2col3sec"] {
         .directsearch-suggest-column {
             width: 50%;
             //max-width: 50%;
             flex-shrink: 0;
             padding: 2rem;
             &:empty {
                 display: none;
             }
         }
     }
     &[data-op-type="3col2sec"] {
         .directsearch-suggest-column {
             width: 33.333%;
             flex-shrink: 0;
             padding: 2rem;
             &:empty {
                 display: none;
             }
         }
     }
     &[data-op-type="3rowA2sec"] {
         .directsearch-suggest-column {
             width: 50%;
             flex-shrink: 0;
             padding: 2rem;
             &:empty {
                 display: none;
             }
         }
     }
 }