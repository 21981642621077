/*###############################################

@Title: Basket Summary
@Description:
This file contains the basket summary styles

###############################################*/

// local namespace variables
.opc-basket-summary {
    .basket-summary-head {
        font-weight: bold;
        padding: 0.5rem 0;
        border-bottom: 1px solid #999;
    }
    .basket-summary-body {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 2rem;
    }
    .basket-summary-head,
    .basket-summary-item {
        display: flex;
        .basket-summary-item-title {
            flex-grow: 1;
            width: 80%;
            padding: 0 1rem 0 1rem;
            color: #000;
        }
        .basket-summary-item-count {
            width: 20%;
            flex-shrink: 0;
            padding: 0 1rem 0 0;
            text-align: right;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 6rem;
                text-align: right;
            }
        }
        .basket-summary-item-price {
            width: 20%;
            flex-shrink: 0;
            text-align: right;
            padding: 0 1rem 0 0;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 14rem;
            }
        }
    }
    .basket-summary-item {
        padding: 1rem 0;
        border-bottom: 1px solid #eee;
        &:hover {
            background: #eee;
        }
    }
    .basket-summary-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 2rem;
        background: #eee;
        padding: 1rem;
        .summary-footer-line dl {
            display: flex;
            margin: 0;
            padding: 0.5rem 0;
            dt {}
            dd {
                width: 12rem;
                text-align: right;
            }
        }
    }
}