/*###############################################

@Title: Links
@Description:
This file contains the link styles

###############################################*/

$_opc-link-color:							$brand-color-1 !default;
$_opc-link-color-visited:					$_opc-link-color !default;
$_opc-link-color-hover:						lighten($_opc-link-color, 30%) !default;
$_opc-link-color-active:					lighten($_opc-link-color, 10%) !default;
$_opc-link-underline-animation-duration:	0.2s !default;


a {
	
	position: relative;
	text-decoration: none;
	
	&:link { color: $_opc-link-color; }
	&:visited { color: $_opc-link-color-visited; }
	&:hover { color: $_opc-link-color-hover; }
	&:active { color: $_opc-link-color-active; }
	
	
	// animated links
	&[data-op-style="animated"] {
		&::after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 100%;
			height: 2px;
			background: $_opc-link-color-hover;
			transition: all $_opc-link-underline-animation-duration ease-in-out 0.1s;
		}
		&:hover {
			color: $_opc-link-color-hover;

			&::after {
				right: 0;
			}
		}
	}
}
