/*###############################################

@Title: Notifications
@Description:
This file contains the notifications styles

###############################################*/

.opc-notifications {
    &.is-maximized {
        .notifications-dialog {
            width: 90% !important;
            height: 90% !important;
            max-width: none !important;
            margin: 0 auto !important;
            top: 5% !important;
            left: 0 !important;
        }
    } // alertify overlay
    .notifications-dimmer {}
    .notifications-modal {}
    .notifications-dialog {
        padding: 2rem;
        padding: 0;
        .notifications-commands {
            margin: 0;
            right: 2rem;
            top: 2rem;
        }
        .notifications-header {
            padding: 2rem;
            margin: 0;
            h3 {
                margin: 0 !important;
            }
        }
        .notifications-body {
            padding: 0 2rem 2rem;
            .notifications-content {
                padding: 0 !important;
            }
        }
        .notifications-footer {
            padding: 0 2rem 2rem;
            margin: 0;
            .notifications-buttons {
                width: 100%;
                @include mq($min-width: map-get($breakpoints, s)) {
                    width: auto;
                }
                .notifications-ok {
                    @extend .opc-button;
                    @extend .opc-button[data-op-style="loud"];
                }
                .notifications-cancel {
                    @extend .opc-button;
                    @extend .opc-button[data-op-style="loud-lined"];
                }
            }
        }
        .notifications-commands {
            top: 1.5rem;
            right: 1.5rem;
            .notifications-close {
                height: 3rem;
                width: 3rem;
                background-image: inline-svg($op-icon-cross-svg, #000) !important;
                background-size: 2.4rem;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
            .notifications-maximize {
                height: 3rem;
                width: 3rem;
                background-image: inline-svg($op-icon-cross-svg, #000) !important;
                background-size: 2.4rem;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
            .notifications-pin {
                height: 3rem;
                width: 3rem;
                background-image: inline-svg($op-icon-cross-svg, #000) !important;
                background-size: 2.4rem;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    .notifications-error {
        border-radius: 1rem;
        background: $error-color;
    }
}

.opc-notifications-notifier {
    .notifications-message {
        width: 28rem;
        border-radius: 1rem;
        border-radius: 1rem;
        text-align: center;
        box-shadow: 0 0 10px rgba(#000, 0.2);
        &.is-error {
            background: $error-color;
            color: #fff;
        }
        &.is-success {
            background: $success-color;
            color: #fff;
        }
        .notifications-link {
            color: #fff;
            &:hover {
                color: $_opc-link-color-hover;
            }
        }
        .notifications-close {
            right: 1rem;
            top: 2rem;
            background-color: transparent;
        }
        &.is-visible {
            padding: 2rem 3rem 2rem 2rem;
        }
    }
}