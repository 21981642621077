/*###############################################

@Title: Flags
@Description:
This file contains the article flag styles

###############################################*/

$_opc-flags-new-bg: $black;
$_opc-flags-new-color: $white;
$_opc-flags-sale-bg: #F63093;
$_opc-flags-sale-color: $white;
$_opc-flags-bestseller-bg: #008B95;
$_opc-flags-bestseller-color: $white;
$_opc-flags-size: auto !default;
$_opc-flags-font-size: 1.6rem !default;
$_opc-flags-occ-bg: $brand-color-1;
$_opc-flags-occ-color: $white;


.opc-flags {
    display: flex;
    // flex-direction: column;
    .flag-news,
    .flag-free1,
    .flag-free2,
    .flag-occ,
    .flag-variant {
        // aus cdn:
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 6rem;
        text-align: center;
        // font-weight: bold;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 1.4rem;
        white-space: pre-line;
        margin-right: .5rem;
        // ende aus cdn
        transform: none;
        width: auto;
        height: auto;
        border-radius: 0;
        background-color: $brand-color-1;
        color: $white;
        padding-left: 0;
        margin-bottom: .6rem;

        span{
            line-height: 1;
            height: auto;
            position: relative;
            padding: .6rem 1.5rem;
            text-transform: none;
            font-size: 1.6rem;
        }

        [data-op-article-list-type="list"] & {
            transform: none;
            width: auto;
            height: auto;
            border-radius: 0;
            color: $white;
            padding-left: 1rem;
            margin-bottom: .6rem;
        }
        span{
            line-height: 1;
            height: auto;
            position: relative;
            padding: .9rem 2rem;
            text-transform: uppercase;
            font-size: 1.4rem;
        }
        svg {
            font-size: 4rem;
            margin: 0;
            flex-shrink: 0;
        }
    }
    .flag-news {
        background: $_opc-flags-new-bg;
        color: $_opc-flags-new-color;
    }
    .flag-free1 {
        background: $_opc-flags-sale-bg;
        color: $_opc-flags-sale-color;
    }
    .flag-free2 {
        background: $_opc-flags-bestseller-bg;
        color: $_opc-flags-bestseller-color;
    }
    .flag-occ {
        background: $_opc-flags-occ-bg;
        color: $_opc-flags-occ-color;
    }
    .flag-variant {
        background: $grey;
        color: $_opc-flags-occ-color;
    }
    &[data-op-layout="icon"] {
        svg {
            font-size: $_opc-flags-size;
            [data-op-article-list-type="list"] & {
                font-size: $_opc-flags-size / 1.5;
            }
        }
        .flag-news {
			background: transparent !important;
            color: $_opc-flags-new-bg;
        }
        .flag-free1 {
			background: transparent !important;
            color: $_opc-flags-sale-bg;
        }
        .flag-free2 {
			background: transparent !important;
            color: $_opc-flags-bestseller-bg;
        }
        .flag-occ {
			background: transparent !important;
            color: $_opc-flags-occ-bg;
        }
    }
    &[data-op-layout="text"] {
        svg {
            display: none;
        }
    }
}
.zuletzt-gekauft{
    // display: inline-flex;
    display: none;
    align-items: center;
    justify-content: center;
    line-height: 6rem;
    text-align: center;
    font-weight: normal;
    text-transform: none;
    font-size: 1.4rem;
    white-space: pre-line;
    margin-right: .5rem;
    transform: none;
    width: auto;
    height: auto;
    border-radius: 0;
    background-color: #EEEEEE;
    color: $black;
    padding-left: 0;
    margin-bottom: .6rem;

    span{
        line-height: 1;
        height: auto;
        position: relative;
        padding: .6rem 1.5rem;
        text-transform: none;
        font-size: 1.6rem;
    }

    [data-op-article-list-type="list"] & {
        transform: none;
        width: auto;
        height: auto;
        border-radius: 0;
        color: $white;
        padding-left: 1rem;
        margin-bottom: .6rem;
    }
    span{
        line-height: 1;
        height: auto;
        position: relative;
        padding: .9rem 2rem;
        text-transform: none;
        font-size: 1.4rem;
    }
}