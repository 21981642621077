$list-item-color: #333 !default;
$panel-bg-color: #EFEFEF !default;
$panel-info-color: darken($panel-bg-color, 20%) !default;
$panel-prefix-bg: darken($panel-bg-color, 8%) !default;
$loading-flat-color: darken(#EFEFEF, 8%) !default;

.hidden {
    display: none !important;
}

.opacc-tool {
    flex-direction: row;

    .opc-message {
        color: #FFF;

        &[data-op-type="warning"] {
            color: #333;
        }
    }

    &.smaller {
        .sidebar-wrapper {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 15rem !important;
            }

            .sidebar-inner-top {
                ul {
                    li {
                        a {
                            @include mq($min-width: map-get($breakpoints, m)) {
                                justify-content: center !important;
                            }
                            span {
                                @include mq($min-width: map-get($breakpoints, m)) {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }
            .sidebar-group {
                a {
                    @include mq($min-width: map-get($breakpoints, m)) {
                        justify-content: center !important;
                    }
                    span {
                        @include mq($min-width: map-get($breakpoints, m)) {
                            display: none !important;
                        }
                    }
                }

                &.language {
                    a {
                        span {
                            @include mq($min-width: map-get($breakpoints, m)) {
                                display: flex !important;
                            }
                        }
                    }
                }
            }
            .sidebar-inner-bottom {
                .dark-mode {
                    justify-content: center;
                    > span {
                        @include mq($min-width: map-get($breakpoints, m)) {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }

    &.open-sidebar {
        .sidebar-wrapper {
            transform: translateX(100%);
        }
    }

    .sidebar-wrapper {
        background: $opacc-tool-sidebar-bg;
        color: $opacc-tool-sidebar-color;
        // padding: 2rem;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        left: -100%;
        transition: transform 0.2s ease-in-out;
        z-index: 1000;
        overflow: auto;

        // @include mq($max-width: map-get($breakpoints, m)) {
        // }

        @include mq($min-width: map-get($breakpoints, m)) {
            position: relative;
            width: 15rem;
            left: 0;
        }

        @include mq($min-width: map-get($breakpoints, l)) {
            width: 30rem;
        }

        .sidebar-inner-logo {
            font-size: 6rem;
            display: flex;
            justify-content: center;
            position: relative;
            padding-top: 6rem;
            padding-bottom: 7rem;

            a {
                color: $opacc-tool-sidebar-logo-color;
                position: relative;

                &:hover {
                    .op-icon-druid {
                        display: inline-block;
                    }
                }

                .op-icon-brand-opacc {
                    position: relative;
                    z-index: 2;
                }
                .op-icon-druid {
                    display: none;
                    position: absolute;
                    font-size: 5rem;
                    transform: rotate(-20deg);
                    left: 0;
                    z-index: 1;
                }
            }

            .toggle-sidebar {
                display: inline-flex;
                position: absolute;
                right: 0;
                background: transparent;
                color: $opacc-tool-sidebar-color;
                z-index: 10;
                font-size: 3rem;

                @include mq($min-width: map-get($breakpoints, m)) {
                    display: none;
                }
            }
        }

        .sidebar-inner-top {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    &.active {
                        a {
                            font-weight: 600;
                            &::after {
                                background-color: $opacc-orange-color;
                            }

                            &:hover {
                                &::after {
                                    background-color: lighten($opacc-orange-color, 10%);
                                }
                            }
                        }
                    }

                    a {
                        position: relative;
                        color: $opacc-tool-sidebar-color;
                        display: flex;
                        align-items: center;
                        // border-radius: 10px;
                        padding: 1rem 2rem;
                        background: $opacc-tool-sidebar-bg;
                        transition: background 0.5s ease;

                        &::after {
                            content: ' ';
                            width: 5px;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            background-color: transparent;
                        }

                        @include mq($min-width: map-get($breakpoints, m)) {
                            justify-content: center;
                        }

                        @include mq($min-width: map-get($breakpoints, l)) {
                            justify-content: flex-start;
                        }

                        &:hover {
                            &::after {
                                background-color: lighten($opacc-orange-color, 5%);
                            }
                        }

                        svg {
                            font-size: 3rem;
                            margin-right: 1rem;
                        }

                        span {
                            @include mq($min-width: map-get($breakpoints, m)) {
                                display: none;
                            }

                            @include mq($min-width: map-get($breakpoints, l)) {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }

        .sidebar-inner-bottom {
            margin-top: 5rem;
            border-top: 1px solid lighten($opacc-tool-sidebar-bg, 10%);
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .dark-mode {
                margin-top: auto;
                display: flex;
                align-items: center;
                padding: 2rem;

                label {
                    margin-bottom: 0;

                }
                > span {
                    margin-left: 5px;

                    @include mq($min-width: map-get($breakpoints, m)) {
                        display: none;
                    }

                    @include mq($min-width: map-get($breakpoints, l)) {
                        display: flex;
                    }
                }
            }
        }

        .sidebar-group {
            a {
                color: $opacc-tool-sidebar-color;
                display: flex;
                align-items: center;
                // border-radius: 10px;
                padding: 1rem 2rem;
                background: $opacc-tool-sidebar-bg;
                transition: background 0.5s ease;

                &::after {
                    content: ' ';
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: transparent;
                }

                @include mq($min-width: map-get($breakpoints, m)) {
                    justify-content: center;
                }

                @include mq($min-width: map-get($breakpoints, l)) {
                    justify-content: flex-start;
                }

                &:hover {
                    &::after {
                        background-color: lighten($opacc-orange-color, 5%);
                    }
                }

                svg {
                    font-size: 3rem;
                    margin-right: 1rem;
                }

                span {
                    @include mq($min-width: map-get($breakpoints, m)) {
                        display: none;
                    }

                    @include mq($min-width: map-get($breakpoints, l)) {
                        display: flex;
                    }
                }
            }

            &.language {
                border-top: 1px solid lighten($opacc-tool-sidebar-bg, 10%);

                a {
                    justify-content: center;

                    &.is-active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .page-opacc-tool {
        background: $opacc-tool-content-bg;

        @include mq($min-width: map-get($breakpoints, m)) {
            width: calc(100% - 15rem);
        }

        @include mq($min-width: map-get($breakpoints, l)) {
            width: calc(100% - 30rem);
        }

        .opacc-tool-wrapper {
            font-family: $opacc-tool-font;
            display: flex;
            height: 100%;
            flex-grow: 1;

            .toggle-sidebar {
                display: inline-block;
                position: absolute;
                right: 0;
                color: $header-headline-color;
                font-size: 3rem;
                background: transparent;
                z-index: 10;

                @include mq($min-width: map-get($breakpoints, m)) {
                    display: none;
                }
            }

            .opacc-tool-main {
                width: 100%;

                .opacc-tool-content {
                    padding: 2rem;
                    color: $content-color;

                    .tool-panel-row {
                        .tool-panel {
                            .panel-label {
                                margin-bottom: 1rem;
                                font-size: larger;
                            }

                            .panel-content {
                                color: $list-item-color;
                                padding: 1rem;
                                background-color: $list-item-bg-color;
                                box-shadow: 0px 0px 0px 1px $box-shadow;
                                transition: all 0.5s ease;
                                position: relative;

                                .opc-col {
                                    dl {
                                        border-bottom: 1px solid $edit-border-color;
                                    }

                                    &[data-op-col="12"] {
                                        &:last-child() {
                                            margin-bottom: 0;
                                        }
                                    }
                                }

                                dd {
                                    min-height: 40px;
                                    display: flex;
                                    align-items: center;

                                    .opc-form-field {
                                        margin-bottom: 0;
                                        width: 100%;

                                        label {
                                            color: $search-color;
                                        }

                                        input {
                                            border-color: $search-border-color;
                                            background-color: lighten($search-bg-color, 5%);
                                            color: $search-color;
                                        }
                                    }
                                }

                                .edit-btn {
                                    position: absolute;
                                    right: 1.5rem;
                                    bottom: 1rem;
                                }
                                .editmode {
                                    display: none;
                                }
                                [data-edit-action="save"] {
                                    display: none;
                                }
                                .is-changing {
                                    dd {
                                        display: none;
                                    }
                                    .editmode {
                                        display: block;
                                    }
                                    [data-edit-action="edit"] {
                                        display: none;
                                    }
                                    [data-edit-action="save"] {
                                        display: block;
                                    }
                                }

                                .list-item-actions {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    z-index: 10;
                                }
                            }
                        }
                    }
                }
            }
        }

        .opacc-tool-title {
            padding: 2rem;
            padding-top: 5rem;
            color: $header-headline-color;
            position: relative;

            h1 {
                font-size: 2rem;
                font-family: $opacc-tool-font;
                font-weight: 300;
                display: flex;
                align-items: center;

                @include mq($min-width: map-get($breakpoints, m)) {
                    font-size: 5rem;
                }

                .opacc-tool-actions {
                    margin-left: auto;
                    button {
                        border: none;
                        font-size: 2rem;
                        color: $header-headline-color;

                        @include mq($min-width: map-get($breakpoints, m)) {
                            font-size: 3rem;
                        }
                    }
                }
            }

            h2, h3, h4, h5, h6 {
                font-family: $opacc-tool-font;
            }

            .opacc-tool-description {
                margin-bottom: 1rem;
            }
        }
    }

    &.sticky-sidebar {
        .sidebar-wrapper {
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 11rem;
            }

            .sidebar-inner-top {
                ul {
                    li {
                        a {
                            span {
                                @include mq($min-width: map-get($breakpoints, m)) {
                                    display: none;
                                }
                            }

                            svg {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .sidebar-group {
                a {
                    span {
                        display: none;
                    }
                    svg {
                        margin-right: 0;
                    }
                }
            }

            .sidebar-inner-bottom {
                .dark-mode {
                    > span {
                        @include mq($min-width: map-get($breakpoints, m)) {
                            display: none;
                        }
                    }
                }
            }
        }

        .page-opacc-tool {
            @include mq($min-width: map-get($breakpoints, m)) {
                left: 11rem;
            }
        }
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        color: $header-headline-color;
    }

    .druid-list {
        .druid-list-item {
            a {
                display: flex;
                width: 100%;
                height: 250px;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: $druid-item-color;
                background-color: $druid-item-bg-color;
                border-radius: 4px;
                box-shadow: 0px 0px 1px rgba(0,0,0,.2);
                font-size: larger;
                transition: background 0.5s ease;

                &:hover {
                    color: $druid-item-color-hover;
                    background-color: $druid-item-bg-color-hover;
                }

                svg {
                    font-size: 8rem;
                    margin: 2rem;
                }
            }
        }
    }

    .druid-attributes {
        margin: 0;
        padding: 0;
        list-style: none;

        .druid-attributes-item {
            margin-bottom: 2rem;
            padding: 2rem;
            border-radius: 5px;
            border: 2px solid darken($panel-bg-color, 5%);
            border-top: none;
            border-right: none;
            border-left: none;
            background: darken($panel-bg-color, 2%);
            position: relative;

            @include mq($min-width: map-get($breakpoints, m)) {
                margin-bottom: 4rem;
            }

            &:hover {
                border-color: darken($panel-bg-color, 7%);
                background: darken($panel-bg-color, 1%);
            }

            .attributes-item-prefix {
                font-size: smaller;
                display: flex;
                margin-bottom: 0.5rem;

                span {
                    padding: 0.5rem;
                    background: $panel-prefix-bg;
                    border-radius: 4px;
                    margin-right: 1rem;
                }
            }
            .attributes-item-header {
                width: 100%;
                .attribute-item-title {
                    display: flex;
                    h2 {
                        margin-bottom: 0;
                        color: $druid-attribute-item-title-color;
                        cursor: pointer;
                        width: 100%;
                    }

                    .attribute-item-actions {
                        margin-left: auto;
                        display: flex;

                        .opc-button {
                            // color: $list-item-color;

                            svg {
                                transition: transform 0.1s linear;
                            }
                        }
                    }

                }
                .attribute-item-info {
                    font-size: small;
                    margin-top: 1rem;
                    color: $panel-info-color;
                    width: 100%;
                }
            }
            .attributes-item-body {
                padding-top: 2rem;
                display: none;

                label {
                    font-weight: 600;
                }

                .image-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .attribute-actions-wrapper {
                    display: flex;

                    .opc-button {
                        margin-left: auto;
                    }
                }
            }

            &.show {
                .attributes-item-header {
                    .attribute-item-actions {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
                .attributes-item-body {
                    display: block;
                }
            }
        }
    }
}

.is-loading {
    // position: relative;

    &::before {
        content: '';
        width: 3rem;
        height: 3rem;
        background: transparent;
        display: block;
        border-radius: 50%;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        top: 1rem;
        margin-left: -1.5rem;
        border: 2px solid transparent;
        border-top: 2px solid $brand-color-1;
        animation-name: rotater;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 10;
    }
}

.is-loading-flat {
    position: relative;
    width: 100%;
    height: 5rem;
    display: block;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        // min-height: 5rem;
        background: $loading-flat-color;
        display: block;
        margin: 0 auto;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        animation-name: loadingflat;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }
}

@keyframes loadingflat {
    0% {
        background-color: $loading-flat-color;
    }
    50% {
        background-color: lighten($loading-flat-color, 5%);
    }
    100% {
        background-color: $loading-flat-color;
    }
}