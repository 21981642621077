$opacc-orange-color: #fc8200 !default;
// $opacc-purple-color: #2B2D42 !default;
// $opacc-purple-color: #6b4e4f !default;
$opacc-purple-color: #392F5A !default;
$opacc-brand-svg: '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  width="32" height="32" viewBox="0 0 32 32" xml:space="preserve"><polygon points="25,20.2 6,31.1 6,11.5 25,0.6 "/></svg>' !default;

$druid-bg-color: $opacc-purple-color !default;
// $druid-components-headline-color: #FFF !default;
$druid-components-headline-color: #777 !default;

$screen-bg: $brand-color-1 !default;
$screen-color: #FFF !default;
$list-selected-bg: $brand-color-2 !default;
$background-color: #EFEFEF !default;

// $opacc-tool-sidebar-bg: #6b4e4f !default;
$opacc-tool-sidebar-bg: #392F5A !default;
$opacc-tool-sidebar-color: #FFF !default;
$opacc-tool-content-bg: #EFEFEF !default;
$opacc-tool-content-panel-bg: #FFF !default;
$opacc-tool-sidebar-logo-color: #FFF !default;

$opacc-tool-font: "Open Sans", Arial, sans-serif !default;

$content-color: #333 !default;

$header-headline-color: #777 !default;

$list-item-color: #333 !default;
$list-item-bg-color: #FFF !default;
$box-shadow: darken($background-color, 2%) !default;
$edit-border-color: #EFEFEF !default;
$search-border-color: #CCC !default;
$search-bg-color: #FFF !default;
$search-color: #000 !default;

$material-bg-color: #FFF !default;
$material-color: #333 !default;
$material-border: $search-border-color !default;
$material-border-focus: #fc8200 !default;

$druid-button-border-radius: 4px;

$blue-info: #3a86ff !default;
$green-info: #3BB273 !default;
$yellow-info: #FFCA3A !default;
$red-info: #EF233C !default;
$pink-info: #EF23C3 !default;

// $druid-dashboard-bg-color: darken($opacc-purple-color, 15%) !default;
$druid-dashboard-bg-color: #EFEFEF !default;
$druid-item-bg-color: $opacc-purple-color !default;
$druid-item-bg-color-hover: darken($opacc-purple-color, 5%) !default;
$druid-item-color: #FFF !default;
$druid-item-color-hover: $opacc-orange-color !default;
$druid-attribute-item-title-color: $opacc-orange-color !default;

.page-druid {
    .opacc-tool-wrapper {
        min-height: 100vh;
    }
    .druid-wrapper {
        width: 100%;
        height: 100%;

        &.login {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;

            background-image: linear-gradient(45deg, rgba(57,47,90,0) 0%, rgba(57,47,90,1) 33%), inline-svg($opacc-brand-svg, lighten($opacc-purple-color, 5%));
            // background-image: linear-gradient(45deg, rgba(107,78,79,0) 0%, rgba(107,78,79,1) 33%), inline-svg($opacc-brand-svg, lighten($opacc-purple-color, 5%));
            background-color: $druid-bg-color;

            .login-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;

                @include mq($min-width: map-get($breakpoints, m)) {
                    width: 500px;
                }

                button {
                    width: 100%;
                }

                [data-op-label="material"] {
                    .form-field-element {
                        border-bottom: none;
                    }
                }
            }

            .login-headline {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                svg {
                    font-size: 10rem;
                }

                h2 {
                    color: #FFF;
                }
            }
        }

        &.dashboard {
            background-color: $druid-dashboard-bg-color;
            padding: 2rem;

            .opacc-tool-title {
                h1 {
                    color: $druid-components-headline-color;
                }
            }
        }
    }

    [data-op-label="material"] {
        label {
            color: lighten($material-color, 5%);
        }
        input, textarea, select {
            color: $material-color;
            background: $material-bg-color;
        }
        .form-field-element {
            width: 100%;
            border-color: $search-border-color;
            background-color: $search-bg-color;
            color: $search-color;
            display: flex;

            &::before {
                background: $material-border;
            }

            .opc-button {
                display: none;
            }

            position: relative;

            &.addon {
                .opc-button {
                    position: absolute;
                    right: 0;
                    z-index: 2;
                    border-radius: 4px;
                    display: inline-flex;
                }
            }

            span {
                width: 100%;
                padding: 4rem 1rem 1rem;
            }
        }
        &.focused {
            .form-field-element {
                &::before {
                    background: $material-border-focus;
                }
            }
        }
    }

    .opc-button {
        &[data-op-style="primary"] {
            color: $_opc-button-primary-color;
            background: $_opc-button-primary-bg;
            border-radius: $druid-button-border-radius;
            &:hover, &:focus {
                color: $_opc-button-primary-color-hover;
                background: $_opc-button-primary-bg-hover;
            }

            &[data-op-type="radio"] {
                label {
                    color: $_opc-button-primary-color;
                    background: $_opc-button-primary-bg;
                    border-radius: $druid-button-border-radius;
                    &:hover, &:focus {
                        color: $_opc-button-primary-color-hover;
                        background: $_opc-button-primary-bg-hover;
                    }
                }
            }

            &.is-progress { background: $_opc-button-primary-progress-color !important; }
            &.is-success { background: $_opc-button-primary-success-color !important; }
        }
        &[data-op-style="secondary"] {
            color: $_opc-button-secondary-color;
            background: $_opc-button-secondary-bg;
            border-radius: $druid-button-border-radius;

            &:hover, &:focus {
                color: $_opc-button-secondary-color-hover;
                background: $_opc-button-secondary-bg-hover;
            }

            &.is-progress { background: $_opc-button-secondary-progress-color !important; }
            &.is-success { background: $_opc-button-secondary-success-color !important; }
        }
        &[data-op-style="loud"],
        &[data-op-style="basket"] {
            background: $_opc-button-loud-bg;
            color: $_opc-button-loud-color;
            border-radius: $druid-button-border-radius;
            &:hover, &:focus {
                color: $_opc-button-loud-color-hover;
                background: $_opc-button-loud-bg-hover;
            }

            &[data-op-type="radio"] {
                background: transparent;
                border: none;

                label {
                    color: $_opc-button-loud-color;
                    background: $_opc-button-loud-bg;
                    border-radius: $druid-button-border-radius;
                    &:hover, &:focus {
                        color: $_opc-button-loud-color-hover;
                        background: $_opc-button-loud-bg-hover;
                    }
                }
            }

            &.is-progress { background: $_opc-button-loud-progress-color !important; }
            &.is-success { background: $_opc-button-loud-success-color !important; }
        }
        &[data-op-style="primary-lined"] {
            background: transparent;
            border-color: $_opc-button-primary-bg;
            color: $_opc-button-primary-bg;
            border-radius: $druid-button-border-radius;
            &:hover, &:focus {
                color: $_opc-button-primary-bg-hover;
                border-color: $_opc-button-primary-bg-hover;
            }

            &[data-op-type="radio"] {
                label {
                    background: transparent;
                    border-color: $_opc-button-primary-bg;
                    color: $_opc-button-primary-bg;
                    border-radius: $druid-button-border-radius;
                    &:hover, &:focus {
                        background: $_opc-button-primary-bg;
                        color: $_opc-button-primary-color;
                        border-color: $_opc-button-primary-bg-hover;
                    }
                }
                input:checked + label {
                    background: $_opc-button-primary-bg;
                    color: $_opc-button-primary-color;
                }
            }

            &.is-progress {
                border-color: $_opc-button-primary-progress-color !important; 
                color: $_opc-button-primary-progress-color !important; 
            }
            &.is-success {
                border-color: $_opc-button-primary-success-color !important; 
                color: $_opc-button-primary-success-color !important; 
            }
        }
        &[data-op-style="secondary-lined"] {
            background: transparent;
            border-color: $_opc-button-secondary-bg;
            color: $_opc-button-secondary-color;
            border-radius: $druid-button-border-radius;
            &:hover, &:focus {
                color: $_opc-button-secondary-color-hover;
                border-color: $_opc-button-secondary-bg-hover;
            }

            &[data-op-type="radio"] {
                label {
                    background: transparent;
                    border-color: $_opc-button-secondary-bg;
                    color: $_opc-button-secondary-bg;
                    border-radius: $druid-button-border-radius;
                    &:hover, &:focus {
                        background: $_opc-button-secondary-bg;
                        color: $_opc-button-secondary-color;
                        border-color: $_opc-button-secondary-bg-hover;
                    }
                }
                input:checked + label {
                    background: $_opc-button-secondary-bg;
                    color: $_opc-button-secondary-color;
                }
            }

            &.is-progress {
                border-color: $_opc-button-secondary-progress-color !important; 
                color: $_opc-button-secondary-progress-color !important; 
            }
            &.is-success {
                border-color: $_opc-button-secondary-success-color !important; 
                color: $_opc-button-secondary-success-color !important; 
            }
        }
        &[data-op-style="loud-lined"] {
            background: transparent;
            border-color: $_opc-button-loud-bg;
            color: $_opc-button-loud-bg;
            border-radius: $druid-button-border-radius;
            &:hover, &:focus {
                color: $_opc-button-loud-bg-hover;
                border-color: $_opc-button-loud-bg-hover;
            }

            &[data-op-type="radio"] {
                label {
                    background: transparent;
                    border-color: $_opc-button-loud-bg;
                    color: $_opc-button-loud-bg;
                    border-radius: $druid-button-border-radius;
                    &:hover, &:focus {
                        background: $_opc-button-loud-bg;
                        color: $_opc-button-loud-color;
                        border-color: $_opc-button-loud-bg-hover;
                    }
                }
                input:checked + label {
                    background: $_opc-button-loud-bg;
                    color: $_opc-button-loud-color;
                }
            }

            &.is-progress { 
                border-color: $_opc-button-loud-progress-color !important; 
                color: $_opc-button-loud-progress-color !important; 
            }
            &.is-success { 
                border-color: $_opc-button-loud-success-color !important; 
                color: $_opc-button-loud-success-color !important; 
            }
        }

        &[data-op-style="primary-color"] {
            background: transparent;
            border-color: transparent;
            color: $_opc-button-primary-bg;
            border-radius: 0;
            &:hover, &:focus {
                color: $_opc-button-primary-bg-hover;
            }

            &.is-progress { color: $_opc-button-primary-progress-color !important; }
            &.is-success { color: $_opc-button-primary-success-color !important; }
        }
        &[data-op-style="secondary-color"] {
            background: transparent;
            border-color: transparent;
            color: $_opc-button-secondary-bg;
            border-radius: 0;
            &:hover, &:focus {
                color: $_opc-button-secondary-bg-hover;
            }
            &.is-progress { color: $_opc-button-secondary-progress-color !important; }
            &.is-success { color: $_opc-button-secondary-success-color !important; }
        }
        &[data-op-style="loud-color"] {
            background: transparent;
            border-color: transparent;
            color: $_opc-button-loud-bg;
            border-radius: 0;

            &:hover, &:focus {
                color: $_opc-button-loud-bg-hover;
            }
            &.is-progress { color: $_opc-button-loud-progress-color !important; }
            &.is-success { color: $_opc-button-loud-success-color !important; }
        }
    }

    .opc-message {
        &[data-op-type="info"] {
            background: $blue-info;
            color: #FFF;

            a {
                color: #FFF;
                text-decoration: underline;
            }
        }
        &[data-op-type="success"] {
            background: $green-info;
            color: #FFF;

            a {
                color: #FFF;
                text-decoration: underline;
            }
        }
        &[data-op-type="warning"] {
            background: $yellow-info;
            color: #333;
            a {
                color: #333;
                text-decoration: underline;
            }
        }
        &[data-op-type="error"] {
            background: $red-info;
            color: #FFF;
            a {
                color: #FFF;
                text-decoration: underline;
            }
        }
    }
}